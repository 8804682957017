import React, { useEffect, useReducer } from "react";
import "./NewProject.scss";
import {
  MotifCard,
  MotifCardBody,
  MotifCardFooter,
  MotifCardHeader,
  MotifButton,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import Header from "../../components/headers/Header";
import NewProjectForm from "../../components/forms/NewProjectForm";
import { newProjectFormFields } from "./NewProjectData";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProjectDetails,
  updateProjectDetails,
  retireProject,
  fetchCountries,
  fetchSectors
} from "../../features/slices/ProjectScreens";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Back, Cross } from "../../constants/icons/Icons";
import RetirePopup from "../../components/modals/addUser/AddUser";
import ErrorModal from "../../components/modals/error/errorModal";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

const initialState = {
  isDeleteModalOpen: false,
  formFields: newProjectFormFields,
  formState: {
    projectName: "",
    clientName: "",
    gisId: null,
    engagementCode: "",
    countryId: "",
    channel: "",
    projectSize: "",
    clientSector: "",
    projectAdmin: [],
  },
  constantProjectName: "",
  isUpdating: false,
  isLoading: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DELETE_MODAL_OPEN":
      return { ...state, isDeleteModalOpen: action.payload };
    case "SET_FORM_FIELDS":
      return { ...state, formFields: action.payload };
    case "SET_FORM_STATE":
      return { ...state, formState: action.payload };
    case "SET_CONSTANT_PROJECT_NAME":
      return { ...state, constantProjectName: action.payload };
    case "SET_COUNTRY_OPTIONS":
      return {
        ...state,
        formFields: state.formFields.map((field) =>
          field.id === "countryId"
            ? { ...field, options: action.payload }
            : field
        ),
      };
    case "SET_SECTOR_OPTIONS":
      return {
        ...state,
        formFields: state.formFields.map((field) =>
          field.id === "clientSector"
            ? { ...field, options: action.payload }
            : field
        ),
      };
    case "SET_IS_UPDATING":
      return { ...state, isUpdating: action.payload };
    case "SET_IS_LOADING":
      return { ...state, isLoading: action.payload };
    case "SET_IS_LOADING_SECTORS":
      return { ...state, isLoadingSectors: action.payload };
    default:
      return state;
  }
};

const EditProject = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = location.state?.token;
  const projectName = location.state?.projectName;
  const { projectId: projectIdString } = useParams();
  const projectId = parseInt(projectIdString);
  const [state, dispatchState] = useReducer(reducer, initialState);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    data,
    isError: isProjectError,
    errorMessage,
    isLoadingSectors
  } = useSelector((state) => state.projectDetails);

  const goToProjectScreen = () => {
    navigate(`/project-home/${projectId}`, { state: { token } });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Set loading states for both general data and sectors
        dispatchState({ type: "SET_IS_LOADING", payload: true });
        dispatchState({ type: "SET_IS_LOADING_SECTORS", payload: true });
  
        // Fetch countries, sectors, and project details in parallel
        const [countriesResponse, sectorsResponse, projectDetailsResponse] = await Promise.all([
          dispatch(fetchCountries({ token })).unwrap(),
          dispatch(fetchSectors({ token })).unwrap(),
          dispatch(fetchProjectDetails({ projectId, token })).unwrap(),
        ]);
  
        // Handle countries data
        if (countriesResponse.success) {
          const countryOptions = countriesResponse.data.map((country) => ({
            value: country.id,
            label: country.name,
          }));
          dispatchState({
            type: "SET_COUNTRY_OPTIONS",
            payload: countryOptions,
          });
        }
  
        // Handle sectors data
        if (sectorsResponse.success) {
          const sectorOptions = sectorsResponse.data.map((sector) => ({
            value: sector.id,
            label: `${sector.name} - ${sector.subName}`,
          }));
          dispatchState({
            type: "SET_SECTOR_OPTIONS",
            payload: sectorOptions,
          });
        }
  
        // Handle project details
        if (projectDetailsResponse.success) {
          const projectAdmins =
            projectDetailsResponse.data.users
              ?.filter((user) => user.role.name === "Project admin")
              .map((user) => ({
                id: user.user.aadId,
                displayName: user.user?.name,
                emailId: user.user.email,
              })) || [];
  
          dispatchState({
            type: "SET_FORM_STATE",
            payload: {
              projectName: projectDetailsResponse.data.name || "",
              clientName: projectDetailsResponse.data.clientName || "",
              engagementCode: projectDetailsResponse.data.engagementCode || "",
              countryId: projectDetailsResponse.data.countryId || "",
              gisId: projectDetailsResponse.data.gisId || null,
              channel: projectDetailsResponse.data.isChannel1 ? "yes" : "no",
              projectSize: projectDetailsResponse.data.size || "",
              clientSector: projectDetailsResponse.data.sectorId || "",
              projectAdmin: projectAdmins,
            },
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        // Turn off general loading state
        dispatchState({ type: "SET_IS_LOADING", payload: false });
        // Turn off sector loading state
        dispatchState({ type: "SET_IS_LOADING_SECTORS", payload: false });
      }
    };
  
    fetchData();
  }, [dispatch, projectId, token]);
  

  useEffect(() => {
    if (
      data?.data &&
      state.formFields.find((field) => field.id === "countryId").options
        .length > 0
    ) {
      const projectAdmins =
        data.data.users
          ?.filter((user) => user.role.name === "Project admin")
          .map((user) => ({
            id: user.user.aadId,
            displayName: user.user?.name,
            emailId: user.user.email,
          })) || [];

      dispatchState({
        type: "SET_FORM_STATE",
        payload: {
          projectName: data.data.name || "",
          clientName: data.data.clientName || "",
          engagementCode: data.data.engagementCode || "",
          countryId: data.data.countryId || "",
          channel: data.data.isChannel1 ? "yes" : "no",
          projectSize: data.data.size || "",
          clientSector: data.data.sectorId || "",
          gisId: data.data.gisId || null,
          projectAdmin: projectAdmins,
        },
      });
      dispatchState({ type: "SET_IS_LOADING", payload: false });
    }
  }, [data, state.formFields]);

  const isFormValid = () => {
    const { formState } = state;
    return (
      formState.projectName.trim() !== "" &&
      formState.clientName.trim() !== "" &&
      formState.countryId > 0 &&
      formState.channel.trim() !== "" &&
      formState.projectSize.trim() !== "" &&
      formState.clientSector > 0 &&
      formState.projectAdmin.length > 0
    );
  };

  const handleUpdate = () => {
    if (isFormValid()) {
      dispatchState({ type: "SET_IS_UPDATING", payload: true });
      const { formState } = state;
      const projectData = {
        id: projectId,
        name: formState.projectName,
        clientName: formState.clientName,
        engagementCode: formState.engagementCode,
        countryId: formState.countryId,
        isChannel1: formState.channel === "yes",
        size: formState.projectSize,
        adminUsers: formState.projectAdmin.map((admin) => ({
          aadId: admin.id,
          name: admin.displayName,
          email: admin.emailId,
          projectRoleId: 2,
        })),
        statusId: 1,
        sectorId: formState.clientSector,
        gisId: parseInt(formState.gisId),
      };
      dispatch(updateProjectDetails({ projectData, token }))
        .unwrap()
        .then((response) => {
          if (response.success) {
            goToProjectScreen();
          }
        })
        .catch((error) => {
          console.error("Update failed:", error);
        })
        .finally(() =>
          dispatchState({ type: "SET_IS_UPDATING", payload: false })
        );
    } else {
      alert("Please fill in all required fields.");
    }
  };

  const handleRetireConfirm = () => {

    dispatch(retireProject({ projectId, token }))
      .unwrap()
      .then(() => {
        navigate("/");
        dispatchState({ type: "SET_DELETE_MODAL_OPEN", payload: false });
      })
      .catch((error) => {
        console.error("Retire failed:", error);
      });
  };

  const handleError = () => {
    navigate("/");
  };

  if (isProjectError) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={errorMessage}
          handleButtonClick={handleError}
          deleteButtonLabel={t("Reload")}
        />
      </div>
    );
  }

  const {
    formFields,
    formState,
    isUpdating,
    isDeleteModalOpen,
    isLoading,
  } = state;

  return (
    <div>
      <Header />
      {isLoading || isLoadingSectors ? (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
        ) : (
          <div className="Container">
            <Breadcrumbs
              items={[
                { label: "All projects", link: "/" },
                {
                  label: projectName,
                  link: `/project-home/${projectId}`,
                  token: token,
                },
                {
                  label: "Edit project",
                  link: `/edit-project/${projectId}`,
                  token: token,
                },
              ]}
            />
            <div id="inline">
              <MotifButton
                className="button done-button"
                onClick={goToProjectScreen}
              >
                <Back />
                &nbsp;
                {t("backButton")}
              </MotifButton>
            </div>
            <MotifCard
              orientation="horizontal"
              variant="card-border"
              className="new-project-card"
            >
              {isProjectError && errorMessage && (
                <div>
                  {errorMessage.errors &&
                    Object.entries(errorMessage.errors).map(([key, value]) => (
                      <p className="error-message" key={key}>
                        {key}: {value.join(", ")}
                      </p>
                    ))}
                </div>
              )}
              <MotifCardHeader>
                <span className="motif-h6-default-regular">
                  {t("editProject")} {projectName}
                </span>
              </MotifCardHeader>
              <MotifCardBody>
                <NewProjectForm
                  formFields={formFields}
                  dispatch={dispatchState}
                  formState={formState}
                  setFormState={(payload) =>
                    dispatchState({ type: "SET_FORM_STATE", payload })
                  }
                />
              </MotifCardBody>
              <MotifCardFooter className="edit-project-button-group">
                <MotifButton
                  className="button retire-button"
                  variant="warn"
                  warn={true}
                  onClick={() =>
                    dispatchState({
                      type: "SET_DELETE_MODAL_OPEN",
                      payload: true,
                    })
                  }
                >
                  {t("retireButton")} &nbsp;
                  <Cross />
                </MotifButton>
                <div className="new-project-footer">
                  <MotifButton
                    className="button cancel-button"
                    onClick={goToProjectScreen}
                  >
                    {t("Cancel")}
                  </MotifButton>
                  <MotifButton
                    className="button done-button"
                    onClick={handleUpdate}
                    disabled={!isFormValid() || isUpdating}
                  >
                    {isUpdating ? t("updatingButton") : t("Update")}
                  </MotifButton>
                </div>
              </MotifCardFooter>
            </MotifCard>
          </div>
      )}
      <RetirePopup
        isOpen={isDeleteModalOpen}
        onClose={() =>
          dispatchState({ type: "SET_DELETE_MODAL_OPEN", payload: false })
        }
        page={t("ConfirmationPopup")}
        ConfirmationPopup={t("ConfirmationPopup")}
        ConfirmationTitle={t("DeleteLabel")}
        ConfirmationEN={t("DeleteLabel")}
        ConfirmationDeleteMsgEN={t("ConfirmationRetireProject")}
        CancelButtonEN={t("Cancel")}
        callApi={handleRetireConfirm}
      />
    </div>
  );
};

export default EditProject;

import React from "react";
import SpecificationTab from "../../../components/specificationTab/SpecificationTab";
import ContextStep from "../../../components/contextStep/ContextStep";
import Thresholds from "./thresholds/Thresholds";
import Score from "./score/Score";
import Likelihood from "./likelihood/Likelihood";

const DmaProcessSpecification = () => {
  return (
    <div className="context-container">
      <SpecificationTab>
        <ContextStep key="thresholds" text="Thresholds">
          <Thresholds />
        </ContextStep>
        <ContextStep key="scores" text="Scores">
          {" "}
          <Score />
        </ContextStep>
        <ContextStep key="likelihood" text="Likelihood">
          {" "}
          <Likelihood />
        </ContextStep>
      </SpecificationTab>
    </div>
  );
};

export default DmaProcessSpecification;

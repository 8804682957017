import React from "react";
import "./Card.scss";
import PropTypes from "prop-types";
const Card = ({ label, value }) => {
  return (
    <div className="card">
      <p style={{ fontFamily: "EYInterstate" }} className="card-label">
        {label}
      </p>
      <p style={{ fontFamily: "EYInterstate" }} className="card-value">
        {value}
      </p>
    </div>
  );
};

Card.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};
export default Card;

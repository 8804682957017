import React, { useState, useEffect } from "react";
import {
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifMessage,
  MotifErrorMessage,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import "./DmaModal.scss";
import { useTranslation } from "react-i18next";
import TabContent from "./TabContent";

const ScoreModal = ({
  onClose,
  modalMode,
  modalTitle,
  DefinitionPositiveLabel,
  DefinitionNegativeLabel,
  topicNameLabel,
  enterTopicNameLabel,
  saveButtonLabel,
  updateButtonLabel,
  cancelButtonLabel,
  ratingLabel,
  initialData,
  onSubmit,
  disable,
}) => {
  const isEditMode = modalMode === "edit";
  const [selectedTab, setSelectedTab] = useState("Scale");
  const [ratingInput, setRatingInput] = useState("");
  const [formData, setFormData] = useState({
    rating: 0,
    scopeLabel: "",
    scopeDefinitionPositive: "",
    scopeDefinitionNegative: "",
    scaleLabel: "",
    scaleDefinitionPositive: "",
    scaleDefinitionNegative: "",
    remediabilityLabel: "",
    remediabilityDefinitionPositive: "",
    remediabilityDefinitionNegative: "",
    magnitudeLabel: "",
    magnitudeDefinitionPositive: "",
    magnitudeDefinitionNegative: "",
  });

  const [formErrors, setFormErrors] = useState({
    ratingError: "",
    scopeLabelError: "",
    scopeDefinitionPositiveError: "",
    scopeDefinitionNegativeError: "",
    scaleLabelError: "",
    scaleDefinitionPositiveError: "",
    scaleDefinitionNegativeError: "",
    remediabilityLabelError: "",
    remediabilityDefinitionPositiveError: "",
    remediabilityDefinitionNegativeError: "",
    magnitudeLabelError: "",
    magnitudeDefinitionPositiveError: "",
    magnitudeDefinitionNegativeError: "",
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (isEditMode && initialData) {
      setFormData(initialData);
      setRatingInput(initialData.rating);
    }
  }, [isEditMode, initialData]);

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    if (formErrors[`${field}Error`]) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [`${field}Error`]: "",
      }));
    }
  };

  const handleRatingChange = (e) => {
    const value = e.target.value;
    if (!/^\d*$/.test(value)) {
      return;
    }
    if (value === "") {
      setRatingInput("");
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        ratingError: "",
      }));
      return;
    }
    const ratingValue = parseInt(value, 10);
    if (ratingValue >= 0 && ratingValue <= 100) {
      setRatingInput(value);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        ratingError: "",
      }));
    } else if (value !== "") {
      alert("Rating must be between 0 and 100.");
    }
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case "Scale":
        return (
          <TabContent
            topicNameLabel={topicNameLabel}
            enterTopicNameLabel={enterTopicNameLabel}
            topicName={formData.scaleLabel}
            handleInputChange={handleInputChange}
            definitionPositive={formData.scaleDefinitionPositive}
            definitionNegative={formData.scaleDefinitionNegative}
            DefinitionPositiveLabel={DefinitionPositiveLabel}
            DefinitionNegativeLabel={DefinitionNegativeLabel}
            fieldPrefix="scale"
            formErrors={formErrors}
          />
        );
      case "Scope":
        return (
          <TabContent
            topicNameLabel={topicNameLabel}
            enterTopicNameLabel={enterTopicNameLabel}
            topicName={formData.scopeLabel}
            handleInputChange={handleInputChange}
            definitionPositive={formData.scopeDefinitionPositive}
            definitionNegative={formData.scopeDefinitionNegative}
            DefinitionPositiveLabel={DefinitionPositiveLabel}
            DefinitionNegativeLabel={DefinitionNegativeLabel}
            fieldPrefix="scope"
            formErrors={formErrors}
          />
        );
      case "Remediability":
        return (
          <TabContent
            topicNameLabel={topicNameLabel}
            enterTopicNameLabel={enterTopicNameLabel}
            topicName={formData.remediabilityLabel}
            handleInputChange={handleInputChange}
            definitionPositive={formData.remediabilityDefinitionPositive}
            definitionNegative={formData.remediabilityDefinitionNegative}
            DefinitionPositiveLabel={DefinitionPositiveLabel}
            DefinitionNegativeLabel={DefinitionNegativeLabel}
            fieldPrefix="remediability"
            formErrors={formErrors}
          />
        );
      case "Financial magnitude":
        return (
          <TabContent
            topicNameLabel={topicNameLabel}
            enterTopicNameLabel={enterTopicNameLabel}
            topicName={formData.magnitudeLabel}
            handleInputChange={handleInputChange}
            definitionPositive={formData.magnitudeDefinitionPositive}
            definitionNegative={formData.magnitudeDefinitionNegative}
            DefinitionPositiveLabel={DefinitionPositiveLabel}
            DefinitionNegativeLabel={DefinitionNegativeLabel}
            fieldPrefix="magnitude"
            formErrors={formErrors}
          />
        );
      default:
        return null;
    }
  };

  const validateFields = () => {
    let isValid = true;
    let errors = {};
    if (!ratingInput) {
      errors.ratingError = "Rating is required";
      isValid = false;
    }
    const tabFields = {
      Scale: [
        "scaleLabel",
        "scaleDefinitionPositive",
        "scaleDefinitionNegative",
      ],
      Scope: [
        "scopeLabel",
        "scopeDefinitionPositive",
        "scopeDefinitionNegative",
      ],
      Remediability: [
        "remediabilityLabel",
        "remediabilityDefinitionPositive",
        "remediabilityDefinitionNegative",
      ],
      "Financial magnitude": [
        "magnitudeLabel",
        "magnitudeDefinitionPositive",
        "magnitudeDefinitionNegative",
      ],
    };

    const currentFields = tabFields[selectedTab];
    currentFields.forEach((field) => {
      if (!formData[field]) {
        errors[`${field}Error`] = "This field is required";
        isValid = false;
      }
    });

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = () => {
    if (validateFields()) {
      const finalData = {
        ...formData,
        rating: parseInt(ratingInput, 10),
      };
      onSubmit(finalData);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="sustain-modal modal">
        <div className="modal-header">
          <h2>{modalTitle}</h2>
        </div>
        <div className="rating-div">
          <div className="rating-input-div">
            <div className="form-row">
              <div className="form-group">
                <MotifMessage
                  data-testid="topic-name"
                  className="rating-div-topic"
                >
                  {ratingLabel}*
                </MotifMessage>
                <MotifFormField>
                  <MotifInput
                    className="rating-input"
                    hideClearButton={true}
                    value={ratingInput}
                    onChange={handleRatingChange}
                  />
                </MotifFormField>
                {formErrors[`ratingError`] && (
                  <MotifErrorMessage>
                    {formErrors[`ratingError`]}
                  </MotifErrorMessage>
                )}
              </div>
            </div>
          </div>
          <div className="description-div">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
        </div>
        <div className="modal-body">
          <div className="modal-sidebar-score">
            <ul>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Scale" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Scale")}
                >
                  {t("Scale")}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Scope" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Scope")}
                >
                  {t("Scope")}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Remediability" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Remediability")}
                >
                  {t("Remediability")}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Financial magnitude" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Financial magnitude")}
                >
                  {t("Financial magnitude")}
                </button>
              </li>
            </ul>
          </div>
          <div className="modal-content">{renderTabContent()}</div>
        </div>
        <div className="modal-footer">
          <MotifButton
            onClick={onClose}
            className="button cancel-button"
            disabled={disable}
          >
            {cancelButtonLabel}
          </MotifButton>
          <MotifButton
            className="button done-button"
            onClick={handleSubmit}
            disabled={disable}
          >
            {isEditMode ? updateButtonLabel : saveButtonLabel}
          </MotifButton>
        </div>
      </div>
    </div>
  );
};

ScoreModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  topicNameLabel: PropTypes.string.isRequired,
  DefinitionPositiveLabel: PropTypes.string.isRequired,
  DefinitionNegativeLabel: PropTypes.string.isRequired,
  enterTopicNameLabel: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  ratingLabel: PropTypes.string.isRequired,
  initialData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  disable: PropTypes.bool,
};

export default ScoreModal;

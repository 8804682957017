import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import PropTypes from "prop-types";

const WaterfallChart = ({ data, width = 800, height = 400 }) => {
  const svgRef = useRef();
  useEffect(() => {
    d3.select(svgRef.current).selectAll("*").remove();

    const margin = { top: 60, right: 20, bottom: 80, left: 80 };
    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    // Calculate cumulative values and prepare waterfall data
    const waterfallData = data?.map((d, i) => ({
      ...d,
      start: i === 0 ? 0 : data[i - 1].cummulativeValue,
      end: d.cummulativeValue,
    }));

    // Calculate total by type for the stacked bar
    const types = Array.from(new Set(data.map((d) => d.type)));
    const typeTotals = types.map((type) => ({
      type,
      value: d3.sum(
        data.filter((d) => d.type === type),
        (d) => d.value
      ),
    }));

    let cumulativeTotal = 0;
    const stackedData = typeTotals.map((d) => {
      const start = cumulativeTotal;
      cumulativeTotal += d.value;
      return { ...d, start, end: cumulativeTotal, label: "Total" };
    });
    const extendedData = [...waterfallData, ...stackedData];

    // Set up SVG canvas
    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMinYMin meet")
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Define scales
    const x = d3
      .scaleBand()
      .domain(extendedData.map((d) => d.label))
      .range([0, chartWidth])
      .padding(0.3);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(extendedData, (d) => d.end)])
      .nice()
      .range([chartHeight, 0]);

    const colorScale = d3
      .scaleOrdinal()
      .domain(types)
      .range(d3.schemeCategory10);

    // Draw x-axis with rotated text labels
    svg
      .append("g")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(d3.axisBottom(x).tickSize(0))
      .selectAll(".tick text")
      .attr("text-anchor", "end")
      .attr("transform", "rotate(-45)")
      .attr("dy", "0.35em")
      .attr("dx", "-0.5em")

      .each(function (d) {
        const text = d3.select(this);
        const labelText = d;
        const maxLabelWidth = x.bandwidth();

        // Truncate text with ellipsis
        if (this.getComputedTextLength() > maxLabelWidth) {
          const truncatedText =
            labelText.length > 10 ? labelText.slice(0, 10) + "…" : labelText;
          text.text(truncatedText);
        }

        // Add a tooltip to show full text on hover
        text.append("title").text(labelText);
      });

    svg
      .selectAll(".bar")
      .data(waterfallData)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => x(d.label))
      .attr("y", (d) => y(d.end))
      .attr("width", x.bandwidth())
      .attr("height", (d) => chartHeight - y(d.value))
      .attr("fill", (d) => colorScale(d.type));

    // Draw connectors
    svg
      .selectAll(".connector")
      .data(waterfallData.slice(0, -1))
      .enter()
      .append("line")
      .attr("class", "connector")
      .attr("x1", (d) => x(d.label) + x.bandwidth() / 2)
      .attr("x2", (d, i) => x(waterfallData[i + 1].label) + x.bandwidth() / 2)
      .attr("y1", (d) => y(d.end))
      .attr("y2", (d, i) => y(waterfallData[i + 1].start))
      .attr("stroke", "#999")
      .attr("stroke-width", 1);

    // Draw the stacked total bar
    svg
      .selectAll(".total-bar")
      .data(stackedData)
      .enter()
      .append("rect")
      .attr("class", "total-bar")
      .attr("x", x("Total"))
      .attr("y", (d) => y(d.end))
      .attr("width", x.bandwidth())
      .attr("height", (d) => chartHeight - y(d.value))
      .attr("fill", (d) => colorScale(d.type));

    // Add label inside the total stacked bar
    svg
      .selectAll(".total-label")
      .data(stackedData)
      .enter()
      .append("text")
      .attr("class", "total-label")
      .attr("x", x("Total") + x.bandwidth() / 2)
      .attr("y", (d) => y(d.start + d.value / 2))
      .attr("text-anchor", "middle")
      .attr("fill", "white")
      .attr("font-weight", "bold")
      .text((d) => d.value);

    // Add bar labels for each segment
    svg
      .selectAll(".bar-label")
      .data(waterfallData)
      .enter()
      .append("text")
      .attr("class", "bar-label")
      .attr("x", (d) => x(d.label) + x.bandwidth() / 2)
      .attr("y", (d) => y(d.end) - 5)
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .text((d) => d.value);

    // Legend inside the chart on the left
    const legend = svg
      .append("g")
      .attr("class", "legend")
      .attr("transform", `translate(0, -62)`);

    const legendItemWidth = 120;
    const legendItemHeight = 20;
    const itemsPerRow = Math.floor(chartWidth / legendItemWidth);

    types.forEach((type, i) => {
      const xPosition = (i % itemsPerRow) * legendItemWidth;
      const yPosition = Math.floor(i / itemsPerRow) * legendItemHeight;

      const legendRow = legend
        .append("g")
        .attr("transform", `translate(${xPosition}, ${yPosition})`);

      legendRow
        .append("rect")
        .attr("width", 15)
        .attr("height", 15)
        .attr("fill", colorScale(type));

      // Add truncated legend text with ellipsis and tooltip
      const fullText = type;

      legendRow
        .append("text")
        .attr("x", 20)
        .attr("y", 12)
        .attr("text-anchor", "start")
        .style("font-size", "12px")
        .text(fullText.length > 10 ? fullText.slice(0, 10) + "…" : fullText)
        .append("title") // Tooltip with full text
        .text(fullText);
    });
  }, [data, height, width]);

  return <svg ref={svgRef} style={{ width: "100%", height: "100%" }}></svg>;
};

WaterfallChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      cummulativeValue: PropTypes.number,
      type: PropTypes.string,
    })
  ).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default WaterfallChart;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_GL_URL,
  CREATE_GL_URL,
  EDIT_GL_URL,
  DELETE_GL_URL,
} from "../../services/Api";

export const fetchAllGl = createAsyncThunk(
  "getAllGlData",
  async ({ moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_ALL_GL_URL}/${moduleId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching projects:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching projects"
      );
    }
  }
);

export const createGl = createAsyncThunk(
  "createGl",
  async ({ moduleId, glData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${CREATE_GL_URL}/${moduleId}`,
        glData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating Data:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error creating Data"
      );
    }
  }
);

export const updateGl = createAsyncThunk(
  "updateGl",
  async ({ glId, moduleId, glData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${EDIT_GL_URL}/${glId}?moduleId=${moduleId}`,
        glData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating Data:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error updating Data"
      );
    }
  }
);

export const deleteGl = createAsyncThunk(
  "deleteGl",
  async ({ glId, moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${DELETE_GL_URL}/${glId}?moduleId=${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting Data:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error deleting Data"
      );
    }
  }
);

const fetchAllGlSlice = createSlice({
  name: "allGldata",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllGl.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(fetchAllGl.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchAllGl.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.errorMessage = action.payload;
      })
      .addCase(createGl.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(createGl.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(createGl.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateGl.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateGl.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateGl.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteGl.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(deleteGl.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(deleteGl.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      });
  },
});

export default fetchAllGlSlice.reducer;

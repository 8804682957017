import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./Header.scss";
import MenuIcon from "@mui/icons-material/Menu";
import {
  MotifButton,
  MotifAvatar,
  MotifSelect,
  MotifOption,
} from "@ey-xd/motif-react";
import notice from "../../assets/files/PrivacyNoticeESGSuite3.0.pdf";
import {
  PrivacyNote,
  HelpDesk,
  Maintenance,
  Location,
} from "../../constants/icons/Icons";
import EYHeaderLogo from "../../assets/images/HeaderLogo.png";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { logoutUser, setUser } from "../../features/slices/HomeSlice";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const userState = useSelector((state) => state.user);
  const { user, photo, jwtToken } = userState;
  const name = user?.name;

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (user) {
      const storedUserData = localStorage.getItem("user");
      if (storedUserData) {
        dispatch(setUser(JSON.parse(storedUserData)));
      }

      const storedLanguage = localStorage.getItem("language");
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }
  }, [dispatch, i18n, user]);

  const handleSignOut = () => {
    dispatch(logoutUser());
    localStorage.removeItem("user");
    localStorage.removeItem("language");
    navigate("/");
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("language", language);
  };

  return (
    <header className="header-container">
      <div>
        <a href="/" aria-label="Home" className="logo-title">
          <img src={EYHeaderLogo} alt="Logo" width="40" height="40" />
          <h1 className="brand-title">{t("Title")}</h1>
        </a>
      </div>
      <nav className="nav-items">
        <ul>
          <li>
            <MotifButton
              className="motif-body"
              onClick={() => window.open(notice, "_blank")}
              data-testid="privacy-note-button"
            >
              <PrivacyNote />
              {t("PrivacyNote")}
            </MotifButton>
          </li>
          <li>
            <a href="mailto:esgsuite@ey.com"  className="no-underline">
              <MotifButton
                className="motif-body"
                data-testid="help-desk-button"
              >
                <HelpDesk />
                {t("HelpDesk")}
              </MotifButton>
            </a>
          </li>
          {user && (
            <li>
              <MotifButton
                className="motif-body"
                onClick={() =>
                  navigate("/maintenance", { state: { token: jwtToken } })
                }
              >
                <Maintenance />
                {t("Maintenance")}
              </MotifButton>
            </li>
          )}
          <li>
            <MotifButton className="motif-body">
              <Location />
              USA
            </MotifButton>
          </li>
        </ul>
      </nav>
      <div className="right-menu">
        <button
          className="burger-menu"
          onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
          data-testid="menu-icon"
          aria-label="Toggle mobile menu"
        >
          <MenuIcon size={30} />
        </button>
        <MotifAvatar
          className="avatar-portal"
          description={name}
          size="medium"
          userName={name}
          src={photo ? `data:image/png;base64,${photo}` : undefined}
          onError={(e) => (e.target.src = undefined)}
          onClick={() => setDropdownVisible(!dropdownVisible)}
          data-testid="user-avatar"
        />
        {user && (
          <div
            className={`dropdown-menu ${dropdownVisible ? "show" : ""}`}
            ref={dropdownRef}
          >
            <ul>
              <li>
                <MotifSelect
                  ariaLabelledBy="select-label"
                  onChange={(e) => handleLanguageChange(e)}
                  placeholder={t("Language")}
                  value={i18n.language}
                  visibleOptions={3}
                >
                  <MotifOption value="en">EN</MotifOption>
                  <MotifOption value="es">ES</MotifOption>
                </MotifSelect>
              </li>
              <li>
                <MotifButton
                  className="motif-body"
                  onClick={handleSignOut}
                  data-testid="sign-out-button"
                >
                  {t("SignOut")}
                </MotifButton>
              </li>
            </ul>
          </div>
        )}
      </div>
      {mobileMenuVisible && (
        <div className="mobile-menu show">
          <ul>
            <li>
              <MotifButton
                className="motif-body"
                onClick={() => window.open(notice, "_blank")}
                data-testid="mobile-privacy-note-button"
              >
                <PrivacyNote />
                {t("PrivacyNote")}
              </MotifButton>
            </li>
            <li>
              <MotifButton
                className="motif-body"
                data-testid="mobile-help-desk-button"
              >
                <HelpDesk />
                {t("HelpDesk")}
              </MotifButton>
            </li>
            <li>
              <MotifButton
                className="motif-body"
                onClick={() =>
                  navigate("/maintenance", { state: { token: jwtToken } })
                }
              >
                <Maintenance />
                {t("Maintenance")}
              </MotifButton>
            </li>
            <li>
              <MotifButton className="motif-body">
                <Location />
                USA
              </MotifButton>
            </li>
          </ul>
        </div>
      )}
    </header>
  );
};

Header.propTypes = {
  isAdmin: PropTypes.bool,
};

export default Header;

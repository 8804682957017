import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TableCell, IconButton, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import ContextSelection from "../../../../components/contextSelection/ContextSelection";
import ClientTable from "../../../../components/clientTable/ClientTable";
import ScoreModal from "../../../../components/modals/dmaModals/ScoreModal";
import DeleteModal from "../../../../components/modals/deleteModal/DeleteModal";
import { Edit, Delete } from "../../../../constants/icons/Icons";
import {
  fetchScores,
  createScores,
  updateScores,
  deleteScores,
} from "../../../../features/slices/Scores";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import "./Score.scss";

const Score = () => {
  const dispatch = useDispatch();
  const { moduleId: moduleIdString } = useParams();
  const moduleId = parseInt(moduleIdString);
  const location = useLocation();
  const token = location.state?.token;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [scoreData, setScoreData] = useState([]);
  const [isScoreModalOpen, setIsScoreModalOpen] = useState(false);
  const [modalScoreMode, setModalScoreMode] = useState("add");
  const [modalScoreTitle, setModalScoreTitle] = useState("");
  const [selectedScoreRow, setSelectedScoreRow] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [scoreToDelete, setScoreToDelete] = useState(null);

  // Fetch Scores from API
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await dispatch(
          fetchScores({ moduleId, token })
        ).unwrap();
        setScoreData(transformScoreData(response.data));
      } catch (error) {
        console.error("Error fetching scores:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch, moduleId, token]);

  // Transform API response data
  const transformScoreData = (data) =>
    data.map((item) => ({
      id: item.id,
      rating: item.rating,
      scopeLabel: item.scopeLabel,
      scaleLabel: item.scaleLabel,
      remediabilityLabel: item.remediabilityLabel,
      magnitudeLabel: item.magnitudeLabel,
      scopeDefinitionPositive: item.scopeDefinitionPositive,
      scopeDefinitionNegative: item.scopeDefinitionNegative,
      scaleDefinitionPositive: item.scaleDefinitionPositive,
      scaleDefinitionNegative: item.scaleDefinitionNegative,
      remediabilityDefinitionPositive: item.remediabilityDefinitionPositive,
      remediabilityDefinitionNegative: item.remediabilityDefinitionNegative,
      magnitudeDefinitionPositive: item.magnitudeDefinitionPositive,
      magnitudeDefinitionNegative: item.magnitudeDefinitionNegative,
    }));

  // Open modals for Add/Edit/Delete actions
  const openScoreModal = (mode, row = null) => {
    setModalScoreMode(mode);
    setModalScoreTitle(mode === "add" ? t("New score") : t("Edit Score"));
    setSelectedScoreRow(row);
    setIsScoreModalOpen(true);
  };

  const openDeleteModal = (row) => {
    setScoreToDelete(row);
    setIsDeleteModalOpen(true);
  };

  // Handle Add or Edit score action
  const handleSaveOrUpdateScore = async (formData) => {
    setIsLoading(true);
    try {
      const formattedData = formatScoreData(formData);
      if (modalScoreMode === "add") {
        await dispatch(
          createScores({ moduleId, token, scoresData: formattedData })
        ).unwrap();
      } else if (modalScoreMode === "edit" && selectedScoreRow) {
        await dispatch(
          updateScores({
            moduleId,
            token,
            scoresId: selectedScoreRow.id,
            scoresData: formattedData,
          })
        ).unwrap();
      }
      await refreshScores();
    } catch (error) {
      console.error("Error saving/updating score:", error);
    } finally {
      handleCloseModal();
    }
  };



  // Format the score data before sending it to the API
  const formatScoreData = (formData) => ({
    rating: formData.rating,
    scopeLabel: formData.scopeLabel,
    scopeDefinitionPositive: formData.scopeDefinitionPositive,
    scopeDefinitionNegative: formData.scopeDefinitionNegative,
    scaleLabel: formData.scaleLabel,
    scaleDefinitionPositive: formData.scaleDefinitionPositive,
    scaleDefinitionNegative: formData.scaleDefinitionNegative,
    remediabilityLabel: formData.remediabilityLabel,
    remediabilityDefinitionPositive: formData.remediabilityDefinitionPositive,
    remediabilityDefinitionNegative: formData.remediabilityDefinitionNegative,
    magnitudeLabel: formData.magnitudeLabel,
    magnitudeDefinitionPositive: formData.magnitudeDefinitionPositive,
    magnitudeDefinitionNegative: formData.magnitudeDefinitionNegative,
  });


  const refreshScores = async () => {
    const response = await dispatch(fetchScores({ moduleId, token })).unwrap();
    setScoreData(transformScoreData(response.data));
    setIsLoading(false);
  };

  // Handle delete score action
  const handleDelete = async () => {
    if (!scoreToDelete) return;

    const remainingScores = scoreData.filter(
      (score) => score.id !== scoreToDelete.id
    );

    if (remainingScores.length < 3) {
      alert("Minimum 3 Rows should be present");
      setIsDeleteModalOpen(false);
      return;
    }
    setIsLoading(true);
    try {
      await dispatch(
        deleteScores({ scoresId: scoreToDelete.id, moduleId, token })
      ).unwrap();
      await refreshScores();
    } catch (error) {
      console.error("Error deleting score:", error);
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  // Handle close modal
  const handleCloseModal = () => setIsScoreModalOpen(false);

  const columnsScore = [
    { colName: "rating", label: t("Rating"), showSorting: true },
    { colName: "scaleLabel", label: t("Scale"), showSorting: false },
    { colName: "scopeLabel", label: t("Scope"), showSorting: false },
    {
      colName: "remediabilityLabel",
      label: t("Remediability"),
      showSorting: false,
    },
    {
      colName: "magnitudeLabel",
      label: t("Financial Magnitude"),
      showSorting: false,
    },
    { colName: "Action", label: t("Action"), showSorting: false },
  ];

  const renderScoreTableCell = (colName, value, row, index) => {
    if (colName === "Action") {
      return (
        <TableCell
          key="actions"
          style={{ textAlign: "right", paddingRight: "3.85%" }}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => openScoreModal("edit", row)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => openDeleteModal(row)}>
              <Delete />
            </IconButton>
          </Box>
        </TableCell>
      );
    }
    return <TableCell key={index}>{value}</TableCell>;
  };

  return (
    <div>
      {isLoading && (
        <MotifProgressLoader className="loader" show variant="default" />
      )}

      <ContextSelection
        addButtonText={t("Add New")}
        onClickNewProject={() => openScoreModal("add")}
        pageName={t("Scores")}
      />

      <div className="gl-card-table-switch">
        <ClientTable
          columns={columnsScore}
          data={scoreData}
          itemsPerPage={5}
          renderTableCell={renderScoreTableCell}
          generateRowKey={(row) => row.id}
        />
      </div>

      {isScoreModalOpen && (
        <ScoreModal
          onClose={handleCloseModal}
          modalTitle={modalScoreTitle}
          modalMode={modalScoreMode}
          initialData={selectedScoreRow}
          onSubmit={handleSaveOrUpdateScore}
          disable={isLoading}
          ratingLabel={t("RatingLabel")}
          topicNameLabel={t("RatingLevel")}
          enterTopicNameLabel={t("New")}
          DefinitionPositiveLabel={t("DefinitionPositive")}
          DefinitionNegativeLabel={t("DefinitionNegative")}
          DescriptionLabel={t("Definition")}
          saveButtonLabel={t("AddScore")}
          updateButtonLabel={t("Update")}
          cancelButtonLabel={t("Cancel")}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          setName={t("DeleteLabel")}
          labelText={t("DeletConfirmationSources")}
          onCancel={() => setIsDeleteModalOpen(false)}
          handleDeleteClick={handleDelete}
          cancelButtonLabel={t("Cancel")}
          deleteButtonLabel={t("DeleteLabel")}
        />
      )}
    </div>
  );
};

export default Score;

import { msalInstance } from "../constants/config/configAuth";

export const getToken = async () => {
  try {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      const response = await msalInstance.acquireTokenSilent({
        scopes: ["user.read", "User.ReadBasic.All"],
        account: accounts[0],
      });
      return response.accessToken;
    } else {
      throw new Error("No accounts found");
    }
  } catch (error) {
    console.error("Error acquiring token", error);
    throw error;
  }
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  CREATE_SH_URL,
  DELETE_SH,
  GET_ALL_SH_URL,
  GET_SH_IE_TYPES_URL,
  GET_SH_TYPES_URL,
  UPDATE_SH_URL,
} from "../../services/Api";

export const fetchStakeHolderTypes = createAsyncThunk(
  "fetchStakeHolderTypes",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_SH_TYPES_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching stake holder types:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching stake holder types"
      );
    }
  }
);

export const fetchStakeHolderIETypes = createAsyncThunk(
  "fetchStakeHolderIETypes",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_SH_IE_TYPES_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching stake holder I/E types:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching stake holder I/E types"
      );
    }
  }
);

export const fetchStakeHolder = createAsyncThunk(
  "fetchStakeHolder",
  async ({ moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_ALL_SH_URL}/${moduleId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching stake holder list:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching stake holder list"
      );
    }
  }
);

export const createNewStakeHolder = createAsyncThunk(
  "createNewStakeHolder",
  async ({ moduleId, StakeHolderData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${CREATE_SH_URL}/${moduleId}`,
        StakeHolderData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating stake holder:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error creating stake holder"
      );
    }
  }
);

export const updateStakeHolder = createAsyncThunk(
  "updateStakeHolder",
  async (
    { StakeHolderId, moduleId, StakeHolderData, token },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(
        `${UPDATE_SH_URL}/${StakeHolderId}/${moduleId}`,
        StakeHolderData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating stake holder :", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error updating stake holder"
      );
    }
  }
);

export const deleteStakeHolder = createAsyncThunk(
  "deleteUser",
  async ({ StakeHolderId, moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${DELETE_SH}/${StakeHolderId}?moduleId=${moduleId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting stake holder:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error deleting stake holder"
      );
    }
  }
);

// Slice definition
const dmaStakeHolderSlice = createSlice({
  name: "dmaStakeHolder",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: "",
    updateSuccess: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStakeHolderTypes.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchStakeHolderTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(fetchStakeHolderTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fetchStakeHolderIETypes.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchStakeHolderIETypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(fetchStakeHolderIETypes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateStakeHolder.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateStakeHolder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(updateStakeHolder.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fetchStakeHolder.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchStakeHolder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(fetchStakeHolder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createNewStakeHolder.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(createNewStakeHolder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(createNewStakeHolder.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteStakeHolder.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(deleteStakeHolder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(deleteStakeHolder.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      });
  },
});

export default dmaStakeHolderSlice.reducer;

import React, { useState, useEffect } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifSelect,
  MotifOption,
  MotifErrorMessage,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import "./DmaModal.scss";

const SourceValue = ({
  onClose,
  setName,
  handleSourceValueClick,
  modalMode,
  NameLabel,
  TypeLabel,
  DescriptionLabel,
  cancelButtonLabel,
  saveButtonLabel,
  updateButtonLabel,
  types,
  selectedRow,
  pageName,
  onDeleteClick,
  DeleteLabel,
  isDisabled
}) => {
  const [nameInput, setNameInput] = useState("");
  const [typeId, setTypeId] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (modalMode === "edit" && selectedRow) {
      setNameInput(selectedRow.name);
      switch (pageName) {
        case "Sources":
          setTypeId(selectedRow.sourceType.id);
          break;
        case "Value Chain":
          setTypeId(selectedRow.valueChainType.id);
          break;
        case "APS":
          setTypeId(selectedRow.actProServiceType.id);
          break;
        default:
          break;
      }
      setDescription(selectedRow.description);
    }
  }, [modalMode, selectedRow, pageName]);

  useEffect(() => {
    setIsFormValid(nameInput && typeId);
  }, [nameInput, typeId]);

  const isEditMode = modalMode === "edit";

  const validateFields = () => {
    const newErrors = {};
    if (!nameInput) newErrors.nameInput = "Name is required";
    if (!typeId) newErrors.typeId = "Type is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveClick = () => {
    if (!validateFields()) return;
    const sourcesData = {
      name: nameInput,
      description,
      typeId,
    };
    handleSourceValueClick(sourcesData);
  };

  const handleNameChange = (e) => {
    setNameInput(e.target.value);
    if (errors.nameInput) {
      setErrors((prevErrors) => ({ ...prevErrors, nameInput: undefined }));
    }
  };

  const handleTypeChange = (e) => {
    setTypeId(e);
    if (errors.typeId) {
      setErrors((prevErrors) => ({ ...prevErrors, typeId: undefined }));
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>{setName}</h2>
        </div>
        <div className="modal-body">
          <div className="form-row">
            <div className="form-group">
              <MotifLabel htmlFor="name-input">{NameLabel}</MotifLabel>
              <MotifFormField>
                <MotifInput
                  id="name-input"
                  data-testid="name-input"
                  className="session-modal-input session-name-input"
                  aria-describedby="session-name"
                  hideClearButton={true}
                  value={nameInput}
                  onChange={handleNameChange}
                  onKeyPress={(e) => {
                    const specialCharPattern = /[^a-zA-Z0-9 ]/;
                    if (specialCharPattern.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.nameInput && (
                  <MotifErrorMessage>{errors.nameInput}</MotifErrorMessage>
                )}
              </MotifFormField>
            </div>
            <div className="form-group">
              <MotifLabel htmlFor="type-select">{TypeLabel}</MotifLabel>
              <MotifFormField>
                <MotifSelect
                  id="type-select"
                  className="change-status-modal"
                  onChange={handleTypeChange}
                  value={typeId}
                >
                  {types?.map((type) => (
                    <MotifOption key={type.id} value={type.id}>
                      {type.name}
                    </MotifOption>
                  ))}
                </MotifSelect>
                {errors.typeId && (
                  <MotifErrorMessage>{errors.typeId}</MotifErrorMessage>
                )}
              </MotifFormField>
            </div>
          </div>
          <MotifLabel htmlFor="description-input">
            {DescriptionLabel}
          </MotifLabel>
          <TextField
            id="description-input"
            data-testid="description-input"
            placeholder=""
            multiline
            fullWidth
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="dma-modal-footer">
          <div className="left-buttons">
            {isEditMode && (
              <MotifButton
                className="button delete-button"
                onClick={onDeleteClick}
              >
                {DeleteLabel}
              </MotifButton>
            )}
          </div>
          <div className="right-buttons">
            <MotifButton onClick={onClose} className="button cancel-button">
              {cancelButtonLabel}
            </MotifButton>
            <MotifButton
              onClick={handleSaveClick}
              className="button done-button"
              disabled={isDisabled}
            >
              {isEditMode ? updateButtonLabel : saveButtonLabel}
            </MotifButton>
          </div>
        </div>
      </div>
    </div>
  );
};

SourceValue.propTypes = {
  onClose: PropTypes.func.isRequired,
  setName: PropTypes.string.isRequired,
  handleSourceValueClick: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired,
  NameLabel: PropTypes.string.isRequired,
  TypeLabel: PropTypes.string.isRequired,
  DescriptionLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  DeleteLabel: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  pageName: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedRow: PropTypes.object,
};

export default SourceValue;

import React, {
  useEffect,
  useReducer,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import TreeView from "../../../components/treeView/TreeView";
import ContextSelection from "../../../components/contextSelection/ContextSelection";
import {
  getAllIro,
  createIro,
  updateIro,
  deleteIro,
  getApsIro,
  getSourcesIro,
  getValueChainIro,
  getStakeholdersIro,
  getGeographiesIro,
  updateSourcesIro,
  updateValueChainIro,
  updateApsIro,
  updateGeographiesIro,
  updateStakeholdersIro,
  getAllSustainabilityMattersIro,
  updateSustainabilityMattersIro,
  getAllTimeframeTypes,
  getAllIroTypes,
} from "../../../features/slices/Iro";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import IroModal from "../../../components/modals/dmaModals/IroModal";
import ErrorModal from "../../../components/modals/error/errorModal";
import DeleteModal from "../../../components/modals/deleteModal/DeleteModal";
import PropTypes from "prop-types";
import "./DMAIroOverview.scss";

const initialState = {
  searchInput: "",
};

const reducer = (state, action) => {
  if (action.type === "SET_SEARCH_INPUT")
    return { ...state, searchInput: action.payload };
  else return state;
};

const DMAIroOverview = ({
  setShowAssessment,
  setAssessmentIroId,
  setIroData,
}) => {
  const [modal, setModal] = useState({ isOpen: false, title: "", mode: "add" });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    itemId: null,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const token = location.state?.token;
  const dispatch = useDispatch();
  const iroData = useSelector((state) => state.iro.data);
  const isError = useSelector((state) => state.iro.isError);
  const errorMessage = useSelector((state) => state.iro.errorMessage);
  const isLoading = useSelector((state) => state.iro.isLoading);
  const { moduleId: moduleIdString } = useParams();
  const moduleId = parseInt(moduleIdString);
  const [state, localDispatch] = useReducer(reducer, initialState);

  const [pillarOptions, setPillarOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [timeframeOptions, setTimeframeOptions] = useState([]);
  const [iroTypesOptions, setIroTypesOptions] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [updateItemID, setUpdateItemID] = useState(null);
  const [filteredUpdateData, setFilteredUpdateData] = useState(null);
  const [checkboxState, setCheckboxState] = useState({});

  const [tabData, setTabData] = useState({
    source: [],
    aps: [],
    valuechain: [],
    geographies: [],
    stakeholders: [],
    sustainablilitymatters: [],
    updatedsustainablilitymatters: [],
  });
  const [dataFetched, setDataFetched] = useState({
    source: false,
    aps: false,
    valuechain: false,
    geographies: false,
    stakeholders: false,
    sustainablilitymatters: false,
    updatedsustainablilitymatters: false,
  });

  useEffect(() => {
    if (token) {
      dispatch(getAllIro({ moduleId, token }));
      const fetchTimeframeTypesData = async () => {
        try {
          const response = await dispatch(
            getAllTimeframeTypes({ token })
          ).unwrap();
          if (response.success) {
            setTimeframeOptions(response.data);
          }
        } catch (error) {
          console.error("Error fetching timeframe types:", error);
        }
      };
      const fetchIroTypesData = async () => {
        try {
          const response = await dispatch(getAllIroTypes({ token })).unwrap();
          if (response.success) {
            setIroTypesOptions(response.data);
          }
        } catch (error) {
          console.error("Error fetching iro types:", error);
        }
      };
      fetchTimeframeTypesData();
      fetchIroTypesData();
    }
  }, [token, dispatch, moduleId, updateTrigger]);

  useEffect(() => {
    if (iroData?.data) {
      setIroData(iroData.data);
    }
  }, [iroData, setIroData]);

  const handleSearchInputChange = useCallback((event) => {
    localDispatch({ type: "SET_SEARCH_INPUT", payload: event });
  }, []);

  const filterItems = useCallback((items, query) => {
    if (!query) return items;

    const queryLowerCase = query.toLowerCase();

    const filteredItems = items
      .map((item) => {
        const areaMatches = item.children
          .filter((area) => {
            const nameMatches = area.children.filter((name) =>
              name.label.toLowerCase().includes(queryLowerCase)
            );

            if (
              area.label.toLowerCase().includes(queryLowerCase) ||
              nameMatches.length > 0
            ) {
              return {
                ...area,
                children: nameMatches,
              };
            }

            return null;
          })
          .filter(Boolean);

        if (
          item.label.toLowerCase().includes(queryLowerCase) ||
          areaMatches.length > 0
        ) {
          return {
            ...item,
            children: areaMatches,
          };
        }

        return null;
      })
      .filter(Boolean);

    return filteredItems;
  }, []);

  const memoizedIroData = useMemo(
    () => (iroData?.data ? iroData.data : []),
    [iroData]
  );

  useEffect(() => {
    if (memoizedIroData.length) {
      const uniquePillars = [
        ...new Set(memoizedIroData.map((item) => item.pillar)),
      ].map((pillar) => ({ value: pillar, label: pillar }));
      const uniqueAreas = [
        ...new Set(memoizedIroData.map((item) => item.area)),
      ].map((area) => ({ value: area, label: area }));
      setPillarOptions(uniquePillars);
      setAreaOptions(uniqueAreas);
    }
  }, [memoizedIroData]);

  const transformData = (data) => {
    const pillarMap = {};

    data.forEach((item) => {
      if (!pillarMap[item.pillar]) {
        pillarMap[item.pillar] = {
          id: `pillar-${item.id}`,
          itemId: `pillar-${item.pillar}`,
          label: item.pillar,
          itemType: "pillar",
          children: [],
        };
      }

      let area = pillarMap[item.pillar].children.find(
        (child) => child.label === item.area
      );
      if (!area) {
        area = {
          id: `area-${item.id}`,
          itemId: `area-${item.pillar}-${item.area}`,
          label: item.area,
          itemType: "area",
          children: [],
        };
        pillarMap[item.pillar].children.push(area);
      }

      area.children.push({
        id: `name-${item.id}`,
        itemId: item.id,
        label: item.name,
        itemType: "name",
      });
    });

    return Object.values(pillarMap);
  };

  const transformedData = useMemo(
    () => transformData(Array.isArray(memoizedIroData) ? memoizedIroData : []),
    [memoizedIroData]
  );

  const filteredData = useMemo(
    () => filterItems(transformedData, state.searchInput),
    [transformedData, state.searchInput, filterItems]
  );

  const findItemById = (data, id) => {
    for (let item of data) {
      if (item.id === id) return item;
      if (item.children) {
        const result = findItemById(item.children, id);
        if (result) return result;
      }
    }
    return null;
  };

  const handleEditClick = (id) => {
    try {
      const numericId =
        typeof id === "string" ? parseInt(id.replace(/^\D+/g, "")) : id;
      handleOpenModal("edit", numericId);
      const item = findItemById(memoizedIroData, numericId);
      setFilteredUpdateData(item);

      setUpdateItemID(numericId);
    } catch (error) {
      console.error("Error in handleEditClick:", error);
    }
  };

  const handleDeleteClick = (id) => {
    const numericId =
      typeof id === "string" ? parseInt(id.replace(/^\D+/g, "")) : id;
    setDeleteModal({ isOpen: true, itemId: numericId });
  };

  const handleViewClick = (id) => {
    const assessmentIroId =
      typeof id === "string" ? parseInt(id.replace(/^\D+/g, "")) : id;
    setShowAssessment(true);
    setAssessmentIroId(assessmentIroId);
  };

  const handleConfirmDelete = async () => {
    try {
      const { itemId } = deleteModal;
      await dispatch(deleteIro({ iroId: itemId, token, moduleId })).unwrap();
      setUpdateTrigger((prev) => prev + 1);
      setDeleteModal({ isOpen: false, itemId: null });
    } catch (error) {
      console.error("Deletion failed:", error);
    }
  };
  const handleOpenModal = (mode, itemId) => {
    setModal({
      isOpen: true,
      mode,
      title: mode === "add" ? t("NewIRO") : t("EditIRO"),
    });
  };

  const handleCloseModal = () => {
    setModal((prev) => ({ ...prev, isOpen: false }));
    setDataFetched({
      source: false,
      aps: false,
      valuechain: false,
      geographies: false,
      stakeholders: false,
      sustainablilitymatters: false,
      updatedsustainablilitymatters: false,
    });
  };

  const createNewIroApi = async (data) => {
    if (data) {
      data.moduleId = moduleId;
      try {
        const response = await dispatch(
          createIro({ iroData: data, token })
        ).unwrap();
        const newId = response.data.id;
        setUpdateTrigger((prev) => prev + 1);
        handleCloseModal();
        handleEditClick(newId);
      } catch (error) {
        console.error("Creation failed:", error);
        setUpdateTrigger((prev) => prev + 1);
      }
    } else {
      alert("Please fill in all required fields");
    }
  };

  const updateIroApi = async (data) => {
    if (data) {
      data.moduleId = moduleId;
      data.moduleIROId = updateItemID;
      try {
        const response = await dispatch(
          updateIro({
            iroId: updateItemID,
            iroData: data,
            token,
          })
        ).unwrap();
        if (response.success) {
          alert("Data updated");
          setUpdateTrigger((prev) => prev + 1);
          handleCloseModal();
        }
      } catch (error) {
        console.error("Updation failed:", error);
        setUpdateTrigger((prev) => prev + 1);
      }
    } else {
      alert("Please fill in all required fields");
    }
  };

  const transformItems = (data) => {
    return data.map((item) => ({
      id: item.id,
      itemId: item.id,
      label: item.label || item.name,
      children: item.children || [],
      isActive: item.isActive || false,
    }));
  };

  const fetchTabData = (tab) => {
    if (!dataFetched[tab]) {
      switch (tab) {
        case "source":
          dispatch(getSourcesIro({ iroId: updateItemID, token, moduleId }))
            .unwrap()
            .then((data) => {
              setTabData((prev) => ({ ...prev, source: data.data }));
              setDataFetched((prev) => ({ ...prev, source: true }));
            })
            .catch((error) => console.error(error));
          break;
        case "aps":
          dispatch(getApsIro({ iroId: updateItemID, token, moduleId }))
            .unwrap()
            .then((data) => {
              setTabData((prev) => ({ ...prev, aps: data.data }));
              setDataFetched((prev) => ({ ...prev, aps: true }));
            })
            .catch((error) => console.error(error));
          break;
        case "valuechain":
          dispatch(getValueChainIro({ iroId: updateItemID, token, moduleId }))
            .unwrap()
            .then((data) => {
              setTabData((prev) => ({ ...prev, valuechain: data.data }));
              setDataFetched((prev) => ({ ...prev, valuechain: true }));
            })
            .catch((error) => console.error(error));
          break;
        case "geographies":
          dispatch(getGeographiesIro({ iroId: updateItemID, token, moduleId }))
            .unwrap()
            .then((data) => {
              setTabData((prev) => ({ ...prev, geographies: data.data }));
              setDataFetched((prev) => ({ ...prev, geographies: true }));
            })
            .catch((error) => console.error(error));
          break;
        case "stakeholders":
          dispatch(getStakeholdersIro({ iroId: updateItemID, token, moduleId }))
            .unwrap()
            .then((data) => {
              setTabData((prev) => ({ ...prev, stakeholders: data.data }));
              setDataFetched((prev) => ({ ...prev, stakeholders: true }));
            })
            .catch((error) => console.error(error));
          break;
        case "sustainablilitymatters":
          dispatch(
            getAllSustainabilityMattersIro({
              moduleId,
              iroId: updateItemID,
              token,
            })
          )
            .unwrap()
            .then((data) => {
              const transformedSmData = transformItems(data.data);
              setTabData((prev) => ({
                ...prev,
                sustainablilitymatters: transformedSmData,
              }));
              setDataFetched((prev) => ({
                ...prev,
                sustainablilitymatters: true,
              }));
            })
            .catch((error) => console.error(error));
          break;
        default:
          break;
      }
    }
  };

  const updateTabData = async (tab) => {
    const apiCalls = {
      source: updateSourcesIro,
      aps: updateApsIro,
      valuechain: updateValueChainIro,
      geographies: updateGeographiesIro,
      stakeholders: updateStakeholdersIro,
      sustainablilitymatters: updateSustainabilityMattersIro,
    };

    const tabDataMapping = {
      source: tabData.source,
      aps: tabData.aps,
      valuechain: tabData.valuechain,
      geographies: tabData.geographies,
      stakeholders: tabData.stakeholders,
      sustainablilitymatters: tabData.updatedsustainablilitymatters,
    };

    try {
      // Ensure the correct API is called for the provided tab
      const currentTab = tab.toLowerCase().replace(/\s+/g, "");
      if (apiCalls.hasOwnProperty(currentTab)) {
        const response = await dispatch(
          apiCalls[currentTab]({
            iroId: updateItemID,
            iroData: tabDataMapping[currentTab].map((item) => ({
              ...item,
            })),
            token,
            moduleId,
          })
        ).unwrap();

        if (!response.success) {
          throw new Error(`Update failed for ${currentTab}`);
        }
        setUpdateTrigger((prev) => prev + 1);
        handleCloseModal();
      }
    } catch (error) {
      console.error(`Update failed:`, error);
    }
  };

  const handleError = () => {
    navigate("/");
  };

  useEffect(() => {
    if (memoizedIroData.length) {
      const newState = {};
      memoizedIroData.forEach((item) => {
        newState[`name-${item.id}`] = item?.isAssessmentDone || false;
      });
      setCheckboxState(newState);
    }
  }, [memoizedIroData]);

  if (isError) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={errorMessage}
          handleButtonClick={handleError}
          deleteButtonLabel={t("Reload")}
        />
      </div>
    );
  }

  return (
    <>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      <div className="Iro-file-explorer">
        <div className="Iro-view-switch">
          <ContextSelection
            addButtonText="New IRO"
            pageName="IRO"
            onClickNewProject={() => handleOpenModal("add")}
            onChangeSearchInput={handleSearchInputChange}
          />
        </div>
        <div className="Iro-table">
          <TreeView
            items={filteredData}
            defaultExpandedItems={[]}
            defaultSelectedItems=""
            onEditClick={handleEditClick}
            onViewClick={handleViewClick}
            onDeleteClick={handleDeleteClick}
            checkboxState={checkboxState}
            handleCheckboxChange={() => {}}
            setShowAssessment={setShowAssessment}
            pageName="IRO"
          />
        </div>
        {modal.isOpen && (
          <IroModal
            onClose={handleCloseModal}
            modalTitle={modal.title}
            modalMode={modal.mode}
            nameLabel={t("Name")}
            typeLabel={t("IROType")}
            areaLabel={t("Area")}
            pillarLabel={t("Pillar")}
            selectAreaLabel={t("SelectArea")}
            SelectPillar={t("SelectPillar")}
            BasicLabel={t("Definition")}
            DescriptionLabel={t("Description")}
            SourceLabel={t("SourceLabel")}
            ValueChainLabel={t("ValueChain")}
            APSLabel={t("APSLabel")}
            SustainablilityMattersLabel={t("Sustainablilitymatters")}
            StakeholdersLabel={t("Stakeholders")}
            GeographiesLabel={t("Geographies")}
            saveButtonLabel={t("addIRO")}
            updateButtonLabel={t("updateIRO")}
            cancelButtonLabel={t("Cancel")}
            filterByLabel={t("FilterBy")}
            pillarOptions={pillarOptions}
            areaOptions={areaOptions}
            handleDoneClick={
              modal.mode === "add" ? createNewIroApi : updateIroApi
            }
            filteredUpdateData={filteredUpdateData}
            fetchTabData={fetchTabData}
            tabData={tabData}
            disable={isLoading}
            setTabData={setTabData}
            updateTabData={updateTabData}
            filterItems={filterItems}
            TimeframeLabel={t("TimeframeLabel")}
            SelectTimeframeLabel={t("SelectTimeframeLabel")}
            SelectType={t("SelectType")}
            timeframeOptions={timeframeOptions}
            iroTypesOptions={iroTypesOptions}
          />
        )}
        {deleteModal.isOpen && (
          <DeleteModal
            isOpen={deleteModal.isOpen}
            onClose={() => setDeleteModal({ isOpen: false, itemId: null })}
            setName={t("DeleteLabel")}
            labelText={t("DeletConfirmationSources")}
            onCancel={() => setDeleteModal({ isOpen: false, itemId: null })}
            handleDeleteClick={handleConfirmDelete}
            cancelButtonLabel={t("Cancel")}
            deleteButtonLabel={t("DeleteLabel")}
          />
        )}
      </div>
    </>
  );
};
DMAIroOverview.propTypes = {
  setShowAssessment: PropTypes.func,
  setAssessmentIroId: PropTypes.func,
  setIroData: PropTypes.func,
};

export default DMAIroOverview;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_IRO_MODULE_BY_ID,
  GET_ALL_MODULE_RATING,
  GET_VALUE_CHAIN_REFERENCE_LIST,
  GET_APS_REFERENCE_LIST,
  GET_STAKEHOLDER_REFERENCE_LIST,
  SAVE_RACIMAPPING_URL,
  CREATE_IRO_ASSESSMENT,
  GET_IRO_ASSESSMENT,
  UPDATE_IRO_ASSESSMENT,
  DELETE_IRO_ASSESSMENT,
} from "../../services/Api";

export const fetchIroModuleById = createAsyncThunk(
  "fetchIroModuleById",
  async ({ moduleIROId, moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_IRO_MODULE_BY_ID}${moduleIROId}?moduleId=${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching Iro module :", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching  Iro module"
      );
    }
  }
);

export const getAllModuleRating = createAsyncThunk(
  "getAllModuleRating",
  async ({ moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_ALL_MODULE_RATING}/${moduleId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching Ratings :", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching rating dropdown"
      );
    }
  }
);

export const getValueChainRefList = createAsyncThunk(
  "getValueChainRefList",
  async ({ moduleIROId, moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_VALUE_CHAIN_REFERENCE_LIST}?moduleIROId=${moduleIROId}&moduleId=${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching Ratings :", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching value chain reference list"
      );
    }
  }
);

export const getAPSRefList = createAsyncThunk(
  "getAPSRefList",
  async ({ moduleIROId, moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_APS_REFERENCE_LIST}?moduleIROId=${moduleIROId}&moduleId=${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching Ratings :", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching APS reference list"
      );
    }
  }
);

export const getStakeholderRefList = createAsyncThunk(
  "getStakeholderRefList",
  async ({ moduleIROId, moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_STAKEHOLDER_REFERENCE_LIST}?moduleIROId=${moduleIROId}&moduleId=${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching Ratings :", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching stakeholder reference list"
      );
    }
  }
);

export const saveRACIMapping = createAsyncThunk(
  "saveRACIMapping",
  async ({ moduleIROId, moduleId, raciData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${SAVE_RACIMAPPING_URL}/${moduleIROId}?moduleId=${moduleId}`,
        raciData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error saving/updating IRO RACI Mapping:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error saving/updating IRO RACI Mapping"
      );
    }
  }
);

export const createIROAssessment = createAsyncThunk(
  "createIROAssessment",
  async ({ iroAssessmentData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${CREATE_IRO_ASSESSMENT}`,
        iroAssessmentData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating IRO Assessment:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error creating IRO Assessment"
      );
    }
  }
);

export const getIROAssessment = createAsyncThunk(
  "getIROAssessment",
  async ({ moduleIROId, moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_IRO_ASSESSMENT}/${moduleIROId}?moduleId=${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching IRO Assessment:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching IRO Assessment"
      );
    }
  }
);

export const updateIROAssessment = createAsyncThunk(
  "updateIROAssessment",
  async ({ iroAssessmentData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${UPDATE_IRO_ASSESSMENT}`,
        iroAssessmentData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating IRO Assessment:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error updating IRO Assessment"
      );
    }
  }
);

export const deleteIROAssessment = createAsyncThunk(
  "deleteIROAssessment",
  async ({ assessmentId,moduleIROId, moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${DELETE_IRO_ASSESSMENT}/${assessmentId}/${moduleIROId}?moduleId=${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting Iro assessment :", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error deleting Iro assessment"
      );
    }
  }
);

// Slice definition
const dmaIroAssessment = createSlice({
  name: "dmaIroAssessment",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: "",
    updateSuccess: false,
    types: [],
    postSuccess: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIroModuleById.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchIroModuleById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(fetchIroModuleById.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllModuleRating.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getAllModuleRating.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(getAllModuleRating.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getValueChainRefList.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getValueChainRefList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(getValueChainRefList.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAPSRefList.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getAPSRefList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(getAPSRefList.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getStakeholderRefList.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getStakeholderRefList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(getStakeholderRefList.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(saveRACIMapping.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(saveRACIMapping.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(saveRACIMapping.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createIROAssessment.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(createIROAssessment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(createIROAssessment.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getIROAssessment.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getIROAssessment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(getIROAssessment.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateIROAssessment.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateIROAssessment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(updateIROAssessment.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteIROAssessment.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(deleteIROAssessment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(deleteIROAssessment.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      });
  },
});

export default dmaIroAssessment.reducer;

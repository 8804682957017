import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_SUSTAINABILITY_MATTERS,
  CREATE_SUSTAINABILITY_MATTERS,
  UPDATE_SUSTAINABILITY_MATTERS,
  DELETE_SUSTAINABILITY_MATTERS,
  GET_ESRS_URL,
  GET_ESRS_AR16_URL,
} from "../../services/Api";

export const getAllSustainabilityMatters = createAsyncThunk(
  "getAllSustainabilityMatters",
  async ({ moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_ALL_SUSTAINABILITY_MATTERS}/${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching Sustainabillity Matter data:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching Sustainabillity Matter data"
      );
    }
  }
);

export const createNewSustainabilityMatters = createAsyncThunk(
  "createNewSustainabilityMatters",
  async (
    { moduleId, sustainabilityMattersData, token },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${CREATE_SUSTAINABILITY_MATTERS}/${moduleId}`,
        sustainabilityMattersData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating sustainability matters:", error);
      return rejectWithValue(
        error.response.data?.message ||
          error?.message ||
          "Error creating sustainability matters"
      );
    }
  }
);

export const updateSustainabilityMatters = createAsyncThunk(
  "updateSustainabilityMatters",
  async (
    { sustainabilityMattersId, moduleId, sustainabilityMattersData, token },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(
        `${UPDATE_SUSTAINABILITY_MATTERS}/${sustainabilityMattersId}?moduleId=${moduleId}`,
        sustainabilityMattersData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating sustainability matters:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error updating sustainability matters"
      );
    }
  }
);

export const deleteSustainabilityMatters = createAsyncThunk(
  "deleteSustainabilityMatters",
  async ({ sustainabilityMattersId, moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${DELETE_SUSTAINABILITY_MATTERS}/${sustainabilityMattersId}?moduleId=${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting sustainability matters:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error deleting sustainability matters"
      );
    }
  }
);

export const fetchEsrs = createAsyncThunk(
  "fetchEsrs",
  async ({ moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_ESRS_URL}/?moduleId=${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching esrs values:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching esrs values"
      );
    }
  }
);

export const fetchEsrsAr16 = createAsyncThunk(
  "fetchEsrsAr16",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_ESRS_AR16_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching esrs ar16 values:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching esrs ar16 values"
      );
    }
  }
);



// Slice definition
const dmaSustainabilityMattersSlice = createSlice({
  name: "dmaSustainabilityMatters",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: "",
    updateSuccess: false,
    types: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSustainabilityMatters.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getAllSustainabilityMatters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(getAllSustainabilityMatters.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateSustainabilityMatters.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateSustainabilityMatters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(updateSustainabilityMatters.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createNewSustainabilityMatters.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(createNewSustainabilityMatters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(createNewSustainabilityMatters.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteSustainabilityMatters.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(deleteSustainabilityMatters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(deleteSustainabilityMatters.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fetchEsrs.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchEsrs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(fetchEsrs.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fetchEsrsAr16.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchEsrsAr16.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(fetchEsrsAr16.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
     
  },
});

export default dmaSustainabilityMattersSlice.reducer;

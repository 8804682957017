import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { MotifBreadcrumb, MotifBreadcrumbItem } from "@ey-xd/motif-react";
import { Link } from "react-router-dom";
import "./Stepper.scss";

const Stepper = ({ children, onChange, items, activeIndex }) => {
  const [currentIndex, setCurrentIndex] = useState(activeIndex);

  // Update local state when activeIndex from parent changes
  useEffect(() => {
    if (activeIndex !== currentIndex) {
      setCurrentIndex(activeIndex);
    }
  }, [activeIndex, currentIndex]);

  // Call onChange only if the step index actually changes
  const handleItemClick = (index) => {
    if (index !== currentIndex) {
      setCurrentIndex(index);
      if (onChange) onChange(index);
    }
  };

  return (
    <div className="stepper-background">
      <div className="breadcrumbs-container">
        <MotifBreadcrumb
          triggerButtonProps={{
            "aria-label": "Custom Trigger Button aria-label",
            title: "Custom Trigger Button title",
          }}
        >
          {items.map(({ label, link, token }, index) => (
            <MotifBreadcrumbItem key={link}>
              {index === items.length - 1 ? (
                <span className="breadcrumb-inactive-stepper">{label}</span>
              ) : (
                <Link to={link} state={{ token }}>
                  <span className="breadcrumb-active-stepper">{label}</span>
                </Link>
              )}
            </MotifBreadcrumbItem>
          ))}
        </MotifBreadcrumb>
      </div>
      <div className="custom-stepper" role="tablist">
        {React.Children.map(children, (child, index) => (
          <button
            key={index}
            className={`stepper-box-item ${
              currentIndex === index ? "active" : ""
            }`}
            onClick={() => handleItemClick(index)}
            data-testid={`box-item-${index}`}
            role="tab"
            aria-selected={currentIndex === index}
            aria-controls={`step-${index}`}
          >
            <div className="stepper-content">
              <div className="stepper-icon">{child.props.icon}</div>
              <div className="stepper-text">{child.props.text}</div>
            </div>
            {index < children.length - 1 && <div className="line"></div>}
          </button>
        ))}
      </div>
      <div
        className="stepper-section-content"
        data-testid="section-content"
        role="tabpanel"
        id={`step-${currentIndex}`}
      >
        {React.Children.toArray(children)[currentIndex].props.children}
      </div>
    </div>
  );
};

Stepper.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      token: PropTypes.string,
    })
  ).isRequired,
  activeIndex: PropTypes.number.isRequired,
};

Stepper.defaultProps = {
  onChange: () => {},
};

export default Stepper;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_DGA_SM,
  CREATE_DGA_SM,
  UPDATE_DGA_SM,
} from "../../services/Api";

export const getDGASustainabilityMatters = createAsyncThunk(
  "getDGASustainabilityMatters",
  async ({ moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_ALL_DGA_SM}/${moduleId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching Sustainabillity Matter data:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching Sustainabillity Matter data"
      );
    }
  }
);

export const createDGASustainabilityMatters = createAsyncThunk(
  "createDGASustainabilityMatters",
  async ({ sustainabilityMattersData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${CREATE_DGA_SM}`,
        sustainabilityMattersData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating sustainability matters:", error);
      return rejectWithValue(
        error.response.data?.message ||
          error?.message ||
          "Error creating sustainability matters"
      );
    }
  }
);

export const updateDGASustainabilityMatters = createAsyncThunk(
  "updateDGASustainabilityMatters",
  async (
    { sustainabilityMattersId, moduleId, sustainabilityMattersData, token },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(
        `${UPDATE_DGA_SM}/${sustainabilityMattersId}?moduleId=${moduleId}`,
        sustainabilityMattersData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating sustainability matters:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error updating sustainability matters"
      );
    }
  }
);

// Slice definition
const dgaSustainabilityMattersSlice = createSlice({
  name: "dgaSustainabilityMatters",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: "",
    updateSuccess: false,
    types: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDGASustainabilityMatters.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getDGASustainabilityMatters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(getDGASustainabilityMatters.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateDGASustainabilityMatters.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateDGASustainabilityMatters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(updateDGASustainabilityMatters.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createDGASustainabilityMatters.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(createDGASustainabilityMatters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(createDGASustainabilityMatters.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      });
  },
});

export default dgaSustainabilityMattersSlice.reducer;

import React, { useState } from "react";
import PropTypes from "prop-types";
import "./SpecificationTab.scss";

const SpecificationTab = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
      <div
        className="context-stepper context-background"
        style={{ fontFamily: "EYInterstate !important" }}
      >
        {React.Children.map(children, (child, index) => {
          const key = child.key || index;
          return (
            <button
              key={key}
              className={`context-box-item ${
                activeIndex === index ? "active" : ""
              }`}
              onClick={() => handleItemClick(index)}
              data-testid={`context-box-item-${index}`}
            >
              <div className="context-content">
                <div className="context-text">{child.props.text}</div>
              </div>
            </button>
          );
        })}
      </div>
      <div
        className="context-section-content"
        data-testid="context-section-content"
      >
        {React.Children.toArray(children)[activeIndex].props.children}
      </div>
    </>
  );
};

SpecificationTab.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SpecificationTab;

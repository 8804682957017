import React, { useState, useEffect, forwardRef } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifSelect,
  MotifOption,
  MotifErrorMessage,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import "./IroAssessmentModal.scss";
import { useTranslation } from "react-i18next";
import { Cross, InfoIcon } from "../../../constants/icons/Icons";
import DeleteModal from "../deleteModal/DeleteModal";

const IroAssessmentModal = forwardRef(
  (
    {
      iroType,
      onClose,
      modalMode,
      modalTitle,
      ratingDropDownArray,
      saveButtonLabel,
      updateButtonLabel,
      cancelButtonLabel,
      valueChainDropDown,
      handleDoneClick,
      deleteButtonLabel,
      ratingLabel,
      descriptionLabel,
      iroTypeLabel,
      valueChainLabel,
      positiveNegativeLabel,
      actualPotentialLabel,
      filteredUpdateData,
      moduleId,
      moduleIROId,
      handleDelete,
      infoRating,
      closeModal,
      likelihood,
    },
    ref
  ) => {
    const isEditMode = modalMode === "edit";
    const [selectedTab, setSelectedTab] = useState("Basic");
    const { t } = useTranslation();
    const [showInfo, setShowInfo] = useState(false);
    const [info, setInfo] = useState(null);

    const [formData, setFormData] = useState({
      ScaleId: 0,
      ScaleDescription: "",
      ScopeId: 0,
      ScopeDescription: "",
      RemediabilityId: 0,
      RemediabilityDescription: "",
      MagnitudeId: 0,
      MagnitudeDescription: "",
      LikelihoodId: 0,
      LikelihoodDescription: "",
      valueChainId: [],
      positive: null,
      actual: null,
    });

    const [isPositiveError, setIsPositiveError] = useState("");
    const [actualValidation, setActualValidation] = useState("");

    React.useImperativeHandle(ref, () => ({
      handleDeleteCloseModal,
    }));

    useEffect(() => {
      if (filteredUpdateData && isEditMode) {
        setFormData({
          ScaleId: filteredUpdateData.assessment.moduleRatingScaleId || null,
          ScaleDescription:
            filteredUpdateData.assessment.ratingScaleDescription || "",
          ScopeId: filteredUpdateData.assessment.moduleRatingScopeId || null,
          ScopeDescription:
            filteredUpdateData.assessment.ratingScopeDescription || "",
          RemediabilityId:
            filteredUpdateData.assessment.moduleRatingRemediabilityId || null,
          RemediabilityDescription:
            filteredUpdateData.assessment.ratingRemediabilityDescription || "",
          MagnitudeId:
            filteredUpdateData.assessment.moduleRatingMagnitudeId || null,
          MagnitudeDescription:
            filteredUpdateData.assessment.ratingRatingMagnitudeIdDescription ||
            "",
          LikelihoodId:
            filteredUpdateData.assessment.moduleRatingLikelihoodId || null,
          LikelihoodDescription:
            filteredUpdateData.assessment.ratingLikelihoodDescription || "",
          valueChainId: filteredUpdateData.valueChainIds,
          positive: filteredUpdateData.assessment.isPositive,
          actual: filteredUpdateData.assessment.isActual,
        });
      }
    }, [filteredUpdateData, isEditMode]);

    const [tabConditions, setTabConditions] = useState({
      scaleDisabled: true,
      scopeDisabled: true,
      remediabilityDisabled: true,
      magnitudeDisabled: true,
      likelihoodDisabled: true,
    });
   
    // Update tab conditions whenever 'positive', 'actual', or 'type' changes
  useEffect(() => {
    const isPositive = formData.positive === true;
    const isActual = formData.actual === true;
    const type = iroType; 
 
    setTabConditions({
      // Scale and Scope disabled when type is "Risk" or "Opportunity"
      scaleDisabled: type === "Risk" || type === "Opportunity",
      scopeDisabled: type === "Risk" || type === "Opportunity",
 
      // Remediability disabled for "I positive actual", "I positive potential", and "Risk" or "Opportunity" types
      remediabilityDisabled:
      (isPositive && isActual) || // "I positive actual"
      (isPositive && !isActual) || // "I positive potential"
      type === "Risk" ||
      type === "Opportunity",
 
      // Magnitude should be disabled all the time except for types "Risk" and "Opportunity"
      magnitudeDisabled: !(type === "Risk" || type === "Opportunity"),
 
      // Likelihood disabled for "I negative actual" and "I positive actual"
      likelihoodDisabled: (!isPositive && isActual) || (isPositive && isActual),
    });
  }, [formData.positive, formData.actual, iroType]);
   
    // Handle changes for the positive and actual fields in Basic tab
    const handlePositiveActualValue = (field, value) => {
      setFormData((prev) => ({ ...prev, [field]: value }));
    };

    const validateFields = () => {
      let isValid = true;

      if (formData.actual === null) {
        setActualValidation("Actual/Potential is required");
        isValid = false;
      } else {
        setActualValidation("");
      }

      if (formData.positive === null) {
        setIsPositiveError("Positive/Negative is required");
        isValid = false;
      } else {
        setIsPositiveError("");
      }

      return isValid;
    };

    const handleSubmit = () => {
      if (isEditMode) {
        const data = {
          moduleId: moduleId,
          id: filteredUpdateData.assessment.id,
          moduleIROId: moduleIROId,
          isPositive: formData.positive,
          isActual: formData.actual,
          moduleRatingScopeId: formData.ScopeId,
          ratingScopeDescription: formData.ScopeDescription,
          moduleRatingScaleId: formData.ScaleId,
          ratingScaleDescription: formData.ScaleDescription,
          moduleRatingRemediabilityId: formData.RemediabilityId,
          ratingRemediabilityDescription: formData.RemediabilityDescription,
          moduleRatingMagnitudeId: formData.MagnitudeId,
          ratingMagnitudeIdDescription: formData.MagnitudeDescription,
          moduleRatingLikelihoodId: formData.LikelihoodId,
          ratingLikelihoodDescription: formData.LikelihoodDescription,
          valueChainIds: formData.valueChainId,
        };
        handleDoneClick(data);
        onClose();
      } else {
        if (validateFields()) {
          const data = {
            moduleId: moduleId,
            moduleIROId: moduleIROId,
            isPositive: formData.positive,
            isActual: formData.actual,
            moduleRatingScopeId: formData.ScopeId || null,
            ratingScopeDescription: formData.ScopeDescription || "",
            moduleRatingScaleId: formData.ScaleId || null,
            ratingScaleDescription: formData.ScaleDescription || "",
            moduleRatingRemediabilityId: formData.RemediabilityId || null,
            ratingRemediabilityDescription:
              formData.RemediabilityDescription || "",
            moduleRatingMagnitudeId: formData.MagnitudeId || null,
            ratingMagnitudeIdDescription: formData.MagnitudeDescription || "",
            moduleRatingLikelihoodId: formData.LikelihoodId || null,
            ratingLikelihoodDescription: formData.LikelihoodDescription || "",
            valueChainIds: formData.valueChainId,
          };
          handleDoneClick(data);
          onClose();
        }
      }
    };

    const handleChange = (field, value) => {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));
    };

    const handlePositiveActual = (positiveActual, e) => {
      setFormData((prev) => ({ ...prev, [positiveActual]: e }));
    };

    const handleValueChainDropDown = (valueChainId, e) => {
      setFormData((prev) => ({ ...prev, [valueChainId]: e }));
    };
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [deleteItemID, setDeleteItemID] = useState(null);

    const handleDeleteClick = () => {
      setIsDeleteModalOpen(true);
      setDeleteItemID(filteredUpdateData.assessment.id);
    };

    const handleDeleteCloseModal = () => {
      setIsDeleteModalOpen(false);
      onClose();
    };

    const getDefinitionByTypeAndId = (type, givenId) => {
      // Find the object that matches the given ID
      const result = infoRating.find((item) => item.id === givenId);

      if (result) {
        const positiveKey = `${type}DefinitionPositive`;
        const negativeKey = `${type}DefinitionNegative`;

        return {
          [positiveKey]: result[positiveKey] || "Not available",
          [negativeKey]: result[negativeKey] || "Not available",
        };
      } else {
        return "No matching ID found";
      }
    };

    const handleMouseEnter = (tab, id) => {
      const definitions = getDefinitionByTypeAndId(tab, id);

      setInfo(
        (definitions[`${tab}DefinitionNegative`] === "N/A"
          ? ""
          : definitions[`${tab}DefinitionNegative`]) +
          "  " +
          (definitions[`${tab}DefinitionPositive`] === "N/A"
            ? ""
            : definitions[`${tab}DefinitionPositive`])
      );
      setShowInfo(true);
    };

    const handleMouseLeave = () => {
      setShowInfo(false);
    };
    
    

    const renderTabContent = () => {
      switch (selectedTab) {
        case "Scale":
        case "Scope":
        case "Remediability":
        case "Magnitude":
        case "Likelihood":
          return (
            <>
              <div className="iro-form-row">
                <MotifLabel className="iro-label">{ratingLabel}</MotifLabel>
                <MotifSelect
                  value={formData[`${selectedTab}Id`]}
                  className="iro-textField"
                  onChange={(e) => handleChange(`${selectedTab}Id`, e)}
                >
                  {(selectedTab === "Likelihood"
                    ? likelihood
                    : ratingDropDownArray
                  ).map((item) => (
                    <MotifOption key={item.id} value={item.id}>
                      {`${item.val} - ${item.label} `}
                    </MotifOption>
                  ))}
                </MotifSelect>

                <div
                  className="info-icon"
                  onMouseEnter={() =>
                    handleMouseEnter(
                      selectedTab.toLocaleLowerCase(),
                      formData[`${selectedTab}Id`]
                    )
                  }
                  onMouseLeave={handleMouseLeave}
                >
                  <InfoIcon />
                </div>

                {showInfo && (
                  <div
                    className="info-description"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <p style={{ margin: 0 }}>{info}</p>
                  </div>
                )}
              </div>

              <div className="iro-form-row">
                <MotifLabel className="iro-label">
                  {descriptionLabel}
                </MotifLabel>
                <TextField
                  placeholder=""
                  multiline
                  fullWidth
                  rows={4}
                  value={formData[`${selectedTab}Description`]}
                  onChange={(e) =>
                    handleChange(`${selectedTab}Description`, e.target.value)
                  }
                />
              </div>
            </>
          );
        case "Basic":
          return (
            <>
              <div className="iro-form-row">
                <MotifLabel className="iro-label">{iroTypeLabel}</MotifLabel>
                <MotifFormField className="iro-textField">
                  <MotifInput value={iroType} disabled />
                </MotifFormField>
              </div>

              <div className="iro-form-row">
                <MotifLabel className="iro-label">{valueChainLabel}</MotifLabel>

                <MotifSelect
                  multiple
                  value={formData.valueChainId}
                  className="iro-textField"
                  onChange={(e) => handleValueChainDropDown("valueChainId", e)}
                >
                  {valueChainDropDown.map((item) => (
                    <MotifOption key={item.id} value={item.id}>
                      {item.referenceList}
                    </MotifOption>
                  ))}
                </MotifSelect>
              </div>
              <div className="iro-form-row">
                <MotifLabel className="iro-label">
                  {positiveNegativeLabel}
                </MotifLabel>
                <MotifSelect
                  value={
                    isEditMode ? (formData.positive ? "true" : "false") : ""
                  }
                  className="iro-textField"
                  onChange={(e) =>
                    handlePositiveActual("positive", e === "true")
                  }
                >
                  <MotifOption value="true">Positive</MotifOption>
                  <MotifOption value="false">Negative</MotifOption>
                </MotifSelect>
              </div>

              {isPositiveError && (
                <MotifErrorMessage className="error-message-iro-modal">
                  {isPositiveError}
                </MotifErrorMessage>
              )}

              <div className="iro-form-row">
                <MotifLabel className="iro-label">
                  {actualPotentialLabel}
                </MotifLabel>

                <MotifSelect
                  value={isEditMode ? (formData.actual ? "true" : "false") : ""}
                  className="iro-textField"
                  onChange={(e) => handlePositiveActual("actual", e === "true")}
                >
                  <MotifOption value="true">Actual</MotifOption>
                  <MotifOption value="false">Potential</MotifOption>
                </MotifSelect>
              </div>
              <div className="error-message-iro-modal">
                {actualValidation && (
                  <MotifErrorMessage>{actualValidation}</MotifErrorMessage>
                )}
              </div>
            </>
          );
        default:
          return null;
      }
    };

    return (
      <div className="iro-modal-overlay">
        <div className="iro-modal modal">
          <div className="iro-modal-header">
            <h2>{modalTitle}</h2>
          </div>
          <div className="iro-modal-body">
            <div className="iro-modal-sidebar">
              <ul>
                <li>
                  <button
                    type="button"
                    className={`sidebar-item ${
                      selectedTab === "Basic" ? "active" : ""
                    }`}
                    onClick={() => setSelectedTab("Basic")}
                    disabled={tabConditions.scaleDisabled}
                  >
                    {t("Basic")}
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={`sidebar-item ${
                      selectedTab === "Scale" ? "active" : ""
                    }`}
                    onClick={() => setSelectedTab("Scale")}
                    disabled={tabConditions.scaleDisabled}
                  >
                    {t("Scale")}
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={`sidebar-item ${
                      selectedTab === "Scope" ? "active" : ""
                    }`}
                    onClick={() => setSelectedTab("Scope")}
                    disabled={tabConditions.scopeDisabled}
                  >
                    {t("Scope")}
                  </button>
                </li>

                <li>
                  <button
                    type="button"
                    className={`sidebar-item ${
                      selectedTab === "Remediability" ? "active" : ""
                    }`}
                    onClick={() => setSelectedTab("Remediability")}
                    disabled={tabConditions.remediabilityDisabled}
                  >
                    {t("Remediability")}
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={`sidebar-item ${
                      selectedTab === "Magnitude" ? "active" : ""
                    }`}
                    onClick={() => setSelectedTab("Magnitude")}
                    disabled={tabConditions.magnitudeDisabled}
                  >
                    {t("Magnitude")}
                  </button>
                </li>

                <li>
                  <button
                    type="button"
                    className={`sidebar-item ${
                      selectedTab === "Likelihood" ? "active" : ""
                    }`}
                    onClick={() => setSelectedTab("Likelihood")}
                    disabled={tabConditions.likelihoodDisabled}
                  >
                    {t("Likelihood")}
                  </button>
                </li>
              </ul>
            </div>
            <div className="modal-content">
              {renderTabContent()}
              <div className="iro-modal-footer">
                {isEditMode && (
                  <MotifButton
                    onClick={handleDeleteClick}
                    className="button delete-button"
                    style={{ marginRight: "10px" }}
                  >
                    {deleteButtonLabel} &nbsp;
                    <Cross />
                  </MotifButton>
                )}
                <MotifButton onClick={onClose} className="button cancel-button">
                  {cancelButtonLabel}
                </MotifButton>
                <MotifButton
                  onClick={handleSubmit}
                  className="button done-button"
                >
                  {isEditMode ? updateButtonLabel : saveButtonLabel}
                </MotifButton>
              </div>
            </div>
          </div>
        </div>

        {isDeleteModalOpen && (
          <DeleteModal
            isOpen={isDeleteModalOpen}
            onClose={handleDeleteCloseModal}
            setName={t("DeleteLabel")}
            labelText={t("DeletConfirmationSources")}
            onCancel={handleDeleteCloseModal}
            handleDeleteClick={handleDelete}
            cancelButtonLabel={t("Cancel")}
            deleteButtonLabel={t("DeleteLabel")}
          />
        )}
      </div>
    );
  }
);

IroAssessmentModal.propTypes = {
  iroType: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  modalMode: PropTypes.string,
  modalTitle: PropTypes.string,
  ratingDropDownArray: PropTypes.array,
  likelihood: PropTypes.array,
  saveButtonLabel: PropTypes.string,
  updateButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  valueChainDropDown: PropTypes.array,
  handleDoneClick: PropTypes.func.isRequired,
  deleteButtonLabel: PropTypes.string,
  ratingLabel: PropTypes.string,
  descriptionLabel: PropTypes.string,
  iroTypeLabel: PropTypes.string,
  valueChainLabel: PropTypes.string,
  positiveNegativeLabel: PropTypes.string,
  actualPotentialLabel: PropTypes.string,
  filteredUpdateData: PropTypes.array,
  moduleId: PropTypes.string,
  moduleIROId: PropTypes.string,
  handleDelete: PropTypes.func,
  infoRating: PropTypes.string,
  closeModal: PropTypes.bool,
};

export default IroAssessmentModal;

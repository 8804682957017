import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import {
  getDGASustainabilityMatters,
  createDGASustainabilityMatters,
} from "../../../features/slices/DGAContext";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import TreeView from "../../../components/treeView/TreeView";
import ContextSelection from "../../../components/contextSelection/ContextSelection";
import { useTranslation } from "react-i18next";
import CommentBox from "../../../components/commentBox/CommentBox";
import "./AssessmentDga.scss";

const AssessmentDga = () => {
  const [isLoading, setIsLoading] = useState(true);
  //const [modularityId, setModularityId] = useState();
  const [selectedItemId, setSelectedItemId] = useState(null); // New state variable
  const { moduleId: moduleIdParam } = useParams();
  const moduleId = parseInt(moduleIdParam, 10);
  const dispatch = useDispatch();
  const location = useLocation();
  const token = location.state?.token;
  const { t } = useTranslation();
  const [smData, setSmData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [comment, setComment] = useState("");
  const [isToggled, setIsToggled] = useState(false);
  const [label, setLabel] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [smResponse] = await Promise.all([
          dispatch(getDGASustainabilityMatters({ moduleId, token })),
        ]);

        const data = smResponse.payload.data || [];
        setSmData(data);

        if (data.length > 0) {
          const firstItem = data[0];
          setSelectedItemId(firstItem.id);
          setComment(firstItem.comment || "");
          setIsToggled(firstItem.materiality || false);
          setLabel(firstItem.label || firstItem.name || "");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch, moduleId, token]);

  const findItemById = useCallback((data, id) => {
    for (let item of data) {
      if (item.id === id) return item;
      if (item.children) {
        const result = findItemById(item.children, id);
        if (result) return result;
      }
    }
    return null;
  }, []);

  // const handleSelect = useCallback((itemId) => {
  //   setModularityId(itemId);
  // }, []);

  const handleEditClick = (id) => {
    const selectedItem = findItemById(smData, id);
    if (selectedItem) {
      setSelectedItemId(id); 
      setComment(selectedItem.comment || "");
      setIsToggled(selectedItem.materiality || false);
      setLabel(selectedItem.label || selectedItem.name || "");
    }
  };

  const transformedItems = useMemo(
    () =>
      smData.map((item) => ({
        id: item.id,
        itemId: item.id,
        label: item.label || item.name,
        children: item.children || [],
      })) || [],
    [smData]
  );

  const handleSave = useCallback(
    async (comment, isToggled) => {
      setIsLoading(true);
      try {
        const newData = {
          moduleSustainabilityMatterId: selectedItemId, 
          comment,
          isToggled,
          moduleId,
        };
        await dispatch(
          createDGASustainabilityMatters({
            sustainabilityMattersData: newData,
            token,
          })
        ).unwrap();
        await dispatch(getDGASustainabilityMatters({ moduleId, token }));
      } catch (error) {
        console.error("Creation failed");
      }
      setIsLoading(false);
    },
    [dispatch, selectedItemId, moduleId, token]
  );

  const handleSearchInputChange = useCallback((event) => {
    setSearchInput(event);
  }, []);

  const handleCancel = useCallback(() => {
    console.log("Cancelled");
  }, []);

  return (
    <>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      {!isLoading && (
        <div style={{ padding: "2% 9% 1% 6% " }}>
          <ContextSelection
            pageName="DgaContext"
            downloadButtonText="Download longlist"
            onChangeSearchInput={handleSearchInputChange}
          />
        </div>
      )}
      <div className="motif-row dga-assessment">
        <div className="motif-col-1 motif-col-lg-8">
          <div className="sm-file-explorer">
            <TreeView
              items={transformedItems}
              pageName="DgaContext"
              defaultExpandedItems={[]}
              defaultSelectedItems={selectedItemId ? [selectedItemId] : []}
             // onSelect={handleSelect}
              onEditClick={handleEditClick}
            />
          </div>
        </div>
        <div className="motif-col-1 motif-col-lg-4">
          <div style={{ width: "100%", height: "80vh" }}>
            <CommentBox
              onSave={handleSave}
              onCancel={handleCancel}
              label={label}
              cancelButtonLabel={t("Cancel")}
              saveButtonLabel={t("Save")}
              isMaterial={t("isMaterial")}
              comment={comment}
              setComment={setComment}
              isToggled={isToggled}
              setIsToggled={setIsToggled}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentDga;
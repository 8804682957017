import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { MotifFormField, MotifErrorMessage } from "@ey-xd/motif-react";

const MultiPeoplePicker = ({
  getADUsers,
  onChange,
  options,
  disabledPicker,
  value,
  isErrorVisible,
  errorMessage,
  isErrorVisible2,
  errorMessage2,
  pageName,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (value) {
      setSelectedOptions(value);
    }
  }, [value]);

  const loadOptions = async (inputValue, callback) => {
    if (inputValue) {
      try {
        const data = await getADUsers(inputValue);
        if (Array.isArray(data)) {
          const formattedOptions = data.map((item) => ({
            id: item.id,
            label: item.emailId,
            value: item.displayName,
          }));
          callback(formattedOptions);
        } else {
          console.error("Expected an array but received:", data);
          callback([]);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        callback([]);
      }
    } else {
      callback([]);
    }
  };

  const handleOnChange = (selectedList) => {
    setSelectedOptions(selectedList);
    onChange(selectedList);
  };

  return (
    <div>
      <div className="MultiselectDiv">
        <MotifFormField disabled={disabledPicker}>
          <div>
            <AsyncSelect
              cacheOptions
              loadOptions={loadOptions}
              isDisabled={disabledPicker}
              placeholder={
                pageName === "New Project"
                  ? "Select project administrator"
                  : "Select Email"
              }
              value={selectedOptions}
              onChange={handleOnChange}
              isMulti={pageName === "New Project"}
              isSearchable
              closeMenuOnSelect={false}
              options={options}
              styles={{
                control: (provided) => ({
                  ...provided,
                  background: "#FFFFFF",
                  border: "1px solid #C3C3CB",
                  borderRadius: "0px",
                  color: "#2e2e38",
                  font: "Y",
                }),
                menu: (provided) => ({
                  ...provided,
                  color: "black",
                }),
                multiValue: (provided) => ({
                  ...provided,
                  background: "#C3C3CB",
                  color: "#656579",
                }),
                input: (provided) => ({
                  ...provided,
                  color: "#656579",
                  Font: "EYInterstate",
                  fontWeight: 300,
                  fontSize: "16px",
                  lineHeight: "24px",
                }),
              }}
            />
          </div>
          {isErrorVisible && (
            <MotifErrorMessage show={isErrorVisible}>
              {errorMessage}
            </MotifErrorMessage>
          )}
          {isErrorVisible2 && (
            <p
              show={isErrorVisible2}
              style={{
                fontSize: "0.857rem",
                color: "red",
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
                marginTop: "0px",
                marginBottom: "0px",
                paddingLeft: "5%",
              }}
            >
              {errorMessage2}
            </p>
          )}
        </MotifFormField>
      </div>
    </div>
  );
};

MultiPeoplePicker.propTypes = {
  getADUsers: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.array,
  isErrorVisible: PropTypes.bool,
  errorMessage: PropTypes.string,
  isErrorVisible2: PropTypes.bool,
  errorMessage2: PropTypes.string,
  pageName: PropTypes.string.isRequired,
};

export default MultiPeoplePicker;
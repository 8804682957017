import React from "react";
import { MotifButton } from "@ey-xd/motif-react";
import {
  LeftArrowForPrevious,
  NavLeft,
  RightArrowForNext,
} from "../../constants/icons/Icons";
import "./IROAssessmentSubheader.scss";
import PropTypes from "prop-types";

const IROAssessmentSubheader = ({
  header,
  subHeader,
  impact,
  previousButtonLabel,
  nextButtonLabel,
  onBackClick,
  handleNextClick,
  handlePreviousClick
}) => {
  return (
    <div className="iro-assessment-subheader-container">
      <div className="iro-assessment-subheader-section">
        <div className="iro-assessment-subheader-section1">
          <div className="iro-left-arrow" onClick={onBackClick}>
            <NavLeft aria-label="nav-left-icon" />
          </div>

          <div className="iro-assessment-subheader-body">
            <div className="iro-assessment-name-section">
              <h2 className="motif-h6-default-regular"> {header}</h2>
              <p className="iro-impact iro-impact-label">{impact} </p>
            </div>

            <h5 className=" iro-assessment-sub-name motif-body2-default-light">
              {subHeader}
            </h5>
          </div>
        </div>
        <div className="iro-assessment-subheader-section2">
          <MotifButton className="button cancel-button next-previous-btn" onClick={handlePreviousClick}>
            <LeftArrowForPrevious aria-label="left-arrow-icon" /> &nbsp;{" "}
            {previousButtonLabel}
          </MotifButton>
          <MotifButton className="button cancel-button next-previous-btn" onClick={handleNextClick}>
            {nextButtonLabel} &nbsp;{" "}
            <RightArrowForNext aria-label="right-arrow-icon" />
          </MotifButton>
        </div>
      </div>
    </div>
  );
};

IROAssessmentSubheader.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  impact: PropTypes.string,
  previousButtonLabel: PropTypes.string,
  nextButtonLabel: PropTypes.string,
  onBackClick: PropTypes.func,
  handlePreviousClick: PropTypes.func,
  handleNextClick: PropTypes.func,
};

export default IROAssessmentSubheader;
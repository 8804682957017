import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_LIKELIHOOD__URL,
  CREATE_LIKELIHOOD__URL,
  UPDATE_LIKELIHOOD__URL,
  DELETE_LIKELIHOOD__URL,
} from "../../services/Api";

// Fetch all Likelihood data
export const fetchLikelihood = createAsyncThunk(
  "likelihood/fetchLikelihood",
  async ({ moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_ALL_LIKELIHOOD__URL}/${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching Likelihood data:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching Likelihood data"
      );
    }
  }
);

// Create a new Likelihood
export const createLikelihood = createAsyncThunk(
  "likelihood/createLikelihood",
  async ({ moduleId, likelihoodData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${CREATE_LIKELIHOOD__URL}/${moduleId}`,
        likelihoodData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating Likelihood:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error creating Likelihood"
      );
    }
  }
);

// Update Likelihood
export const updateLikelihood = createAsyncThunk(
  "likelihood/updateLikelihood",
  async ({ likelihoodId, moduleId, likelihoodData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${UPDATE_LIKELIHOOD__URL}/${likelihoodId}?moduleId=${moduleId}`,
        likelihoodData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating Likelihood:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error updating Likelihood"
      );
    }
  }
);

// Delete Likelihood
export const deleteLikelihood = createAsyncThunk(
  "likelihood/deleteLikelihood",
  async ({ likelihoodId, token, moduleId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${DELETE_LIKELIHOOD__URL}/${likelihoodId}/${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting Likelihood:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error deleting Likelihood"
      );
    }
  }
);

const likelihoodSlice = createSlice({
  name: "likelihood",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLikelihood.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchLikelihood.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchLikelihood.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createLikelihood.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(createLikelihood.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(createLikelihood.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateLikelihood.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateLikelihood.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateLikelihood.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteLikelihood.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(deleteLikelihood.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(deleteLikelihood.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      });
  },
});

export default likelihoodSlice.reducer;

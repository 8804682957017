import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ExcelJS from "exceljs";
import {
  createNewSustainabilityMatters,
  deleteSustainabilityMatters,
  fetchEsrsAr16,
  getAllSustainabilityMatters,
  updateSustainabilityMatters,
} from "../../../../features/slices/SustainabilityMatters";
import { fetchExcelData } from "../../../../features/slices/LongListData";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import ContextSelection from "../../../../components/contextSelection/ContextSelection";
import TreeView from "../../../../components/treeView/TreeView";
import SustainabilityMattersModal from "../../../../components/modals/dmaModals/SustainabilityMattersModal";
import DeleteModal from "../../../../components/modals/deleteModal/DeleteModal";
import ErrorModal from "../../../../components/modals/error/errorModal";
import "./SustainabillityMatters.scss";
import { useTranslation } from "react-i18next";
const SustainabilityMatters = () => {
  const [modal, setModal] = useState({ isOpen: false, title: "", mode: "add" });
  const [options, setOptions] = useState({
    parent: [],
    esrs: [],
    esrsar16: [],
  });
  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [filteredUpdateData, setFilteredUpdateData] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updateItemID, setUpdateItemID] = useState(null);
  const [deleteItemID, setDeleteItemID] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { moduleId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = location.state?.token;
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");
  const [smData, setSmData] = useState([]);

  const getEligibleParentOptions = useCallback(
    (items, parentId = null, currentLevel = 0) => {
      if (currentLevel >= 2) return []; // Stop recursion if level exceeds 2

      return items
        .filter((item) => item.parentId === parentId)
        .flatMap((item) => [
          {
            value: item.id,
            label: item.label || item.name,
          },
          ...getEligibleParentOptions(
            item.children || [],
            item.id,
            currentLevel + 1
          ), // Recursively get children if present
        ]);
    },
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [smResponse, esrsar16Response] = await Promise.all([
          dispatch(getAllSustainabilityMatters({ moduleId, token })),
          dispatch(fetchEsrsAr16({ token })),
        ]);
        const sustainabilityMatters = smResponse.payload.data || [];
        setSmData(sustainabilityMatters);
        setOptions({
          esrsar16:
            esrsar16Response.payload.data.map((type) => ({
              value: type.id,
              label: type.name,
            })) || [],
          parent: getEligibleParentOptions(sustainabilityMatters),
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, moduleId, token]);

  const sustainabilityMattersState = useSelector(
    (state) => state.dmaSustainabilityMatters
  );
  const { isError, errorMessage } = sustainabilityMattersState;

  const handleOpenModal = (mode, itemId) => {
    setModal({
      isOpen: true,
      mode,
      title:
        mode === "add"
          ? t("newSustainabilityMatters")
          : t("editSustainabilityMatters"),
    });
  };

  const handleCloseModal = () => {
    setModal((prev) => ({ ...prev, isOpen: false }));
    setUpdateItemID(null);
  };

  const findItemById = (data, id) => {
    for (let item of data) {
      if (item.id === id) return item;
      if (item.children) {
        const result = findItemById(item.children, id);
        if (result) return result;
      }
    }
    return null;
  };

  const handleEditClick = (id) => {
    handleOpenModal("edit", id);
    setFilteredUpdateData(findItemById(smData, id));
    setUpdateItemID(id);
    setDeleteItemID(id);
  };

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      await dispatch(
        deleteSustainabilityMatters({
          sustainabilityMattersId: deleteItemID,
          moduleId,
          token,
        })
      ).unwrap();
      handleCloseModal();
      handleDeleteCloseModal();
      setUpdateTrigger((prev) => prev + 1);
    } catch (error) {
      console.error("Deletion failed:", error);
    }
  };

  function findParentById(data, parentId) {
    for (let item of data) {
      if (item.id === parentId) {
        return item;
      }
      if (item.children && item.children.length > 0) {
        const found = findParentById(item.children, parentId);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  const createNewSustainabilityMattersApi = async (data) => {
    if (data) {
      try {
        let newSortOrder;
        if (data.parentId === null) {
          const maxSortOrder = smData.reduce(
            (max, item) => (item.sortOrder > max ? item.sortOrder : max),
            0
          );
          newSortOrder = maxSortOrder + 1;
        } else {
          const parentItem = findParentById(smData, data.parentId);

          newSortOrder = parentItem ? parentItem.sortOrder : 0;
        }
        const newData = {
          ...data,
          sortOrder: newSortOrder,
        };

        await dispatch(
          createNewSustainabilityMatters({
            moduleId,
            sustainabilityMattersData: newData,
            token,
          })
        ).unwrap();

        setUpdateTrigger((prev) => prev + 1);
        handleCloseModal();
      } catch (error) {
        console.error("Creation failed:", error);
      }
    } else {
      alert("Please fill in all required fields");
    }
  };

  const updateSustainabilityMattersApi = async (data) => {
    if (data) {
      setIsLoading(true);
      try {
        const existingItem = findItemById(smData, updateItemID);
        const updatedData = {
          ...data,
          sortOrder: existingItem.sortOrder,
        };

        const response = await dispatch(
          updateSustainabilityMatters({
            sustainabilityMattersId: updateItemID,
            sustainabilityMattersData: updatedData,
            moduleId,
            token,
          })
        ).unwrap();

        if (response.success) {
          handleCloseModal();
          setUpdateTrigger((prev) => prev + 1);
        }
      } catch (error) {
        console.error("Update failed:", error);
      }
      setIsLoading(false);
    } else {
      alert("Please fill in all required fields");
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event);
  };

  const filterItems = (items, query) => {
    if (!query) return items;
    return items
      .map((item) => {
        if (
          item?.label?.toLowerCase().includes(query.toLowerCase()) ||
          item?.description?.toLowerCase().includes(query.toLowerCase())
        )
          return item;
        if (item.children) {
          const filteredChildren = filterItems(item.children, query);
          if (filteredChildren.length > 0)
            return { ...item, children: filteredChildren };
        }
        return null;
      })
      .filter(Boolean);
  };

  const addSheet = (workbook, sheetName, data) => {
    const uniqueSourceTypes = Array.from(
      new Set(
        data.flatMap((item) => item.sources.map((source) => source.sourceType))
      )
    );
    const uniqueSourceNames = Array.from(
      new Set(data.flatMap((item) => item.sources.map((source) => source.name)))
    );

    const uniqueValueChainTypes = Array.from(
      new Set(
        data.flatMap((item) => item.valueChain.map((vc) => vc.valueChainType))
      )
    );
    const uniqueValueChainNames = Array.from(
      new Set(data.flatMap((item) => item.valueChain.map((vc) => vc.name)))
    );

    const uniqueStakeholderNames = Array.from(
      new Set(data.flatMap((item) => item.stakeholders.map((st) => st.name)))
    );
    const uniqueGeographyNames = Array.from(
      new Set(data.flatMap((item) => item.geographies.map((geo) => geo.name)))
    );
    const uniqueActProServiceNames = Array.from(
      new Set(
        data.flatMap((item) => item.actProServices.map((aps) => aps.name))
      )
    );

    // Top-level headers without "Section", "ESRS", "Topic", "SubTopic", and "SubSubTopic"
    const headers = [
      ...Array(uniqueSourceTypes.length * uniqueSourceNames.length).fill(
        "Sources"
      ),
      ...Array(
        uniqueValueChainTypes.length * uniqueValueChainNames.length
      ).fill("Value Chain"),
      ...Array(uniqueStakeholderNames.length).fill("Stakeholders"),
      ...Array(uniqueGeographyNames.length).fill("Geographies"),
      ...Array(uniqueActProServiceNames.length).fill("ActProServices"),
    ];

    // Second row for source and value chain types without extra fields
    const typeNamesRow = [
      ...uniqueSourceTypes.flatMap((type) =>
        Array(uniqueSourceNames.length).fill(type)
      ),
      ...uniqueValueChainTypes.flatMap((type) =>
        Array(uniqueValueChainNames.length).fill(type)
      ),
      ...uniqueStakeholderNames.map(() => ""), // Leave blank for other sections
      ...uniqueGeographyNames.map(() => ""),
      ...uniqueActProServiceNames.map(() => ""),
    ];

    // Third row with "Section", "ESRS", "Topic", "SubTopic", "SubSubTopic" and individual names
    const subHeaders = [
      "Section",
      "ESRS",
      "Topic",
      "SubTopic",
      "SubSubTopic",
      ...uniqueSourceTypes.flatMap((type) => uniqueSourceNames),
      ...uniqueValueChainTypes.flatMap((type) => uniqueValueChainNames),
      ...uniqueStakeholderNames,
      ...uniqueGeographyNames,
      ...uniqueActProServiceNames,
    ];

    const worksheetData = [headers, typeNamesRow, subHeaders];

    // Populate rows based on item data
    data.forEach((item) => {
      const row = [
        item.section,
        item.esrs,
        item.topic,
        item.subTopic,
        item.subSubTopic,
      ];

      // For each source, mark "X" if active
      uniqueSourceTypes.forEach((type) => {
        uniqueSourceNames.forEach((name) => {
          const isActive = item.sources.some(
            (src) =>
              src.sourceType === type && src.name === name && src.isActive
          );
          row.push(isActive ? "X" : "");
        });
      });

      uniqueValueChainTypes.forEach((type) => {
        uniqueValueChainNames.forEach((name) => {
          const isActive = item.valueChain.some(
            (vc) =>
              vc.valueChainType === type && vc.name === name && vc.isActive
          );
          row.push(isActive ? "X" : "");
        });
      });

      uniqueStakeholderNames.forEach((name) => {
        const isActive = item.stakeholders.some(
          (st) => st.name === name && st.isActive
        );
        row.push(isActive ? "X" : "");
      });

      uniqueGeographyNames.forEach((name) => {
        const isActive = item.geographies.some(
          (geo) => geo.name === name && geo.isActive
        );
        row.push(isActive ? "X" : "");
      });

      uniqueActProServiceNames.forEach((name) => {
        const isActive = item.actProServices.some(
          (aps) => aps.name === name && aps.isActive
        );
        row.push(isActive ? "X" : "");
      });

      worksheetData.push(row);
    });

    // Create worksheet and add it to the workbook
    const worksheet = workbook.addWorksheet(sheetName);
    worksheet.addRows(worksheetData);

    // Merge cells for top-level headers
    const sourcesStartCol = 1;
    const sourcesEndCol =
      sourcesStartCol + uniqueSourceTypes.length * uniqueSourceNames.length - 1;
    const valueChainStartCol = sourcesEndCol + 1;
    const valueChainEndCol =
      valueChainStartCol +
      uniqueValueChainTypes.length * uniqueValueChainNames.length -
      1;
    const stakeholdersStartCol = valueChainEndCol + 1;
    const stakeholdersEndCol =
      stakeholdersStartCol + uniqueStakeholderNames.length - 1;
    const geographiesStartCol = stakeholdersEndCol + 1;
    const geographiesEndCol =
      geographiesStartCol + uniqueGeographyNames.length - 1;
    const actProServicesStartCol = geographiesEndCol + 1;
    const actProServicesEndCol =
      actProServicesStartCol + uniqueActProServiceNames.length - 1;

    worksheet.mergeCells(1, sourcesStartCol, 1, sourcesEndCol); // Merge "Sources" header
    worksheet.mergeCells(1, valueChainStartCol, 1, valueChainEndCol); // Merge "Value Chain" header
    worksheet.mergeCells(1, stakeholdersStartCol, 1, stakeholdersEndCol); // Merge "Stakeholders" header
    worksheet.mergeCells(1, geographiesStartCol, 1, geographiesEndCol); // Merge "Geographies" header
    worksheet.mergeCells(1, actProServicesStartCol, 1, actProServicesEndCol); // Merge "ActProServices" header

    // Merge cells for source and value chain types row (type names only appear once for their section)
    let currentCol = sourcesStartCol;
    uniqueSourceTypes.forEach((type) => {
      worksheet.mergeCells(
        2,
        currentCol,
        2,
        currentCol + uniqueSourceNames.length - 1
      );
      currentCol += uniqueSourceNames.length;
    });

    currentCol = valueChainStartCol;
    uniqueValueChainTypes.forEach((type) => {
      worksheet.mergeCells(
        2,
        currentCol,
        2,
        currentCol + uniqueValueChainNames.length - 1
      );
      currentCol += uniqueValueChainNames.length;
    });

    // Set column widths for better visibility
    worksheet.columns = Array(headers.length).fill({ width: 15 });

    // Define border style
    const rightBorderStyle = {
      style: "thin",
      color: { argb: "000000" }, // Black border
    };

    // Apply right border to last columns of each section
    worksheet
      .getColumn(sourcesEndCol)
      .eachCell((cell) => (cell.border = { right: rightBorderStyle }));
    worksheet
      .getColumn(valueChainEndCol)
      .eachCell((cell) => (cell.border = { right: rightBorderStyle }));
    worksheet
      .getColumn(stakeholdersEndCol)
      .eachCell((cell) => (cell.border = { right: rightBorderStyle }));
    worksheet
      .getColumn(geographiesEndCol)
      .eachCell((cell) => (cell.border = { right: rightBorderStyle }));
    worksheet
      .getColumn(actProServicesEndCol)
      .eachCell((cell) => (cell.border = { right: rightBorderStyle }));

    // Apply styles for headers (optional)
    [worksheet.getRow(1), worksheet.getRow(2), worksheet.getRow(3)].forEach(
      (row) => {
        row.eachCell((cell) => {
          cell.font = { bold: true };
          cell.alignment = { vertical: "middle", horizontal: "center" };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFE0B2" }, // light orange color for headers
          };
        });
      }
    );
  };

  const formatExcelData = (data) => {
    const workbook = new ExcelJS.Workbook();
    addSheet(workbook, "Sustainability Matters", data);
    return workbook;
  };

  // Function to trigger Excel download
  const downloadExcel = async (data) => {
    const workbook = formatExcelData(data);
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "sustainability_matters.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const handleDownloadExcel = async () => {
    setIsExcelLoading(true);
    try {
      const excelResponse = await dispatch(
        fetchExcelData({ moduleId, token })
      ).unwrap();
      const exceResponseData = excelResponse.data || [];
      await downloadExcel(exceResponseData);
    } catch (error) {
      console.error("Error fetching Excel data:", error);
    } finally {
      setIsExcelLoading(false);
    }
  };
  const transformedItems =
    smData.map((item) => ({
      id: item.id,
      itemId: item.id,
      label: item.label || item.name,
      children: item.children || [],
    })) || [];

  const filteredItems = filterItems(transformedItems, searchInput);

  const handleError = () => {
    navigate("/");
  };

  if (isError) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={errorMessage}
          handleButtonClick={handleError}
          deleteButtonLabel={t("Reload")}
        />
      </div>
    );
  }

  return (
    <>
      {isLoading || isExcelLoading ? (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      ) : (
        <>
          <div>
            <ContextSelection
              addButtonText="Add new"
              pageName="SM"
              downloadButtonText="Download longlist"
              onClickNewProject={() => handleOpenModal("add")}
              onClickNewDownload={handleDownloadExcel}
              onChangeSearchInput={handleSearchInputChange}
            />
          </div>
          <div className="sm-file-explorer">
            <TreeView
              items={filteredItems}
              pageName="SM"
              defaultExpandedItems={[]}
              defaultSelectedItems="1"
              onEditClick={handleEditClick}
            />
          </div>
        </>
      )}
      {modal.isOpen && (
        <SustainabilityMattersModal
          onClose={handleCloseModal}
          modalTitle={modal.title}
          modalMode={modal.mode}
          topicNameLabel={t("TopicName")}
          parentLabel={t("Parent")}
          esrsAr16Label={t("ESRS_AR16")}
          esgLabel={t("ESG")}
          enterTopicNameLabel={t("EnterTopicName")}
          selectParentLabel={t("SelectParent")}
          isHumanRightsRelatedLabel={t("isHumanRightsRelated")}
          selectEsrsAr16Label={t("SelectESRS")}
          DescriptionLabel={t("Description")}
          saveButtonLabel={t("addSustainabilityMatters")}
          updateButtonLabel={t("Update")}
          cancelButtonLabel={t("Cancel")}
          esrsar16Options={options.esrsar16}
          parentOptions={options.parent}
          handleDoneClick={
            modal.mode === "add"
              ? createNewSustainabilityMattersApi
              : updateSustainabilityMattersApi
          }
          filteredUpdateData={filteredUpdateData}
          onDeleteClick={handleDeleteClick}
          DeleteLabel={t("DeleteLabel")}
          updateItemID={updateItemID}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={handleDeleteCloseModal}
          setName={t("DeleteLabel")}
          labelText={t("DeletConfirmationSources")}
          onCancel={handleDeleteCloseModal}
          handleDeleteClick={handleDelete}
          cancelButtonLabel={t("Cancel")}
          deleteButtonLabel={t("DeleteLabel")}
        />
      )}
    </>
  );
};

export default SustainabilityMatters;

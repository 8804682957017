import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_SCORES_URL,
  CREATE_SCORES_URL,
  UPDATE_SCORES_URL,
  DELETE_SCORES_URL,
} from "../../services/Api";

export const fetchScores = createAsyncThunk(
  "scores/fetchScores",
  async ({ moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_SCORES_URL}/${moduleId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching Scores data:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching Scores data"
      );
    }
  }
);

export const createScores = createAsyncThunk(
  "createScores",
  async ({ moduleId, scoresData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${CREATE_SCORES_URL}/${moduleId}`,
        scoresData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating Data:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error creating Data"
      );
    }
  }
);
export const updateScores = createAsyncThunk(
  "updateScores",
  async ({ scoresId, scoresData, moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${UPDATE_SCORES_URL}/${scoresId}?moduleId=${moduleId}`,
        scoresData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating Data:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error updating Data"
      );
    }
  }
);

export const deleteScores = createAsyncThunk(
  "deleteScores",
  async ({ scoresId, moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${DELETE_SCORES_URL}/${scoresId}?moduleId=${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting Data:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error deleting Data"
      );
    }
  }
);

const scoresSlice = createSlice({
  name: "scores",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchScores.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchScores.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchScores.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createScores.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(createScores.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(createScores.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateScores.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateScores.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateScores.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteScores.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(deleteScores.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(deleteScores.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      });
  },
});

export default scoresSlice.reducer;

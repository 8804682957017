import React from "react";
import ContextSubHeader from "../../../components/contextSubHeader/ContextSubHeader";
import ContextStep from "../../../components/contextStep/ContextStep";
import Stakeholders from "./stakeHolders/Stakeholders";
import ValueChain from "./valueChain/ValueChain";
import Sources from "./sources/Sources";
import GeographicalLocation from "./geographicalLocation/GeographicalLocation";
import Aps from "./aps/APS";
import SustainabillityMatters from "./sustainabillityMatters/SustainabillityMatters";
import Demo from "./demo/Demo";
import PropTypes from "prop-types";

const DmaContext = ({ activeTab, setActiveTab }) => {
  return (
    <div className="context-container">
      <ContextSubHeader activeTab={activeTab} setActiveTab={setActiveTab}>
        <ContextStep
          key="sources"
          text="Sources"
          isActive={activeTab === "sources"}
        >
          <Sources />
        </ContextStep>
        <ContextStep
          key="value-chain"
          text="Value chain"
          isActive={activeTab === "Value chain"}
        >
          <ValueChain />
        </ContextStep>
        <ContextStep
          key="activities-product-and-services"
          text="Activities, products and services"
          isActive={activeTab === "Activities, products and services"}
        >
          <Aps />
        </ContextStep>
        <ContextStep
          key="geographical-location"
          text="Geographical location"
          isActive={activeTab === "Geographical location"}
        >
          <GeographicalLocation />
        </ContextStep>
        <ContextStep
          key="stakeholders"
          text="Stakeholders"
          isActive={activeTab === "Stakeholders"}
        >
          <Stakeholders />
        </ContextStep>
        <ContextStep
          key="sustainability-matters"
          text="Sustainability matters"
          isActive={activeTab === "Sustainability matters"}
        >
          <SustainabillityMatters />
        </ContextStep>
        <ContextStep key="demo" text="Demo" isActive={activeTab === "demo"}>
          <Demo />
        </ContextStep>
      </ContextSubHeader>
    </div>
  );
};

DmaContext.propTypes = {
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
};
export default DmaContext;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_SOURCES_TYPES_URL,
  GET_ALL_SOURCES_URL,
  CREATE_SOURCES_URL,
  UPDATE_SOURCES_URL,
  DELETE_SOURCES_URL,
} from "../../services/Api";

// Fetch all Sources data
export const fetchSources = createAsyncThunk(
  "dmaSources/fetchSources",
  async ({ moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_ALL_SOURCES_URL}/${moduleId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching Sources data:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching Sources data"
      );
    }
  }
);

// Create a new Source
export const createSources = createAsyncThunk(
  "dmaSources/createSources",
  async ({ moduleId, sourceData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${CREATE_SOURCES_URL}/${moduleId}`,
        sourceData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating Source:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error creating Source"
      );
    }
  }
);

// Update Source
export const updateSources = createAsyncThunk(
  "dmaSources/updateSources",
  async ({ sourceId, moduleId, sourceData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${UPDATE_SOURCES_URL}/${sourceId}?moduleId=${moduleId}`,
        sourceData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating Source:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error updating Source"
      );
    }
  }
);

// Delete Source
export const deleteSources = createAsyncThunk(
  "dmaSources/deleteSources",
  async ({ sourceId, moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${DELETE_SOURCES_URL}/${sourceId}?moduleId=${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting Source:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error deleting Source"
      );
    }
  }
);

// Fetch Sources types
export const fetchSourcesTypes = createAsyncThunk(
  "dmaSources/fetchSourcesTypes",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_ALL_SOURCES_TYPES_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching Sources types:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching Sources types"
      );
    }
  }
);

const dmaSourcesSlice = createSlice({
  name: "dmaSources",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    types: [],
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSources.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchSources.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchSources.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fetchSourcesTypes.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchSourcesTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.types = action.payload;
      })
      .addCase(fetchSourcesTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createSources.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(createSources.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(createSources.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateSources.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateSources.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateSources.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteSources.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(deleteSources.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(deleteSources.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      });
  },
});

export default dmaSourcesSlice.reducer;

import React, { useReducer, useCallback, useMemo } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  TablePagination,
} from "@mui/material";
import { Sort } from "../../constants/icons/Icons";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import natsort from "natsort";
import "./ClientTable.scss";

const ClientTable = ({
  page,
  columns,
  data,
  itemsPerPage,
  renderTableCell,
  generateRowKey,
  showPagination = true,
}) => {
  const initialState = {
    page: 0,
    sortConfig: { key: "createdOn", direction: "desc" },
    rowsPerPage: itemsPerPage,
    searchQuery: "",
  };

  const actionTypes = {
    SET_PAGE: "SET_PAGE",
    SET_SORT_CONFIG: "SET_SORT_CONFIG",
    SET_ROWS_PER_PAGE: "SET_ROWS_PER_PAGE",
    SET_SEARCH_QUERY: "SET_SEARCH_QUERY",
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case actionTypes.SET_PAGE:
        return { ...state, page: action.payload };
      case actionTypes.SET_SORT_CONFIG:
        return { ...state, sortConfig: action.payload };
      case actionTypes.SET_ROWS_PER_PAGE:
        return { ...state, rowsPerPage: action.payload, page: 0 };
      case actionTypes.SET_SEARCH_QUERY:
        return { ...state, searchQuery: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const isNumeric = (value) => !isNaN(value);
  const theme = createTheme({
    typography: {
      fontFamily: "EYInterstate, sans-serif",
    },
  });

  const compareValues = useCallback((key, direction) => {
    const sorter = natsort({ insensitive: true });
    return (a, b) => {
      const aValue = a[key];
      const bValue = b[key];

      if (isNumeric(aValue) && isNumeric(bValue)) {
        return direction === "asc" ? aValue - bValue : bValue - aValue;
      } else if (isNumeric(aValue)) {
        return direction === "asc" ? -1 : 1;
      } else if (isNumeric(bValue)) {
        return direction === "asc" ? 1 : -1;
      } else {
        const result = sorter(aValue, bValue);
        return direction === "asc" ? result : -result;
      }
    };
  }, []);

  const filterAndSortData = useCallback(() => {
    if (!data) return [];

    const dataArray = Object.keys(data).map((projectId) => ({
      id: projectId,
      ...data[projectId],
    }));

    let filteredData = [...dataArray];
    if (state.searchQuery) {
      filteredData = filteredData.filter((item) =>
        item?.projectName
          ?.toLowerCase()
          .includes(state.searchQuery.toLowerCase())
      );
    }

    if (state.sortConfig.key) {
      filteredData.sort(
        compareValues(state.sortConfig.key, state.sortConfig.direction)
      );
    } else {
      filteredData.sort(
        (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
      );
    }

    return filteredData;
  }, [data, state.searchQuery, state.sortConfig, compareValues]);

  const displayedData = useMemo(() => filterAndSortData(), [filterAndSortData]);

  const handleChangePage = useCallback(
    (event, newPage) => {
      dispatch({ type: actionTypes.SET_PAGE, payload: newPage });
    },
    [actionTypes.SET_PAGE]
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      dispatch({
        type: actionTypes.SET_ROWS_PER_PAGE,
        payload: parseInt(event.target.value, 10),
      });
    },
    [actionTypes.SET_ROWS_PER_PAGE]
  );

  const requestSort = useCallback(
    (key) => {
      let direction = "asc";
      if (
        state.sortConfig.key === key &&
        state.sortConfig.direction === "asc"
      ) {
        direction = "desc";
      } else if (
        state.sortConfig.key === key &&
        state.sortConfig.direction === "desc"
      ) {
        direction = "asc";
      }
      dispatch({
        type: actionTypes.SET_SORT_CONFIG,
        payload: { key, direction },
      });
    },
    [state.sortConfig, actionTypes.SET_SORT_CONFIG]
  );

  const paddingLeftTableHead = (page)=>{
     switch (page) {
      case "UpdateUserManagement":
        return "30px";
      default:
        return "30px"
     }
  }

  return (
    <ThemeProvider theme={theme}>
      <Box width="100%">
        <Box border={1} borderColor="grey.300" className="table-container">
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => {
                  const style = {
                    flex: 1,
                    textAlign: index === columns.length - 1 ? "right" : "left",
                    paddingRight: index === columns.length - 1 ? "8.12%" : "0",
                    paddingLeft:paddingLeftTableHead(page),
                    cursor: column.showSorting ? "pointer" : "default",
                    "@media (maxWidth: 768px)": {
                      paddingRight: "4%",
                    },
                  };
                  return (
                    <TableCell
                      key={column.colName}
                      className="table-header-cell"
                      style={style}
                      onClick={() =>
                        column.showSorting && requestSort(column.colName)
                      }
                    >
                      <div>
                        {column.label}
                        {column.showSorting && (
                          <span className="sort-icon">
                            <Sort
                              style={{
                                transform:
                                  state.sortConfig.key === column.colName &&
                                  state.sortConfig.direction === "asc"
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                              }}
                            />
                          </span>
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className="table-content">
              {displayedData
                .slice(
                  state.page * state.rowsPerPage,
                  (state.page + 1) * state.rowsPerPage
                )
                .map((row) => (
                  <TableRow key={generateRowKey(row)}>
                    {columns.map((column, index) =>
                      renderTableCell(
                        column.colName,
                        row[column.colName],
                        row,
                        index
                      )
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {showPagination && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={displayedData.length}
              rowsPerPage={state.rowsPerPage}
              page={state.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

ClientTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  renderTableCell: PropTypes.func.isRequired,
  generateRowKey: PropTypes.func.isRequired,
};

export default ClientTable;

import React, { useEffect, useState, useCallback } from "react";
import { MotifCard, MotifCardHeader, MotifCardBody } from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import Slider from "@mui/material/Slider";
import "./ThresholdCard.scss";

const ThresholdCard = ({
  sliderHeader,
  sliderDescription,
  thresholdToInput,
  updateThresholdValue,
}) => {
  const [threshold, setThreshold] = useState(thresholdToInput);

  useEffect(() => {
    setThreshold(thresholdToInput);
  }, [thresholdToInput]);

  const marks = [
    { value: 0, label: "0" },
    { value: 5, label: "5" },
  ];

  const handleInputChange = (e) => {
    const value = parseFloat(e.target.value);

    if (value > 5.0 || value < 0.0) {
      alert("Value can't be more than 5 or less than 0");
    } else if (e.target.value.split(".")[1]?.length > 1) {
      alert("Only one decimal place is allowed");
    } else {
      setThreshold(value);
      updateThresholdValue(value); 
    }
  };

  const handleSliderChangeCommitted = useCallback((event, value) => {
    if (typeof value === 'number') {
      setThreshold(value);
      updateThresholdValue(value);
    }
  }, [updateThresholdValue]);

  return (
    <MotifCard className="threshold-card">
      <MotifCardHeader style={{ fontFamily: "EYInterstate" }} variant="alt" className="threshold-header">
        {sliderHeader}
      </MotifCardHeader>
      <MotifCardBody>
        <div className="slider">
          <input
            type="number"
            value={threshold}
            onChange={handleInputChange}
            className="threshold-input"
            data-testid="textbox"
            style={{ fontFamily: "EYInterstate" }}
            min={0}
            max={5}
            step={0.1}
          />
          <div className="slider-component">
            <Slider
              color="#E6E6E9"
              value={threshold}
              step={0.1}
              min={0}
              max={5}
              onChange={(e, value) => setThreshold(value)}
              marks={marks}
              onChangeCommitted={handleSliderChangeCommitted}
              data-testid="slider"
            />
          </div>
        </div>
        <p className="description" style={{ fontFamily: "EYInterstate" }}>
          {sliderDescription}
        </p>
      </MotifCardBody>
    </MotifCard>
  );
};

ThresholdCard.propTypes = {
  sliderHeader: PropTypes.string.isRequired,
  sliderDescription: PropTypes.string.isRequired,
  thresholdToInput: PropTypes.number.isRequired,
  updateThresholdValue: PropTypes.func.isRequired,
};

export default ThresholdCard;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_EXCEL_DATA } from "../../services/Api";

// Thunk to fetch all exceelData with the token
export const fetchExcelData = createAsyncThunk(
  "fetchExcelData",
  async ({ moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_EXCEL_DATA}/${moduleId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching users:", error);
      return rejectWithValue(error.response?.data || "Error fetching users");
    }
  }
);

const fetchExcel = createSlice({
  name: "allUsers",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExcelData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchExcelData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchExcelData.rejected, (state, action) => {
      console.error("error", action.payload);
      state.isError = true;
    });
  },
});

export default fetchExcel.reducer;

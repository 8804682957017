import React, { useState } from "react";
import Stepper from "../../components/stepper/Stepper";
import Header from "../../components/headers/Header";
import { Beat, BeatLite } from "../../constants/icons/Icons";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import AssesmentDga from "./Assessment/AssessmentDga";
import "./DataPointGapAssessment.scss";

const DataPointGapAssessment = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("sources");

  const { moduleId } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const projectId = location.state?.projectId;
  const token = location.state?.token;
  const projectName = location.state?.projectName;
  const sessionData = location.state?.sessionData;

  const handleStepChange = (index) => {
    setActiveIndex(index);
  };

  const switchToUnderstandTheContext = (tab) => {
    setActiveTab(tab);
    handleStepChange(1);
  };

  const getIcon = (index, activeIcon, inactiveIcon) => {
    return activeIndex === index ? activeIcon : inactiveIcon;
  };

  const project = sessionData?.find((project) =>
    project.modules.some((module) => module.id === parseInt(moduleId))
  );

  const stepItems = [
    { label: t("All projects"), link: "/" },
    { label: projectName, link: `/project-home/${projectId}`, token },
    { label: project?.name, link: `/project-home/${projectId}`, token },
    { label: t("DGA"), link: "#" },
  ];

  return (
    <div className="dga">
      <Header />
      <Stepper
        activeIndex={activeIndex} 
        onChange={handleStepChange} 
        items={stepItems}
      >
        <div icon={getIcon(0, <Beat />, <BeatLite />)} text={t("UnderstandTheContext")}>
          <AssesmentDga onTabChange={switchToUnderstandTheContext} />
        </div>
        <div icon={getIcon(0, <Beat />, <BeatLite />)} text={t("Assessment")}>
          {/* <AssesmentDga onTabChange={switchToUnderstandTheContext} /> */}
        </div>
        <div icon={getIcon(0, <Beat />, <BeatLite />)} text={t("Overview")}>
          {/* <AssesmentDga onTabChange={switchToUnderstandTheContext} /> */}
        </div>
      </Stepper>
    </div>
  );
};

export default DataPointGapAssessment;

import React from "react";
import "./ProjectDetails.scss";
import {
  MotifAccordion,
  MotifAccordionTrigger,
  MotifAccordionContent,
} from "@ey-xd/motif-react";
import ProjectDetailTable from "./ProjectDetailTable";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import { Edit } from "../../../constants/icons/Icons";

function ProjectDetails({
  onClose,
  onOpen,
  EditEN,
  ProjectDetailTitle,
  projectName,
  clientName,
  country,
  engagementcode,
  projectSizeUSD,
  clientSector,
  projectAdministrator,
  data,
  handleEditNavigate,
}) {
  return (
    <div>
      <div className="project-details-accordion">
        <div
          style={{
            alignItems: "flex-start",
            display: "flex",
            flexWrap: "wrap",
            gap: "15px",
          }}
        >
          <MotifAccordion
            onClose={onClose}
            onOpen={onOpen}
            style={{
              flex: "1",
            }}
            variant="editorial"
          >
            <MotifAccordionTrigger>
              <div className="trigger-content">
                {ProjectDetailTitle}
                <div className="edit">
                <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditNavigate();
                }}>
                  <Edit />
                </IconButton>
                </div>
              </div>
            </MotifAccordionTrigger>
            <MotifAccordionContent className="project-details-table">
              &nbsp; &nbsp; &nbsp; &nbsp;
              <div className="project-details-table-admin">
                <ProjectDetailTable
                  data={data}
                  projectName={projectName}
                  clientName={clientName}
                  country={country}
                  engagementcode={engagementcode}
                  projectSizeUSD={projectSizeUSD}
                  clientSector={clientSector}
                  projectAdministrator={projectAdministrator}
                />
              </div>
              &nbsp; &nbsp;
            </MotifAccordionContent>
          </MotifAccordion>
        </div>
      </div>
    </div>
  );
}

ProjectDetails.propTypes = {
  onClose: PropTypes.bool,
  onOpen: PropTypes.bool,
  EditEN: PropTypes.string,
  ProjectDetailTitle: PropTypes.string,
  projectName: PropTypes.string,
  clientName: PropTypes.string,
  country: PropTypes.string,
  engagementcode: PropTypes.string,
  projectSizeUSD: PropTypes.string,
  clientSector: PropTypes.string,
  projectAdministrator: PropTypes.string,
  data: PropTypes.object,
  handleEditNavigate: PropTypes.func,
};

export default ProjectDetails;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_IROs,
  CREATE_IRO,
  UPDATE_IRO,
  DELETE_IRO,
  GET_APS_IRO,
  GET_VALUE_CHAIN_IRO,
  GET_SOURCES_IRO,
  GET_GEOGRAPHIES_IRO,
  GET_STAKEHOLDERS_IRO,
  UPDATE_SOURCES_IRO,
  UPDATE_VALUE_CHAIN_IRO,
  UPDATE_APS_IRO,
  UPDATE_STAKEHOLDERS_IRO,
  UPDATE_GEOGRAPHIES_IRO,
  GET_ALL_SUSTAINABILITY_MATTERS_IRO,
  UPDATE_SUSTAINABILITY_MATTERS_IRO,
  GET_ALL_TIMEFRAME_TYPES,
  GET_ALL_IRO_TYPES
} from "../../services/Api";

export const getAllIro = createAsyncThunk(
  "getAllIro",
  async ({ moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_ALL_IROs}?moduleId=${moduleId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching IRO data:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching IRO data"
      );
    }
  }
);

export const createIro = createAsyncThunk(
  "createIro",
  async ({ iroData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${CREATE_IRO}`, iroData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error creating IRO:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error creating IRO"
      );
    }
  }
);

export const updateIro = createAsyncThunk(
  "updateIro",
  async ({ iroId, iroData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${UPDATE_IRO}`, iroData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error updating IRO:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error updating IRO"
      );
    }
  }
);

export const deleteIro = createAsyncThunk(
  "deleteIro",
  async ({ iroId, token, moduleId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${DELETE_IRO}?moduleIROId=${iroId}&moduleId=${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting Iro:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error deleting Iro"
      );
    }
  }
);

export const getSourcesIro = createAsyncThunk(
  "getSourcesIro",
  async ({ iroId, token, moduleId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_SOURCES_IRO}?moduleIROId=${iroId}&moduleId=${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching IRO Sources:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching IRO Sources"
      );
    }
  }
);

export const getValueChainIro = createAsyncThunk(
  "getValueChainIro",
  async ({ iroId, token, moduleId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_VALUE_CHAIN_IRO}/${iroId}?moduleId=${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching IRO Value Chain:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching IRO Value Chain"
      );
    }
  }
);

export const getApsIro = createAsyncThunk(
  "getApsIro",
  async ({ iroId, token, moduleId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_APS_IRO}/${iroId}?moduleId=${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching IRO APS:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error fetching IRO APS"
      );
    }
  }
);

export const getGeographiesIro = createAsyncThunk(
  "getGeographiesIro",
  async ({ iroId, token, moduleId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_GEOGRAPHIES_IRO}/${iroId}?moduleId=${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching IRO Geographies:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching IRO Geographies"
      );
    }
  }
);

export const getStakeholdersIro = createAsyncThunk(
  "getStakeholdersIro",
  async ({ iroId, token, moduleId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_STAKEHOLDERS_IRO}/${iroId}?moduleId=${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching IRO Stakeholders:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching IRO Stakeholders"
      );
    }
  }
);

export const updateSourcesIro = createAsyncThunk(
  "updateSourcesIro",
  async ({ iroId, iroData, token, moduleId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${UPDATE_SOURCES_IRO}/${iroId}?moduleId=${moduleId}`,
        iroData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating IRO:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error updating IRO"
      );
    }
  }
);

export const updateValueChainIro = createAsyncThunk(
  "updateValueChainIro",
  async ({ iroId, iroData, token, moduleId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${UPDATE_VALUE_CHAIN_IRO}/${iroId}?moduleId=${moduleId}`,
        iroData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating IRO:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error updating IRO"
      );
    }
  }
);

export const updateApsIro = createAsyncThunk(
  "updateApsIro",
  async ({ iroId, iroData, token, moduleId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${UPDATE_APS_IRO}/${iroId}?moduleId=${moduleId}`,
        iroData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating IRO:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error updating IRO"
      );
    }
  }
);

export const updateGeographiesIro = createAsyncThunk(
  "updateGeographiesIro",
  async ({ iroId, iroData, token, moduleId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${UPDATE_GEOGRAPHIES_IRO}/${iroId}?moduleId=${moduleId}`,
        iroData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating IRO:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error updating IRO"
      );
    }
  }
);

export const updateStakeholdersIro = createAsyncThunk(
  "updateStakeholdersIro",
  async ({ iroId, iroData, token, moduleId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${UPDATE_STAKEHOLDERS_IRO}/${iroId}?moduleId=${moduleId}`,
        iroData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating IRO:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error updating IRO"
      );
    }
  }
);

export const getAllSustainabilityMattersIro = createAsyncThunk(
  "getAllSustainabilityMattersIro",
  async ({ moduleId, iroId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_ALL_SUSTAINABILITY_MATTERS_IRO}/${moduleId}/${iroId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching Sustainabillity Matter data:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching Sustainabillity Matter data"
      );
    }
  }
);

export const updateSustainabilityMattersIro = createAsyncThunk(
  "updateSustainabilityMattersIro",
  async ({ iroId, iroData, token, moduleId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${UPDATE_SUSTAINABILITY_MATTERS_IRO}/${iroId}?moduleId=${moduleId}`,
        iroData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating IRO:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error updating IRO"
      );
    }
  }
);

export const getAllTimeframeTypes = createAsyncThunk(
  "getAllTimeframeTypes",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_ALL_TIMEFRAME_TYPES}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching TIMEFRAME types:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching TIMEFRAME types"
      );
    }
  }
);

export const getAllIroTypes = createAsyncThunk(
  "getAllIroTypes",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_ALL_IRO_TYPES}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching IRO types:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching IRO types"
      );
    }
  }
);

const iroSlice = createSlice({
  name: "Iro",
  initialState: {
    isLoading: false,
    data: null,
    sourcesData: null,
    apsData: null,
    valueChainData: null,
    geographiesData: null,
    stakeholdersData: null,
    iroSustainabilityMattersData: null,
    timeframeTypesData: null,
    iroTypesData: null,
    isError: false,
    errorMessage: "",
    updateSuccess: false,
    types: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllIro.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getAllIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(getAllIro.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createIro.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(createIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(createIro.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateIro.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(updateIro.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteIro.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(deleteIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(deleteIro.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getSourcesIro.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getSourcesIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sourcesData = action.payload.data;
        state.isError = false;
      })
      .addCase(getSourcesIro.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getApsIro.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getApsIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.apsData = action.payload.data;
        state.isError = false;
      })
      .addCase(getApsIro.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getValueChainIro.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getValueChainIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.valueChainData = action.payload.data;
        state.isError = false;
      })
      .addCase(getValueChainIro.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getGeographiesIro.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getGeographiesIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.geographiesData = action.payload.data;
        state.isError = false;
      })
      .addCase(getGeographiesIro.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getStakeholdersIro.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getStakeholdersIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stakeholdersData = action.payload.data;
        state.isError = false;
      })
      .addCase(getStakeholdersIro.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateSourcesIro.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateSourcesIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stakeholdersData = action.payload.data;
        state.isError = false;
      })
      .addCase(updateSourcesIro.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateValueChainIro.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateValueChainIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stakeholdersData = action.payload.data;
        state.isError = false;
      })
      .addCase(updateValueChainIro.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateApsIro.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateApsIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stakeholdersData = action.payload.data;
        state.isError = false;
      })
      .addCase(updateApsIro.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateGeographiesIro.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateGeographiesIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stakeholdersData = action.payload.data;
        state.isError = false;
      })
      .addCase(updateGeographiesIro.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateStakeholdersIro.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateStakeholdersIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stakeholdersData = action.payload.data;
        state.isError = false;
      })
      .addCase(updateStakeholdersIro.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllSustainabilityMattersIro.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getAllSustainabilityMattersIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.iroSustainabilityMattersData = action.payload.data;
        state.isError = false;
      })
      .addCase(getAllSustainabilityMattersIro.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateSustainabilityMattersIro.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateSustainabilityMattersIro.fulfilled, (state, action) => {
        state.isLoading = false;
        state.iroSustainabilityMattersData = action.payload.data;
        state.isError = false;
      })
      .addCase(updateSustainabilityMattersIro.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllTimeframeTypes.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getAllTimeframeTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.timeframeTypesData = action.payload.data;
        state.isError = false;
      })
      .addCase(getAllTimeframeTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllIroTypes.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getAllIroTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.iroTypesData = action.payload.data;
        state.isError = false;
      })
      .addCase(getAllIroTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
  },
});

export default iroSlice.reducer;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_DMA_SM_IDENTIFIED_IMPACTS,
  GET_ALL_DMA_SM_MATERIAL_IDENTIFIED_IMPACTS,
  GET_ALL_DMA_SM_IDENTIFIED_RISK_AND_OPPORTUNITY,
  GET_ALL_DMA_SM_MATERIAL_IDENTIFIED_RISK_AND_OPPORTUNITY,
} from "../../services/Api";

export const getIdentifiedImpacts = createAsyncThunk(
  "getIdentifiedImpacts",
  async ({ moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_ALL_DMA_SM_IDENTIFIED_IMPACTS}/${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching Sustainabillity Matter data:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching Sustainabillity Matter data"
      );
    }
  }
);
export const getMaterialIdentifiedImpacts = createAsyncThunk(
  "getMaterialIdentifiedImpacts",
  async ({ moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_ALL_DMA_SM_MATERIAL_IDENTIFIED_IMPACTS}/${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching Sustainabillity Matter data:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching Sustainabillity Matter data"
      );
    }
  }
);
export const getIdentifiedRiskAndOpportunity = createAsyncThunk(
  "getIdentifiedRiskAndOpportunity",
  async ({ moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_ALL_DMA_SM_IDENTIFIED_RISK_AND_OPPORTUNITY}/${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching Sustainabillity Matter data:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching Sustainabillity Matter data"
      );
    }
  }
);
export const getMaterialIdentifiedRiskAndOpportunity = createAsyncThunk(
  "getMaterialIdentifiedRiskAndOpportunity",
  async ({ moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_ALL_DMA_SM_MATERIAL_IDENTIFIED_RISK_AND_OPPORTUNITY}/${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching Sustainabillity Matter data:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching Sustainabillity Matter data"
      );
    }
  }
);

// Slice definition
const dmaReportingSlice = createSlice({
  name: "dmaReporting",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: "",
    updateSuccess: false,
    types: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIdentifiedImpacts.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getIdentifiedImpacts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(getIdentifiedImpacts.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getMaterialIdentifiedImpacts.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getMaterialIdentifiedImpacts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(getMaterialIdentifiedImpacts.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getIdentifiedRiskAndOpportunity.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getIdentifiedRiskAndOpportunity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(getIdentifiedRiskAndOpportunity.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getMaterialIdentifiedRiskAndOpportunity.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(
        getMaterialIdentifiedRiskAndOpportunity.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.postSuccess = true;
          state.data = action.payload;
          state.isError = false;
        }
      )
      .addCase(
        getMaterialIdentifiedRiskAndOpportunity.rejected,
        (state, action) => {
          state.isLoading = false;
          state.postSuccess = false;
          state.isError = true;
          state.errorMessage = action.payload;
        }
      );
  },
});

export default dmaReportingSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_VALUE_CHAIN_TYPES,
  CREATE_VALUE_CHAIN,
  UPDATE_VALUE_CHAIN,
  DELETE_VALUE_CHAIN,
  GET_VALUE_CHAIN,
} from "../../services/Api";

export const fetchValueChainTypes = createAsyncThunk(
  "fetchValueChainTypes",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_VALUE_CHAIN_TYPES, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching value chain types:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching value chain types"
      );
    }
  }
);

export const fetchValueChain = createAsyncThunk(
  "fetchValueChain",
  async ({ moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_VALUE_CHAIN}/${moduleId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching value chain list:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching value chain list"
      );
    }
  }
);

export const createNewValueChain = createAsyncThunk(
  "createNewValueChain",
  async ({ moduleId, valueChainData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${CREATE_VALUE_CHAIN}/${moduleId}`,
        valueChainData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating value chain:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error creating value chain"
      );
    }
  }
);

export const updateValueChain = createAsyncThunk(
  "updateValueChain",
  async (
    { valueChainId, moduleId, valueChainData, token },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(
        `${UPDATE_VALUE_CHAIN}/${valueChainId}/${moduleId}`,
        valueChainData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating value chain:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error updating value chain"
      );
    }
  }
);

export const deleteValueChain = createAsyncThunk(
  "deleteValueChain",
  async ({ valueChainId, moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${DELETE_VALUE_CHAIN}/${valueChainId}/${moduleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting value chain:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error deleting value chain"
      );
    }
  }
);

// Slice definition
const dmaValueChainSlice = createSlice({
  name: "dmaValueChain",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: "",
    updateSuccess: false,
    types: [],
    postSuccess: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchValueChainTypes.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchValueChainTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.types = action.payload;
        state.isError = false;
      })
      .addCase(fetchValueChainTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fetchValueChain.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchValueChain.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(fetchValueChain.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createNewValueChain.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(createNewValueChain.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(createNewValueChain.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateValueChain.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateValueChain.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(updateValueChain.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteValueChain.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(deleteValueChain.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(deleteValueChain.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      });
  },
});

export default dmaValueChainSlice.reducer;

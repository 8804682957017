import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ExcelJS from "exceljs";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import DMACards from "./dmaCards/DMACards";
import { Stake, Iro, Reporting } from "../../../constants/icons/Icons";
import {
  fetchUnderstandingTheContextCount,
  fetchIROCount,
} from "../../../features/slices/DMAOverview";
import { fetchExcelData } from "../../../features/slices/LongListData";
import ErrorModal from "../../../components/modals/error/errorModal";
import { useTranslation } from "react-i18next";

export const DMAOverview = ({ onTabChange }) => {
  const dispatch = useDispatch();
  const { moduleId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const token = location.state?.token;
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("sources");
  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Track loading state for both context and Excel data

  const { contextData, iroData, isError, errorMessage } = useSelector(
    (state) => state.dmaOverview
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([
          dispatch(fetchUnderstandingTheContextCount({ moduleId, token })),
          dispatch(fetchIROCount({ moduleId, token })),
        ]);
      } catch (error) {
        console.error("Error fetching context data:", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [dispatch, moduleId, token]);

  const addSheet = (workbook, sheetName, data) => {
    const uniqueSourceTypes = Array.from(
      new Set(
        data.flatMap((item) => item.sources.map((source) => source.sourceType))
      )
    );
    const uniqueSourceNames = Array.from(
      new Set(data.flatMap((item) => item.sources.map((source) => source.name)))
    );

    const uniqueValueChainTypes = Array.from(
      new Set(
        data.flatMap((item) => item.valueChain.map((vc) => vc.valueChainType))
      )
    );
    const uniqueValueChainNames = Array.from(
      new Set(data.flatMap((item) => item.valueChain.map((vc) => vc.name)))
    );

    const uniqueStakeholderNames = Array.from(
      new Set(data.flatMap((item) => item.stakeholders.map((st) => st.name)))
    );
    const uniqueGeographyNames = Array.from(
      new Set(data.flatMap((item) => item.geographies.map((geo) => geo.name)))
    );
    const uniqueActProServiceNames = Array.from(
      new Set(
        data.flatMap((item) => item.actProServices.map((aps) => aps.name))
      )
    );

    // Top-level headers without "Section", "ESRS", "Topic", "SubTopic", and "SubSubTopic"
    const headers = [
      ...Array(uniqueSourceTypes.length * uniqueSourceNames.length).fill(
        "Sources"
      ),
      ...Array(
        uniqueValueChainTypes.length * uniqueValueChainNames.length
      ).fill("Value Chain"),
      ...Array(uniqueStakeholderNames.length).fill("Stakeholders"),
      ...Array(uniqueGeographyNames.length).fill("Geographies"),
      ...Array(uniqueActProServiceNames.length).fill("ActProServices"),
    ];

    // Second row for source and value chain types without extra fields
    const typeNamesRow = [
      ...uniqueSourceTypes.flatMap((type) =>
        Array(uniqueSourceNames.length).fill(type)
      ),
      ...uniqueValueChainTypes.flatMap((type) =>
        Array(uniqueValueChainNames.length).fill(type)
      ),
      ...uniqueStakeholderNames.map(() => ""), // Leave blank for other sections
      ...uniqueGeographyNames.map(() => ""),
      ...uniqueActProServiceNames.map(() => ""),
    ];

    // Third row with "Section", "ESRS", "Topic", "SubTopic", "SubSubTopic" and individual names
    const subHeaders = [
      "Section",
      "ESRS",
      "Topic",
      "SubTopic",
      "SubSubTopic",
      ...uniqueSourceTypes.flatMap((type) => uniqueSourceNames),
      ...uniqueValueChainTypes.flatMap((type) => uniqueValueChainNames),
      ...uniqueStakeholderNames,
      ...uniqueGeographyNames,
      ...uniqueActProServiceNames,
    ];

    const worksheetData = [headers, typeNamesRow, subHeaders];

    // Populate rows based on item data
    data.forEach((item) => {
      const row = [
        item.section,
        item.esrs,
        item.topic,
        item.subTopic,
        item.subSubTopic,
      ];

      // For each source, mark "X" if active
      uniqueSourceTypes.forEach((type) => {
        uniqueSourceNames.forEach((name) => {
          const isActive = item.sources.some(
            (src) =>
              src.sourceType === type && src.name === name && src.isActive
          );
          row.push(isActive ? "X" : "");
        });
      });

      uniqueValueChainTypes.forEach((type) => {
        uniqueValueChainNames.forEach((name) => {
          const isActive = item.valueChain.some(
            (vc) =>
              vc.valueChainType === type && vc.name === name && vc.isActive
          );
          row.push(isActive ? "X" : "");
        });
      });

      uniqueStakeholderNames.forEach((name) => {
        const isActive = item.stakeholders.some(
          (st) => st.name === name && st.isActive
        );
        row.push(isActive ? "X" : "");
      });

      uniqueGeographyNames.forEach((name) => {
        const isActive = item.geographies.some(
          (geo) => geo.name === name && geo.isActive
        );
        row.push(isActive ? "X" : "");
      });

      uniqueActProServiceNames.forEach((name) => {
        const isActive = item.actProServices.some(
          (aps) => aps.name === name && aps.isActive
        );
        row.push(isActive ? "X" : "");
      });

      worksheetData.push(row);
    });

    // Create worksheet and add it to the workbook
    const worksheet = workbook.addWorksheet(sheetName);
    worksheet.addRows(worksheetData);

    // Merge cells for top-level headers
    const sourcesStartCol = 1;
    const sourcesEndCol =
      sourcesStartCol + uniqueSourceTypes.length * uniqueSourceNames.length - 1;
    const valueChainStartCol = sourcesEndCol + 1;
    const valueChainEndCol =
      valueChainStartCol +
      uniqueValueChainTypes.length * uniqueValueChainNames.length -
      1;
    const stakeholdersStartCol = valueChainEndCol + 1;
    const stakeholdersEndCol =
      stakeholdersStartCol + uniqueStakeholderNames.length - 1;
    const geographiesStartCol = stakeholdersEndCol + 1;
    const geographiesEndCol =
      geographiesStartCol + uniqueGeographyNames.length - 1;
    const actProServicesStartCol = geographiesEndCol + 1;
    const actProServicesEndCol =
      actProServicesStartCol + uniqueActProServiceNames.length - 1;

    worksheet.mergeCells(1, sourcesStartCol, 1, sourcesEndCol); // Merge "Sources" header
    worksheet.mergeCells(1, valueChainStartCol, 1, valueChainEndCol); // Merge "Value Chain" header
    worksheet.mergeCells(1, stakeholdersStartCol, 1, stakeholdersEndCol); // Merge "Stakeholders" header
    worksheet.mergeCells(1, geographiesStartCol, 1, geographiesEndCol); // Merge "Geographies" header
    worksheet.mergeCells(1, actProServicesStartCol, 1, actProServicesEndCol); // Merge "ActProServices" header

    // Merge cells for source and value chain types row (type names only appear once for their section)
    let currentCol = sourcesStartCol;
    uniqueSourceTypes.forEach((type) => {
      worksheet.mergeCells(
        2,
        currentCol,
        2,
        currentCol + uniqueSourceNames.length - 1
      );
      currentCol += uniqueSourceNames.length;
    });

    currentCol = valueChainStartCol;
    uniqueValueChainTypes.forEach((type) => {
      worksheet.mergeCells(
        2,
        currentCol,
        2,
        currentCol + uniqueValueChainNames.length - 1
      );
      currentCol += uniqueValueChainNames.length;
    });

    // Set column widths for better visibility
    worksheet.columns = Array(headers.length).fill({ width: 15 });

    // Define border style
    const rightBorderStyle = {
      style: "thin",
      color: { argb: "000000" }, // Black border
    };

    // Apply right border to last columns of each section
    worksheet
      .getColumn(sourcesEndCol)
      .eachCell((cell) => (cell.border = { right: rightBorderStyle }));
    worksheet
      .getColumn(valueChainEndCol)
      .eachCell((cell) => (cell.border = { right: rightBorderStyle }));
    worksheet
      .getColumn(stakeholdersEndCol)
      .eachCell((cell) => (cell.border = { right: rightBorderStyle }));
    worksheet
      .getColumn(geographiesEndCol)
      .eachCell((cell) => (cell.border = { right: rightBorderStyle }));
    worksheet
      .getColumn(actProServicesEndCol)
      .eachCell((cell) => (cell.border = { right: rightBorderStyle }));

    // Apply styles for headers (optional)
    [worksheet.getRow(1), worksheet.getRow(2), worksheet.getRow(3)].forEach(
      (row) => {
        row.eachCell((cell) => {
          cell.font = { bold: true };
          cell.alignment = { vertical: "middle", horizontal: "center" };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFE0B2" }, // light orange color for headers
          };
        });
      }
    );
  };

  const formatExcelData = (data) => {
    const workbook = new ExcelJS.Workbook();
    addSheet(workbook, "Sustainability Matters", data);
    return workbook;
  };

  // Function to trigger Excel download
  const downloadExcel = async (data) => {
    const workbook = formatExcelData(data);
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "sustainability_matters.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const handleDownloadExcel = async () => {
    setIsExcelLoading(true);
    try {
      const excelResponse = await dispatch(
        fetchExcelData({ moduleId, token })
      ).unwrap();
      const exceResponseData = excelResponse.data || [];
      await downloadExcel(exceResponseData);
    } catch (error) {
      console.error("Error fetching Excel data:", error);
    } finally {
      setIsExcelLoading(false);
    }
  };

  const handleError = () => {
    navigate("/");
  };

  if (isError) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={errorMessage}
          handleButtonClick={handleError}
          deleteButtonLabel={t("Reload")}
        />
      </div>
    );
  }

  return (
    <div className="dma-overview-container">
      {isLoading || isExcelLoading ? (
        <MotifProgressLoader className="loader" show variant="default" />
      ) : (
        <>
          <DMACards
            headerIconLeft={<Stake />}
            lefttype={t("UTC")}
            UTC={t("UTC")}
            references={contextData?.data}
            headerIconRight={<Reporting />}
            Longlist={t("Longlist")}
            Shortlist={t("Shortlist")}
            Dashboard={t("Dashboard")}
            ReportingImplication={t("ReportingImplication")}
            rightHeaderType={t("ReportingImplication")}
            onTabChange={onTabChange}
            onLonglistClick={handleDownloadExcel} // Pass the download function here
          />
          <DMACards
            headerIconLeft={<Iro />}
            headerIconRight={<Stake />}
            references={iroData?.data}
            lefttype={t("IROIdentificationAndAssessment")}
            IROIdentificationAndAssessment={t("IROIdentificationAndAssessment")}
            rightHeaderType={t("StakeholderOutreach")}
            StakeholderOutreach={t("StakeholderOutreach")}
            onTabChange={setActiveTab}
          />
        </>
      )}
    </div>
  );
};

export default DMAOverview;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_THRESHOLD,
  CREATE_UPDATE_THRESHOLD,
  GET_THRESHOLD_TYPES,
} from "../../services/Api";


export const fetchThresholdTypes = createAsyncThunk(
  "fetchThresholdTypes",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_THRESHOLD_TYPES, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });

      return response.data;
    } catch (error) {
      console.error(
        "Error fetching Threshold types:",
        error
      );
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error fetching Threshold types"
      );
    }
  }
);

export const fetchThreshold = createAsyncThunk(
  "fetchThreshold",
  async ({ moduleId, thresholdTypeId , token }, { rejectWithValue }) => {
    try {
      
      const response = await axios.get(`${GET_THRESHOLD}/${moduleId}/${thresholdTypeId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching Threshold:", error);
      return rejectWithValue(error.response.data?.title || error?.message || "Error Threshold");
    }
  }
);

export const createUpdateThreshold = createAsyncThunk(
  "createUpdateThreshold",
  async ({ moduleId, thresholdData, token }, { rejectWithValue }) => {
    try {
      
      const response = await axios.post(
        `${CREATE_UPDATE_THRESHOLD}/${moduleId}`,
        thresholdData,
        {
          headers: {
            Authorization: `Bearer ${token}`
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating updating threshold data:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error creating updating threshold data"
      );
    }
  }
);

const dmaProcessSpecificationSlice = createSlice({
  name: "dmaProcessSpecification",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    postSuccess: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder.addCase(fetchThresholdTypes.pending, (state) => {
      state.isLoading = true;
      state.errorMessage = "";
    });
    builder.addCase(fetchThresholdTypes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchThresholdTypes.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
    builder.addCase(fetchThreshold.pending, (state) => {
      state.isLoading = true;
      state.errorMessage = "";
    });
    builder.addCase(fetchThreshold.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchThreshold.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
    builder.addCase(createUpdateThreshold.pending, (state) => {
      state.isLoading = true;
      state.postSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    });
    builder.addCase(createUpdateThreshold.fulfilled, (state, action) => {
      state.isLoading = false;
      state.postSuccess = true;
      state.data = action.payload;
      state.isError = false;
    });
    builder.addCase(createUpdateThreshold.rejected, (state, action) => {
      state.isLoading = false;
      state.postSuccess = false;
      state.isError = true;
      state.errorMessage = action.payload;
    });
  },
});

export default dmaProcessSpecificationSlice.reducer;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./ContextSubHeader.scss";

const ContextSubHeader = ({ children, activeTab, setActiveTab }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const newIndex = React.Children.toArray(children).findIndex(
      (child) =>
        child.props.text.trim().toLowerCase() === activeTab.trim().toLowerCase()
    );
    if (newIndex !== -1) {
      setActiveIndex(newIndex);
    }
  }, [activeTab, children]);

  const handleItemClick = (index, text) => {
    setActiveIndex(index);
    setActiveTab(text);
  };

  return (
    <>
      <div
        className="context-stepper context-background"
        style={{ fontFamily: "EYInterstate !important" }}
      >
        {React.Children.map(children, (child, index) => {
          const key = child.key || index;
          return (
            <button
              key={key}
              className={`context-box-item ${
                activeIndex === index ? "active" : ""
              }`}
              onClick={() => handleItemClick(index, child.props.text)}
              data-testid={`context-box-item-${index}`}
            >
              <div className="context-content">
                <div className="context-text">{child.props.text}</div>
              </div>
            </button>
          );
        })}
      </div>
      <div
        className="context-section-content"
        data-testid="context-section-content"
      >
        {React.Children.toArray(children)[activeIndex].props.children}
      </div>
    </>
  );
};

ContextSubHeader.propTypes = {
  children: PropTypes.node.isRequired,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
};

export default ContextSubHeader;
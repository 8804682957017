import React from "react";
import "./Subheader.scss";
import {
  Add,
  ClientOrg,
  UserManagement,
  Stake,
} from "../../constants/icons/Icons";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { MotifButton } from "@ey-xd/motif-react";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip"; // Import Tooltip

const Subheader = ({
  isAdmin,
  page,
  disableButton,
  title,
  handleOpenModal,
  handleNavigate,
  handleSessionModal,
  handleClientOrg,
  handleUserManagement,
  onChangeSearchInput,
  ProjectHomeAccessTitle,
  ClientOrgTitleEN,
  FileManagerButtonTitle,
  handleFileManager,
  UserManagementTitleEN,
  NewSessionTitleEN,
  AddNewEN,
  ClientOrgAccessTitleEN,
  AddNewAccessTitleProject,
  CreateNewEN,
  disableUser,
  showAddNewButton = true,
}) => {
  const theme = createTheme({
    typography: {
      fontFamily: "EYInterstate, sans-serif",
    },
  });

  const subheaderSearchWidth = (page) => {
    switch (page) {
      case "NewProj":
        return "60%";
      case "ProjectHome":
        return "24%";
      case "FileManager":
        return "70%";
      case "ClientOrg":
        return "60%";
      default:
        return "25%%";
    }
  };

  const subheaderPadding = (page) => {
    switch (page) {
      case "ProjectHome":
        return "0px 0px";
      default:
        return "0px 24px";
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box id="subheader" sx={{ padding: subheaderPadding(page) }}>
        <h1 className="Text">{title}</h1>
        <Box className="subheader-button-container">
          <Box
            className="subheader-search"
            sx={{ width: subheaderSearchWidth(page) }}
          >
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "96%",
                boxShadow: "none",
                border: "1px solid #e0e0e0",
              }}
            >
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{
                  ml: 1,
                  flex: 1,
                  fontFamily: "EYInterstate",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  textAlign: "left",
                }}
                placeholder="Search "
                onChange={onChangeSearchInput}
                inputProps={{ "aria-label": "search For" }}
              />
            </Paper>
          </Box>
          &nbsp; &nbsp;
          {isAdmin && page === ProjectHomeAccessTitle && (
            <Box className="subheader-buttons">
              <Box className="subheader-icon-buttons">
                <MotifButton
                  className="subheader-button"
                  onClick={handleFileManager}
                  size="small"
                  type="submit"
                  variant="text"
                  disabled={disableButton}
                >
                  {FileManagerButtonTitle}
                  &nbsp;
                  <Stake />
                </MotifButton>
                &nbsp; &nbsp;
                <MotifButton
                  className="subheader-button"
                  onClick={handleClientOrg}
                  size="small"
                  type="submit"
                  variant="text"
                  disabled={disableButton}
                >
                  {ClientOrgTitleEN}
                  &nbsp;
                  <ClientOrg />
                </MotifButton>
                &nbsp; &nbsp;
                <Tooltip
                  title={disableUser ? "You must be project admin to access this section" : ""}
                  arrow
                  placement="top"
                >
                  <span>
                    <MotifButton
                      className="subheader-button"
                      onClick={handleUserManagement}
                      size="small"
                      type="submit"
                      variant="text"
                      disabled={disableUser}
                    >
                      {UserManagementTitleEN}
                      &nbsp;
                      <UserManagement />
                    </MotifButton>
                  </span>
                </Tooltip>
              </Box>
              &nbsp; &nbsp;
              <MotifButton
                className="subheader-button-add"
                onClick={handleSessionModal}
                size="small"
                type="submit"
                variant="primary-alt"
              >
                {NewSessionTitleEN}
                &nbsp;
                <Add />
              </MotifButton>
            </Box>
          )}
          {isAdmin && page === ClientOrgAccessTitleEN && (
            <Box className="ButtonContainer">
              <MotifButton
                className="subheader-button-add"
                onClick={handleOpenModal}
                size="small"
                type="submit"
                variant="primary-alt"
                disabled={disableButton}
              >
                {CreateNewEN}
                &nbsp;
                <Add />
              </MotifButton>
            </Box>
          )}
          {isAdmin && showAddNewButton && page === AddNewAccessTitleProject && (
            <Box className="ButtonContainer">
              <MotifButton
                className="subheader-button-add"
                onClick={handleNavigate}
                size="small"
                type="submit"
                variant="primary-alt"
                disabled={disableButton}
              >
                &nbsp;
                {AddNewEN}
                <Add />
              </MotifButton>
            </Box>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

Subheader.propTypes = {
  isAdmin: PropTypes.bool,
  page: PropTypes.string.isRequired,
  title: PropTypes.string,
  handleOpenModal: PropTypes.func,
  handleNavigate: PropTypes.func,
  handleSessionModal: PropTypes.func,
  handleClientOrg: PropTypes.func,
  handleUserManagement: PropTypes.func,
  onChangeSearchInput: PropTypes.func.isRequired,
  ClientOrgTitleEN: PropTypes.string,
  FileManagerButtonTitle: PropTypes.string,
  handleFileManager: PropTypes.func,
  UserManagementTitleEN: PropTypes.string,
  NewSessionTitleEN: PropTypes.string,
  AddNewEN: PropTypes.string,
  ClientOrgAccessTitleEN: PropTypes.string,
  AddNewAccessTitleProject: PropTypes.string,
  CreateNewEN: PropTypes.string,
  showAddNewButton: PropTypes.bool,
};

export default Subheader;
import React from "react";
import { MotifBreadcrumb, MotifBreadcrumbItem } from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./Breadcrumbs.scss";

const Breadcrumbs = ({ items }) => {
  return (
    <MotifBreadcrumb
      triggerButtonProps={{
        "aria-label": "Custom Trigger Button aria-label",
        title: "Custom Trigger Button title",
      }}
    >
      {items.map(({ label, link, token }, index) => {
        const isLast = index === items.length - 1;
        return (
          <MotifBreadcrumbItem key={link}>
            {isLast ? (
              <span className="breadcrumb-inactive">{label}</span>
            ) : (
              <Link to={link} state={{ token }}>
                <span className="breadcrumb-active">{label}</span>
              </Link>
            )}
          </MotifBreadcrumbItem>
        );
      })}
    </MotifBreadcrumb>
  );
};
Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Breadcrumbs;

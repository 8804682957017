import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  LIST_OF_FILES,
  UPLOAD_FILES,
  DELETE_FILES,
  DOWNLOAD_FILES,
} from "../../services/Api";

export const fetchFiles = createAsyncThunk(
  "fileManager/fetchFiles",
  async ({ projectId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${LIST_OF_FILES}?projectId=${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching files:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error fetching files"
      );
    }
  }
);

export const downloadFile = createAsyncThunk(
  "fileManager/downloadFile",
  async ({ guidName, projectId, filename, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${DOWNLOAD_FILES}?blobId=${guidName}&projectId=${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );
      return { data: response.data, filename };
    } catch (error) {
      console.error("Error downloading file:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error downloading file"
      );
    }
  }
);

export const uploadFile = createAsyncThunk(
  "fileManager/uploadFile",
  async ({ fileData, projectId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${UPLOAD_FILES}?projectId=${projectId}`,
        fileData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error uploading file"
      );
    }
  }
);

export const deleteFile = createAsyncThunk(
  "fileManager/deleteFile",
  async ({ fileId, projectId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${DELETE_FILES}?blobId=${fileId}&projectId=${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting file:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error deleting file"
      );
    }
  }
);

const fileManagerSlice = createSlice({
  name: "fileManager",
  initialState: {
    isLoading: false,
    files: [],
    isError: false,
    errorMessage: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFiles.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchFiles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.files = Array.isArray(action.payload.data)
          ? action.payload.data
          : [];
      })
      .addCase(fetchFiles.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(downloadFile.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(downloadFile.fulfilled, (state, action) => {
        state.isLoading = false;
        const { data, filename } = action.payload;
        const url = window.URL.createObjectURL(
          new Blob([data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename || "default-filename.pdf");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .addCase(downloadFile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(uploadFile.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.files = [...state.files, action.payload];
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteFile.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.files = state.files.filter(
          (file) => file.id !== action.payload.id
        );
      })
      .addCase(deleteFile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      });
  },
});

export default fileManagerSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_PROJECT_DETAILS_URL,
  UPDATE_PROJECT_DETAILS_URL,
  GET_USERS_URL,
  PROJECT_BASE_URL,
  CREATE_NEW_USER_URL,
  CREATE_SESSION_URL,
  CREATE_MODULE_URL,
  UPDATE_USER_URL,
  DELETE_USER_URL,
  RETIRE_PROJECT_URL,
  CREATE_PROJECT_URL,
  UPDATE_SESSION_URL,
  RETIRE_SESSION_URL,
  GET_PROJECT_USER_ROLES,
  GET_MODULE_TYPES_URL,
  FETCH_COUNTRIES_URL,
  GET_MODULE_BY_ID,
  UPDATE_MODULE_URL,
  GET_CLIENTS_URL,
  GET_CLIENTS_TYPES_URL,
  CREATE_CLIENTS_URL,
  UPDATE_CLIENTS_URL,
  DELETE_CLIENTS_URL,
  FETCH_SECTORS_URL,
  GET_ALL_FRAMEWORK,
} from "../../services/Api";

// Async thunk for fetching project details
export const fetchProjectDetails = createAsyncThunk(
  "fetchProjectDetails",
  async ({ projectId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_PROJECT_DETAILS_URL}?projectId=${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching project details:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error.message ||
          "Error fetching project details"
      );
    }
  }
);

export const fetchCountries = createAsyncThunk(
  "fetchCountries",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${FETCH_COUNTRIES_URL}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching countries:", error);
      return rejectWithValue(
        error.response.data?.title || error.message || "Countries not found"
      );
    }
  }
);

export const fetchSectors = createAsyncThunk(
  "fetchSectors",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${FETCH_SECTORS_URL}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching sectors:", error);
      return rejectWithValue(
        error.response.data?.title || error.message || "Sectors not found"
      );
    }
  }
);

// Async thunk for updating project details
export const updateProjectDetails = createAsyncThunk(
  "updateProjectDetails",
  async ({ projectData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        UPDATE_PROJECT_DETAILS_URL,
        projectData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating project details:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error updating project details"
      );
    }
  }
);

// Async thunk for creating project
export const createProject = createAsyncThunk(
  "createProject",
  async ({ projectData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(CREATE_PROJECT_URL, projectData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error creating project:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error creating project"
      );
    }
  }
);

// Async thunk for creating session
export const createSessionInProject = createAsyncThunk(
  "createSessionInProject",
  async ({ sessionData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(CREATE_SESSION_URL, sessionData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error creating session data:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error creating session data"
      );
    }
  }
);

// Async thunk for creating module
export const createModuleInProject = createAsyncThunk(
  "createModuleInProject",
  async ({ moduleData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(CREATE_MODULE_URL, moduleData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error creating module data:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error creating module data"
      );
    }
  }
);

// Async thunk for updating  module
export const updateModule = createAsyncThunk(
  "updateModule",
  async ({ moduleDataEdited, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(UPDATE_MODULE_URL, moduleDataEdited, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error updating module data:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error updating module data"
      );
    }
  }
);

// Async thunk for fetching users
export const fetchUsers = createAsyncThunk(
  "fetchUsers",
  async ({ projectId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${PROJECT_BASE_URL}${GET_USERS_URL}?projectId=${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching users list:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching users list"
      );
    }
  }
);

// Async thunk for posting New User admin
export const createNewUser = createAsyncThunk(
  "createNewUser",
  async ({ projectId, userData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${PROJECT_BASE_URL}${CREATE_NEW_USER_URL}?projectId=${projectId}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating user:", error);
      return rejectWithValue(
        error.response.data?.message || error?.message || "Error creating user"
      );
    }
  }
);

// Async thunk for updating user
export const updateUser = createAsyncThunk(
  "updateUser",
  async ({ userData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${UPDATE_USER_URL}?id=${userData.id}&roleId=${userData.projectRoleId}&projectId=${userData.projectId}&isApprover=${userData.isApprover}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating user:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error updating user"
      );
    }
  }
);

// Async thunk for deleting user
export const deleteUser = createAsyncThunk(
  "deleteUser",
  async ({ projectId, userId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${PROJECT_BASE_URL}${DELETE_USER_URL}?userId=${userId}&projectId=${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting user:", error);
      return rejectWithValue(
        error.response.data?.message || error?.message || "Error deleting user"
      );
    }
  }
);

// Async thunk for retire project
export const retireProject = createAsyncThunk(
  "retireProject",
  async ({ projectId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${RETIRE_PROJECT_URL}?projectId=${projectId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error retire project:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error retire project"
      );
    }
  }
);

// Async thunk for updating session details
export const updateSessionDetails = createAsyncThunk(
  "updateSessionDetails",
  async ({ name, id, token, projectId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${UPDATE_SESSION_URL}?sessionId=${id}&sessionName=${name}&projectId=${projectId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating session details:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error updating session details"
      );
    }
  }
);

// Async thunk for retiring session details
export const retireSession = createAsyncThunk(
  "retireSession",
  async ({ id, token, projectId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${RETIRE_SESSION_URL}?sessionId=${id}&projectId=${projectId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error retiring session details:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error retiring session details"
      );
    }
  }
);

// Async thunk for fetching ProjectUserRoles
export const fetchProjectUserRoles = createAsyncThunk(
  "fetchProjectUserRoles",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_PROJECT_USER_ROLES}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching roles list:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching roles list"
      );
    }
  }
);

// Async thunk for fetching AllModuleTypes
export const fetchAllModuleTypes = createAsyncThunk(
  "fetchAllModuleTypes",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_MODULE_TYPES_URL}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching module types list:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching module types list"
      );
    }
  }
);

// Async thunk for fetching Module By ID
export const fetchModuleById = createAsyncThunk(
  "fetchModuleById",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_MODULE_BY_ID}?moduleId=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching module types list:", error);
      return rejectWithValue(
        error.response.data?.title ||
          error?.message ||
          "Error fetching module types list"
      );
    }
  }
);

export const fetchClientsDetails = createAsyncThunk(
  "fetchClientDetails",
  async ({ projectId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_CLIENTS_URL}?projectId=${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(
        error.response.data?.title || error.message || "Error fetching data"
      );
    }
  }
);
export const fetchClientTypes = createAsyncThunk(
  "fetchClientTypes",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_CLIENTS_TYPES_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching types:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error fetching types"
      );
    }
  }
);
export const createClient = createAsyncThunk(
  "createClient",
  async ({ clientData, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${CREATE_CLIENTS_URL}`, clientData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error creating Client:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error creating Client"
      );
    }
  }
);
export const updateClient = createAsyncThunk(
  "dmaSources/updateClient",
  async ({ clientId, clientData, projectId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${UPDATE_CLIENTS_URL}/${clientId}/${projectId}`,
        clientData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating Client:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error updating Client"
      );
    }
  }
);
export const deleteClient = createAsyncThunk(
  "deleteClient",
  async ({ clientId, projectId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${DELETE_CLIENTS_URL}/${clientId}/${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting Client:", error);
      return rejectWithValue(
        error.response.data?.message || error?.message || "Error deleting Client"
      );
    }
  }
);


export const getAllSessionFrameWork = createAsyncThunk(
  "getAllSessionFrameWork",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_ALL_FRAMEWORK}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching frameworks:", error);
      return rejectWithValue(
        error.response.data?.title || error.message || "frameworks not found"
      );
    }
  }
);

// Slice definition
const projectDetailsSlice = createSlice({
  name: "projectDetails",
  initialState: {
    isLoading: false,
    isLoadingSectors: false,
    data: null,
    projectUserRoles: null,
    moduleTypes: null,
    sessionFramework: null,
    updatedData: null,
    isError: false,
    errorMessage: "",
    updateSuccess: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectDetails.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchProjectDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(fetchProjectDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateProjectDetails.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateProjectDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.updatedData = action.payload;
        state.isError = false;
      })
      .addCase(updateProjectDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createProject.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(createProject.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createSessionInProject.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(createSessionInProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(createSessionInProject.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createModuleInProject.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(createModuleInProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(createModuleInProject.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fetchUsers.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createNewUser.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(createNewUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(createNewUser.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(retireProject.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(retireProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(retireProject.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateSessionDetails.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateSessionDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(updateSessionDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(retireSession.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(retireSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(retireSession.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fetchProjectUserRoles.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchProjectUserRoles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.projectUserRoles = action.payload;
        state.isError = false;
      })
      .addCase(fetchProjectUserRoles.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fetchAllModuleTypes.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchAllModuleTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.moduleTypes = action.payload;
        state.isError = false;
      })
      .addCase(fetchAllModuleTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fetchModuleById.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchModuleById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.moduleTypes = action.payload;
        state.isError = false;
      })
      .addCase(fetchModuleById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fetchCountries.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.moduleTypes = action.payload;
        state.isError = false;
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fetchSectors.pending, (state) => {
        state.isLoadingSectors = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchSectors.fulfilled, (state, action) => {
        state.isLoadingSectors = false;
        state.moduleTypes = action.payload;
        state.isError = false;
      })
      .addCase(fetchSectors.rejected, (state, action) => {
        state.isLoadingSectors = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateModule.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateModule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.moduleTypes = action.payload;
        state.isError = false;
      })
      .addCase(updateModule.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fetchClientsDetails.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchClientsDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(fetchClientsDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fetchClientTypes.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchClientTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.types = action.payload;
      })
      .addCase(fetchClientTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createClient.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(createClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(createClient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateClient.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateClient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteClient.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(deleteClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(deleteClient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllSessionFrameWork.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getAllSessionFrameWork.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sessionFramework = action.payload;
      })
      .addCase(getAllSessionFrameWork.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      });
  },
});

export default projectDetailsSlice.reducer;

import React, { useState } from "react";
import Stepper from "../../components/stepper/Stepper";
import Header from "../../components/headers/Header";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import {
  Stake,
  Beat,
  Iro,
  IroLite,
  ReportingLite,
  Reporting,
  BeatLite,
  StakeLite,
  StakeHolder,
  StakeOut,
} from "../../constants/icons/Icons";
const DeepDive = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { moduleId } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const projectId = location.state?.projectId;
  const token = location.state?.token;
  const projectName = location.state?.projectName;
  const sessionData = location.state?.sessionData;
  const handleStepChange = (index) => {
    setActiveIndex(index);
  };

  const getIcon = (index, activeIcon, inactiveIcon) => {
    return activeIndex === index ? activeIcon : inactiveIcon;
  };
  const project = sessionData?.find((project) =>
    project.modules.some((module) => module.id === parseInt(moduleId))
  );
  const stepItems = [
    { label: t("All projects"), link: "/" },
    { label: projectName, link: `/project-home/${projectId}`, token: token },
    { label: project?.name, link: `/project-home/${projectId}`, token: token },
    {
      label: project?.framework.name,
      link: `/project-home/${projectId}`,
      token: token,
    },
    { label: t("DD"), link: "#" },
  ];
  return (
    <div>
      <Header />
      <Stepper onChange={handleStepChange} items={stepItems}>
        <div
          icon={getIcon(0, <Beat />, <BeatLite />)}
          text={"Sample Text"}
        ></div>
        <div
          icon={getIcon(1, <Stake />, <StakeLite />)}
          text={"Sample Text"}
        ></div>
      </Stepper>
    </div>
  );
};

export default DeepDive;

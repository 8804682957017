import React, { useState, useEffect, useMemo } from "react";
import "./ClientOrg.scss";
import Header from "../../components/headers/Header";
import {
  fetchClientsDetails,
  fetchClientTypes,
  createClient,
  updateClient,
  deleteClient,
} from "../../features/slices/ProjectScreens";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { TableCell, Box, IconButton } from "@mui/material";
import { Edit, Delete } from "../../constants/icons/Icons";
import Subheader from "../../components/subheader/Subheader";
import languageData from "../../constants/languages/en/translations.json";
import ClientTable from "../../components/clientTable/ClientTable";
import { useDispatch, useSelector } from "react-redux";
import ClientModal from "../../components/modals/clientModal/ClientModal";
import DeleteModal from "../../components/modals/deleteModal/DeleteModal";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ErrorModal from "../../components/modals/error/errorModal";

const ClientOrg = () => {
  const isAdmin = true;
  const location = useLocation();
  const { projectId } = useParams();
  const [types, setTypes] = useState([]);
  const { t } = useTranslation();
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [clientMode, setClientMode] = useState("add");
  const [clientModalTitle, setClientModalTitle] = useState("");
  const [selectedClientRow, setSelectedClientRow] = useState(null);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updateClientList, setUpdateClientList] = useState(0);
  const [searchClientQuery, setSearchClientQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [clientData, setClientData] = useState([]);
  const dispatch = useDispatch();
  const token = location.state?.token;
  const projectName = location.state?.projectName;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [clientResponseData, typesResponse] = await Promise.all([
          dispatch(fetchClientsDetails({ projectId, token })).unwrap(),
          dispatch(fetchClientTypes({ token })).unwrap(),
        ]);

        if (typesResponse.success) {
          setTypes(typesResponse.data);
        }

        if (Array.isArray(clientResponseData.data)) {
          const clientsData = clientResponseData.data.map((item) => ({
            ...item,
            typeName: item.clientOrganisationType?.name || "Unknown",
          }));
          setClientData(clientsData);
        } else {
          setClientData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, projectId, token, updateClientList]);

  const projectScreenState = useSelector((state) => state.projectDetails);
  const { isError, errorMessage } = projectScreenState;

  const handleEdit = (row) => {
    setSelectedClientRow(row);
    setClientMode("edit");
    setClientModalTitle(t("EditClient"));
    setIsClientModalOpen(true);
  };

  const handleOpenModal = (mode) => {
    setClientMode(mode);
    setSelectedClientRow(null);
    setClientModalTitle(mode === "add" ? t("NewClient") : t("EditClient"));
    setIsClientModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsClientModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchClientQuery(String(event.target.value));
  };

  const openDeleteModal = (row) => {
    setClientToDelete(row);
    setIsDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    if (!clientToDelete) return;
    setIsLoading(true);

    try {
      const response = await dispatch(
        deleteClient({ clientId: clientToDelete.id, projectId, token })
      ).unwrap();

      if (!response.error) {
        setClientData((prevData) =>
          prevData.filter((client) => client.id !== clientToDelete.id)
        );
        setIsDeleteModalOpen(false);
      } else {
        setIsDeleteModalOpen(false);
        console.error("Error deleting client:", response.error);
      }
    } catch (error) {
      setIsDeleteModalOpen(false);
      console.error("Error deleting client:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClientEditUpdateClick = async (clientData) => {
    setIsLoading(true);
    try {
      if (clientMode === "add") {
        const payload = {
          name: clientData.name,
          email: clientData.email,
          typeId: clientData.typeId,
          projectId: parseInt(projectId),
        };
        const response = await dispatch(
          createClient({ clientData: payload, token })
        ).unwrap();

        if (response) {
          setClientData((prevData) => [...prevData, response]);
          setUpdateClientList((prev) => prev + 1);
          handleCloseModal();
          dispatch(fetchClientsDetails({ projectId, token })).unwrap();
        } else {
          console.error("Error creating client:", response.error);
        }
      } else if (clientMode === "edit" && selectedClientRow) {
        const payload = {
          name: clientData.name,
          email: clientData.email,
          typeId: clientData.typeId,
        };
        const response = await dispatch(
          updateClient({
            clientId: selectedClientRow.id,
            projectId: parseInt(projectId),
            clientData: payload,
            token,
          })
        ).unwrap();

        if (response) {
          setClientData((prevData) =>
            prevData.map((client) =>
              client.id === selectedClientRow.id ? response : client
            )
          );
          setUpdateClientList((prev) => prev + 1);
          handleCloseModal();
          dispatch(fetchClientsDetails({ projectId, token })).unwrap();
        } else {
          console.error("Error updating client:", response.error);
        }
      }
    } catch (error) {
      console.error("Error creating/updating client:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const columnsToDisplay = useMemo(
    () => [
      { colName: "name", label: t("Name"), showSorting: true },
      { colName: "typeName", label: t("Type"), showSorting: true },
      { colName: "email", label: t("Email"), showSorting: true },
      { colName: "Action", label: t("Action"), showSorting: false },
    ],
    [t]
  );

  const generateRowKey = (row) => row.id;

  const renderClientsTableCell = (colName, value, row, index) => {
    if (colName === "Action") {
      return (
        <TableCell
          key="actions"
          style={{ textAlign: "right", paddingRight: "4%" }}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton disabled={isLoading} onClick={() => handleEdit(row)}>
              <Edit />
            </IconButton>
            <IconButton
              disabled={isLoading}
              onClick={() => openDeleteModal(row)}
            >
              <Delete />
            </IconButton>
          </Box>
        </TableCell>
      );
    }
    if (colName === "typeName") {
      return (
        <TableCell key={colName}>{row.clientOrganisationType?.name}</TableCell>
      );
    }

    return <TableCell key={index}>{value}</TableCell>;
  };

  const filteredClientsData = useMemo(() => {
    if (!clientData) return [];
    const searchQueryLower = searchClientQuery.trim().toLowerCase();
    if (searchQueryLower === "") {
      return clientData;
    }
    const filteredData = clientData.filter((item) =>
      item.name?.toLowerCase().includes(searchQueryLower)
    );
    return filteredData;
  }, [clientData, searchClientQuery]);

  const handleError = () => {
    navigate("/");
  };

  if (isError) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={errorMessage}
          handleButtonClick={handleError}
          deleteButtonLabel={t("Reload")}
        />
      </div>
    );
  }

  return (
    <div className="all-client-container">
      {isLoading && (
        <MotifProgressLoader
          className="loader"
          show
          variant="default"
          data-testid="loading-spinner"
        />
      )}
      <Header />
      <div className="client-org-container">
        <Breadcrumbs
          items={[
            { label: t("AllProjects"), link: "/" },
            {
              label: projectName,
              link: `/project-home/${projectId}`,
              token: token,
            },
            {
              label: t("ClientOrganisation"),
              link: `/client-org/${projectId}`,
            },
          ]}
        />
        <div className="client-org-subheader">
          <Subheader
            isAdmin={isAdmin}
            page={languageData.ClientOrgPage}
            title={t("ClientOrganisation")}
            onChangeSearchInput={handleSearch}
            handleOpenModal={() => handleOpenModal("add")}
            disableButton={isLoading}
            CreateNewEN={t("CreateNew")}
            ClientOrgAccessTitleEN={t("ClientOrgPage")}
          />
          {isClientModalOpen && (
            <ClientModal
              onClose={handleCloseModal}
              setName={clientModalTitle}
              handleSourceValueClick={handleClientEditUpdateClick}
              modalMode={clientMode}
              nameLabel={t("Name")}
              typeLabel={t("Type")}
              emailLabel={t("Email")}
              cancelButtonLabel={t("Cancel")}
              saveButtonLabel={t("DoneButton")}
              updateButtonLabel={t("Update")}
              types={types}
              selectedRow={selectedClientRow}
              pageName="Client"
            />
          )}
        </div>
        <ClientTable
          columns={columnsToDisplay}
          data={filteredClientsData}
          itemsPerPage={5}
          generateRowKey={generateRowKey}
          renderTableCell={(colName, value, row, index) => {
            const cellValue = colName
              .split(".")
              .reduce((acc, part) => acc?.[part], row);
            return renderClientsTableCell(colName, cellValue, row, index);
          }}
        />

        {isDeleteModalOpen && (
          <DeleteModal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            setName={t("DeleteLabel")}
            labelText={t("DeletConfirmationSources")}
            onCancel={() => setIsDeleteModalOpen(false)}
            handleDeleteClick={handleDelete}
            cancelButtonLabel={t("Cancel")}
            deleteButtonLabel={t("DeleteLabel")}
          />
        )}
      </div>
    </div>
  );
};

export default ClientOrg;

import React, { useState, useEffect, useMemo, useCallback } from "react";
import WaterfallChart from "../../../components/charts/WaterfallChart";
import { useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import {
  getIdentifiedImpacts,
  getIdentifiedRiskAndOpportunity,
  getMaterialIdentifiedImpacts,
  getMaterialIdentifiedRiskAndOpportunity,
} from "../../../features/slices/DMAReporting";
import { MotifButton, MotifProgressLoader } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import "./DMAReporting.scss"; // Import the stylesheet

const DMAReporting = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { moduleId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = location.state?.token;
  const { t } = useTranslation();

  // State for each data type
  const [impactData, setImpactData] = useState([]);
  const [materialImpactData, setMaterialImpactData] = useState([]);
  const [riskOpportunityData, setRiskOpportunityData] = useState([]);
  const [materialRiskOpportunityData, setMaterialRiskOpportunityData] =
    useState([]);
  const [error, setError] = useState(null);

  // Memoize the fetchData function
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const results = await Promise.all([
        dispatch(getIdentifiedImpacts({ moduleId, token })),
        dispatch(getMaterialIdentifiedImpacts({ moduleId, token })),
        dispatch(getIdentifiedRiskAndOpportunity({ moduleId, token })),
        dispatch(getMaterialIdentifiedRiskAndOpportunity({ moduleId, token })),
      ]);

      // Process and set data from each response
      const [
        impactResponse,
        materialImpactsResponse,
        riskOpportunityResponse,
        materialRiskOpportunityResponse,
      ] = results;

      setImpactData(
        impactResponse?.payload?.data?.moduleSustainabilityMatters || []
      );
      setMaterialImpactData(materialImpactsResponse?.payload?.data || []);
      setRiskOpportunityData(
        riskOpportunityResponse?.payload?.data?.moduleSustainabilityMatters ||
          []
      );
      setMaterialRiskOpportunityData(
        materialRiskOpportunityResponse?.payload?.data || []
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, moduleId, token]);

  // Use useEffect to fetch data on component mount
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Memoize the chart data
  const memoizedImpactData = useMemo(() => impactData, [impactData]);
  const memoizedMaterialImpactData = useMemo(
    () => materialImpactData,
    [materialImpactData]
  );
  const memoizedRiskOpportunityData = useMemo(
    () => riskOpportunityData,
    [riskOpportunityData]
  );
  const memoizedMaterialRiskOpportunityData = useMemo(
    () => materialRiskOpportunityData,
    [materialRiskOpportunityData]
  );

  const ChartRow = useCallback(
    ({ title1, title2, data1, data2 }) => (
      <div className="motif-row ">
        <div className="motif-col-6 motif-col-xl-6 chart-container">
          <h3 className="chart-title">{title1}</h3>
          <WaterfallChart data={data1} />
        </div>
        <div className="motif-col-6 motif-col-xl-6 chart-container">
          <h3 className="chart-title">{title2}</h3>
          <WaterfallChart data={data2} />
        </div>
      </div>
    ),
    []
  );

  return (
    <>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      {error && <div className="error-message">{error}</div>}
      {!isLoading && !error && (
        <div>
          {/* <div className="button-container">
            <MotifButton className="big-button">Button 1</MotifButton>
            <MotifButton className="big-button">Button 2</MotifButton>
            <MotifButton className="big-button">Button 3</MotifButton>
            <MotifButton className="big-button">Button 4</MotifButton>
          </div> */}
          <ChartRow
            title1={t("Identified Impacts")}
            title2={t("Material Identified Impacts")}
            data1={memoizedImpactData}
            data2={memoizedMaterialImpactData}
          />
          <ChartRow
            title1={t("Identified Risk and Opportunity")}
            title2={t("Material Identified Risk and Opportunity")}
            data1={memoizedRiskOpportunityData}
            data2={memoizedMaterialRiskOpportunityData}
          />
        </div>
      )}
    </>
  );
};

export default DMAReporting;

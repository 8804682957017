import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import {
  createNewSustainabilityMatters,
  deleteSustainabilityMatters,
  fetchEsrs,
  fetchEsrsAr16,
  getAllSustainabilityMatters,
  updateSustainabilityMatters,
} from "../../../../features/slices/SustainabilityMatters";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import TreeView from "../../../../components/treeView/TreeView";
import SustainabilityMattersModal from "../../../../components/modals/dmaModals/SustainabilityMattersModal";
import DeleteModal from "../../../../components/modals/deleteModal/DeleteModal";
import { useTranslation } from "react-i18next";
import SunburstChart from "../../../../components/charts/SunburstChart";

const Demo = () => {
  const [modal, setModal] = useState({ isOpen: false, title: "", mode: "add" });
  const [options, setOptions] = useState({
    parent: [],
    esrs: [],
    esrsar16: [],
  });
  const [filteredUpdateData, setFilteredUpdateData] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updateItemID, setUpdateItemID] = useState(null);
  const [deleteItemID, setDeleteItemID] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // Local isLoading state
  const { moduleId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = location.state?.token;
  const { t } = useTranslation();
  const [smData, setSmData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading to true before fetching data
      try {
        const [smResponse, esrsResponse, esrsar16Response] =
          await Promise.all([
            dispatch(getAllSustainabilityMatters({ moduleId, token })),
            dispatch(fetchEsrs({ token, moduleId })),
            dispatch(fetchEsrsAr16({ token })),
          ]);

        setSmData(smResponse.payload.data || []);
        setOptions({
          esrs:
            esrsResponse.payload.data.map((type) => ({
              value: type.id,
              label: type.name,
            })) || [],
          esrsar16:
            esrsar16Response.payload.data.map((type) => ({
              value: type.id,
              label: type.name,
            })) || [],
      
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Set loading to false after fetching data
      }
    };
    fetchData();
  }, [dispatch, moduleId, updateTrigger, token]);

  const sustainabilityMattersState = useSelector(
    (state) => state.dmaSustainabilityMatters
  );

  const handleOpenModal = (mode, itemId) => {
    setModal({
      isOpen: true,
      mode,
      title:
        mode === "add"
          ? t("newSustainabilityMatters")
          : t("editSustainabilityMatters"),
    });
  };

  const handleCloseModal = () => {
    setModal((prev) => ({ ...prev, isOpen: false }));
  };

  const findItemById = (data, id) => {
    for (let item of data) {
      if (item.id === id) return item;
      if (item.children) {
        const result = findItemById(item.children, id);
        if (result) return result;
      }
    }
    return null;
  };

  const handleEditClick = (id) => {
    handleOpenModal("edit", id);
    setFilteredUpdateData(findItemById(smData, id));
    setUpdateItemID(id);
  };

  const handleDeleteClick = (id) => {
    setIsDeleteModalOpen(true);
    setDeleteItemID(id);
  };

  const handleDeleteCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      await dispatch(
        deleteSustainabilityMatters({
          sustainabilityMattersId: deleteItemID,
          moduleId,
          token,
        })
      ).unwrap();
      handleDeleteCloseModal();
      setUpdateTrigger((prev) => prev + 1);
    } catch (error) {
      console.error("Deletion failed:", error);
      alert("Failed to delete sustainability matter: " + error);
    }
  };

  const createNewSustainabilityMattersApi = async (data) => {
    if (data) {
      try {
        await dispatch(
          createNewSustainabilityMatters({
            moduleId,
            sustainabilityMattersData: data,
            token,
          })
        ).unwrap();
        setUpdateTrigger((prev) => prev + 1);
        handleCloseModal();
      } catch (error) {
        console.error("Creation failed:", error);
        alert("Failed to create sustainability matters: " + error);
      }
    } else {
      alert("Please fill in all required fields");
    }
  };

  const updateSustainabilityMattersApi = async (data) => {
    if (data) {
      try {
        const response = await dispatch(
          updateSustainabilityMatters({
            sustainabilityMattersId: updateItemID,
            sustainabilityMattersData: data,
            moduleId,
            token,
          })
        ).unwrap();
        if (response.success) {
          handleCloseModal();
          setUpdateTrigger((prev) => prev + 1);
        }
      } catch (error) {
        console.error("Update failed:", error);
        alert("Failed to update sustainability matters: " + error);
      }
    } else {
      alert("Please fill in all required fields");
    }
  };

  const transformedItems =
    smData.map((item) => ({
      id: item.id,
      itemId: item.id,
      label: item.label || item.name,
      children: item.children || [],
    })) || [];

  const transformDataToSunburst = (data) => {
    const transformNode = (node) => ({
      name:
        node.label ||
        node.esrsaR16?.name ||
        node.frameworkReference?.name ||
        "Unknown",
      value: 1,
      children: node.children ? node.children.map(transformNode) : [],
    });

    return {
      name: "root",
      children: data.map(transformNode),
    };
  };

  const chartData = transformDataToSunburst(smData);

  return (
    <>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      <div className="motif-row">
        <div className="motif-col-1 motif-col-lg-6">
          <div className="sm-file-explorer">
            <TreeView
              items={transformedItems}
              pageName="SM"
              defaultExpandedItems={[]}
              defaultSelectedItems="1"
              onEditClick={handleEditClick}
              onDeleteClick={handleDeleteClick}
            />
          </div>
        </div>
        <div className="motif-col-1 motif-col-lg-6">
          <div style={{ width: "100%", height: "80vh" }}>
            <SunburstChart data={chartData} width={600} height={600} />
          </div>
        </div>
      </div>

      {modal.isOpen && (
        <SustainabilityMattersModal
          onClose={handleCloseModal}
          modalTitle={modal.title}
          modalMode={modal.mode}
          topicNameLabel={t("TopicName")}
          parentLabel={t("Parent")}
          esrsLabel={t("ESRS")}
          esrsAr16Label={t("ESRS_AR16")}
          esgLabel={t("ESG")}
          enterTopicNameLabel={t("EnterTopicName")}
          selectParentLabel={t("SelectParent")}
          selectEsrsLabel={t("SelectESRS")}
          selectEsrsAr16Label={t("SelectESRS")}
          DescriptionLabel={t("Description")}
          saveButtonLabel={t("addSustainabilityMatters")}
          updateButtonLabel={t("Update")}
          cancelButtonLabel={t("Cancel")}
          esrsOptions={options.esrs}
          esrsar16Options={options.esrsar16}
          parentOptions={options.parent}
          handleDoneClick={
            modal.mode === "add"
              ? createNewSustainabilityMattersApi
              : updateSustainabilityMattersApi
          }
          filteredUpdateData={filteredUpdateData}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={handleDeleteCloseModal}
          setName={t("DeleteLabel")}
          labelText={t("DeletConfirmationSources")}
          onCancel={handleDeleteCloseModal}
          handleDeleteClick={handleDelete}
          cancelButtonLabel={t("Cancel")}
          deleteButtonLabel={t("DeleteLabel")}
        />
      )}
    </>
  );
};

export default Demo;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_UTC_COUNT, GET_MODULE_COUNT } from "../../services/Api";


export const fetchUnderstandingTheContextCount = createAsyncThunk(
  "fetchUnderstandingTheContextCount",
  async ({ moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_UTC_COUNT}/${moduleId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching Understanding The Context Count:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error fetching Understanding The Context Count"
      );
    }
  }
);

export const fetchIROCount = createAsyncThunk(
  "fetchIROCount",
  async ({ moduleId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_MODULE_COUNT}/${moduleId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching IRO Count:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error fetching IRO Count"
      );
    }
  }
);

const dmaOverviewSlice = createSlice({
  name: "dmaOverview",
  initialState: {
    isLoading: false,
    contextData: null,  
    iroData: null,     
    isError: false,
    errorMessage: ""
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUnderstandingTheContextCount.pending, (state) => {
      state.isLoading = true;
      state.errorMessage = "";
    });
    builder.addCase(fetchUnderstandingTheContextCount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.contextData = action.payload;  
    });
    builder.addCase(fetchUnderstandingTheContextCount.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    });
    builder.addCase(fetchIROCount.pending, (state) => {
      state.isLoading = true;
      state.errorMessage = "";
    });
    builder.addCase(fetchIROCount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.iroData = action.payload; 
    });
    builder.addCase(fetchIROCount.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    });
  },
});

export default dmaOverviewSlice.reducer;
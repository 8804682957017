import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import ContextSelection from "../../../../components/contextSelection/ContextSelection";
import ClientTable from "../../../../components/clientTable/ClientTable";
import CardDMS from "../../../../components/cardDMS/CardDMS";
import { Edit } from "../../../../constants/icons/Icons";
import { TableCell, IconButton, Box } from "@mui/material";
import SourceValue from "../../../../components/modals/dmaModals/SourcesValue";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSources,
  fetchSourcesTypes,
  createSources,
  updateSources,
  deleteSources,
} from "../../../../features/slices/DMASources";
import { MotifProgressLoader, MotifTruncate } from "@ey-xd/motif-react";
import Pagination from "../../../../components/paginationForCards/Pagination";
import TopBar from "../Topbar";
import ErrorModal from "../../../../components/modals/error/errorModal";
import DeleteModal from "../../../../components/modals/deleteModal/DeleteModal";
import "./Sources.scss";
import { useTranslation } from "react-i18next";
const Sources = () => {
  const [isSourcesTableView, setIsSourcesTableView] = useState(true);
  const [isSourcesModalOpen, setIsSourcesModalOpen] = useState(false);
  const [modalSourcesMode, setModalSourcesMode] = useState("add");
  const [modalSourcesTitle, setModalSourcesTitle] = useState("");
  const [selectedSourcesRow, setSelectedSourcesRow] = useState(null);
  const [currentSourcesPage, setCurrentSourcesPage] = useState(1);
  const [searchSourcesQuery, setSearchSourcesQuery] = useState("");
  const [types, setTypes] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [sourceToDelete, setSourceToDelete] = useState(null);
  const [updateSourcesTrigger, setUpdateSourcesTrigger] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { moduleId } = useParams();
  const itemsPerPage = 10;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = location.state?.token;
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [sourcesResponse, typesResponse] = await Promise.all([
          dispatch(fetchSources({ moduleId, token })).unwrap(),
          dispatch(fetchSourcesTypes({ token })).unwrap(),
        ]);

        if (typesResponse.success) {
          setTypes(typesResponse.data);
        }

        if (Array.isArray(sourcesResponse.data)) {
          const sourcesData = sourcesResponse.data.map((item) => ({
            ...item,
            typeName: item.sourceType?.name || "Unknown",
          }));
          setSourcesData(sourcesData);
        } else {
          setSourcesData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [dispatch, moduleId, token, updateSourcesTrigger]);

  const {
    data: responseData,
    isError,
    errorMessage,
  } = useSelector((state) => state.dmaSources);

  useEffect(() => {
    if (Array.isArray(responseData?.data)) {
      const sourcesData = responseData.data.map((item) => ({
        ...item,
        typeName: item.sourceType?.name || "Unknown",
      }));
      setSourcesData(sourcesData);
    } else {
      console.error("Unexpected data format:", responseData?.data);
      setSourcesData([]);
    }
  }, [responseData]);

  const [sourcesData, setSourcesData] = useState([]);

  const handleEdit = (row) => {
    setSelectedSourcesRow(row);
    setModalSourcesMode("edit");
    setModalSourcesTitle("Edit Sources");
    setIsSourcesModalOpen(true);
    setSourceToDelete(row);
  };

  const handleDelete = async () => {
    if (!sourceToDelete) return;
    setIsLoading(true);

    try {
      const response = dispatch(
        deleteSources({ sourceId: sourceToDelete.id, moduleId, token })
      );
      if (!response.error) {
        setSourcesData((prevData) => {
          if (Array.isArray(prevData)) {
            return prevData.filter((source) => source.id !== sourceToDelete.id);
          } else if (prevData === null || prevData === undefined) {
            return [];
          } else {
            console.error("Unexpected data format:", prevData);
            return [];
          }
        });
        setUpdateSourcesTrigger((prev) => prev + 1);
        dispatch(fetchSources({ moduleId, token }));
      } else {
        console.error("Error deleting Source:", response.error);
      }
    } catch (error) {
      console.error("Error deleting Source:", error);
    } finally {
      setIsLoading(false);
      setIsDeleteModalOpen(false);
      handleCloseModal();
    }
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleSourceValueClick = async (sourcesData) => {
    setIsLoading(true);
    try {
      if (modalSourcesMode === "add") {
        dispatch(
          createSources({ moduleId, sourceData: sourcesData, token })
        ).then((response) => {
          if (response.payload) {
            setSourcesData((prevData) => {
              if (Array.isArray(prevData)) {
                return [...prevData, response.payload];
              } else {
                return [response.payload];
              }
            });
            setUpdateSourcesTrigger((prev) => prev + 1);
            handleCloseModal();
            dispatch(fetchSources({ moduleId, token }));
          } else {
            console.error("Error creating source:", response.error);
          }
        });
      } else if (modalSourcesMode === "edit" && selectedSourcesRow) {
        dispatch(
          updateSources({
            sourceId: selectedSourcesRow.id,
            sourceData: sourcesData,
            moduleId,
            token,
          })
        ).then((response) => {
          if (response.payload) {
            setSourcesData((prevData) => {
              if (Array.isArray(prevData)) {
                return prevData.map((source) =>
                  source.id === response.payload.id ? response.payload : source
                );
              } else {
                return [response.payload];
              }
            });
            setUpdateSourcesTrigger((prev) => prev + 1);
            handleCloseModal();
            dispatch(fetchSources({ moduleId, token }));
          } else {
            console.error("Error updating source:", response.error);
          }
        });
      }
    } catch (error) {
      console.error("Error creating/updating source:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderSourcesTableCell = (colName, value, row, index) => {
    if (colName === "Action") {
      return (
        <TableCell
          key="actions"
          style={{ textAlign: "right", paddingRight: "3.85%" }}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton disabled={isLoading} onClick={() => handleEdit(row)}>
              <Edit />
            </IconButton>
          </Box>
        </TableCell>
      );
    }
    if (colName === "typeName") {
      return <TableCell key={index}>{row.typeName}</TableCell>;
    }
    if (colName === "description") {
      return (
        <TableCell key={index} className="truncate">
          <MotifTruncate>{value}</MotifTruncate>
        </TableCell>
      );
    }
    return <TableCell key={index}>{value}</TableCell>;
  };

  const generateRowKey = (row) => row.id;

  const handleOpenModal = (mode) => {
    setModalSourcesMode(mode);
    setSelectedSourcesRow(null);
    if (mode === "add") {
      setModalSourcesTitle("New sources");
    } else if (mode === "edit") {
      setModalSourcesTitle("Edit sources");
    }
    setIsSourcesModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsSourcesModalOpen(false);
  };

  const handlePageChange = (page) => {
    setCurrentSourcesPage(page);
  };

  const handleSearch = (event) => {
    setSearchSourcesQuery(String(event));
  };

  const handleError = () => {
    navigate("/");
  };

  if (isError) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={errorMessage}
          handleButtonClick={handleError}
          deleteButtonLabel={t("Reload")}
        />
      </div>
    );
  }

  const getSourcesFilteredData = () => {
    return Array.isArray(sourcesData)
      ? sourcesData.filter((item) =>
          item.name?.toLowerCase().includes(searchSourcesQuery.toLowerCase())
        )
      : [];
  };

  const paginatedData = getSourcesFilteredData().slice(
    (currentSourcesPage - 1) * itemsPerPage,
    currentSourcesPage * itemsPerPage
  );

  const columnsSources = [
    { colName: "name", label: t("Name"), showSorting: true },
    { colName: "typeName", label: "Type", showSorting: true },
    {
      colName: "description",
      label: t("Description"),
      showSorting: true,
    },
    { colName: "Action", label: "Action", showSorting: false },
  ];

  const totalPages = Math.ceil(getSourcesFilteredData().length / itemsPerPage);

  return (
    <div>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          show
          variant="default"
        />
      )}
      <ContextSelection
        addButtonText="Add New"
        onClickNewProject={() => handleOpenModal("add")}
        onChangeSearchInput={handleSearch}
      />
      <TopBar
        isTableView={isSourcesTableView}
        totalResults={sourcesData.length}
        filteredResults={getSourcesFilteredData().length}
        onTableViewClick={() => setIsSourcesTableView(true)}
        onCardViewClick={() => setIsSourcesTableView(false)}
      />
      <div className="gl-card-table-switch">
        {isSourcesTableView ? (
          <ClientTable
            columns={columnsSources}
            data={getSourcesFilteredData()}
            itemsPerPage={5}
            renderTableCell={(colName, value, row, index) => {
              const cellValue = colName
                .split(".")
                .reduce((acc, part) => acc?.[part], row);
              return renderSourcesTableCell(colName, cellValue, row, index);
            }}
            generateRowKey={generateRowKey}
          />
        ) : (
          <div className="card-container">
            {paginatedData.map((item) => (
              <CardDMS
                key={item.id}
                header={item.name}
                pageName="Sources"
                stakeHolderType={item.sourceType.name}
                descriptionHeaderText={item.description}
                handleEdit={() => handleEdit(item)}
              />
            ))}
          </div>
        )}
      </div>
      {!isSourcesTableView && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentSourcesPage}
          onPageChange={handlePageChange}
        />
      )}
      {isSourcesModalOpen && (
        <SourceValue
          onClose={handleCloseModal}
          setName={modalSourcesTitle}
          handleStatusChange={() => {}}
          handleSourceValueClick={handleSourceValueClick}
          modalMode={modalSourcesMode}
          NameLabel={t("Name")}
          TypeLabel={t("Type")}
          statusActiveLabel={t("Upstream")}
          statusInactiveLabel={t("DownStream")}
          DescriptionLabel={t("Description")}
          cancelButtonLabel={t("Cancel")}
          saveButtonLabel={t("AddToList")}
          updateButtonLabel={t("Update")}
          types={types}
          isDisabled={isLoading}
          pageName="Sources"
          selectedRow={selectedSourcesRow}
          onDeleteClick={openDeleteModal}
          DeleteLabel={t("DeleteLabel")}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          setName={t("DeleteLabel")}
          labelText={t("DeletConfirmationSources")}
          onCancel={() => setIsDeleteModalOpen(false)}
          handleDeleteClick={handleDelete}
          cancelButtonLabel={t("Cancel")}
          deleteButtonLabel={t("DeleteLabel")}
        />
      )}
    </div>
  );
};

export default Sources;

import React, { useState, useEffect, useCallback } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifMessage,
  MotifSelect,
  MotifOption,
  MotifToggleSwitch,
  MotifErrorMessage,
} from "@ey-xd/motif-react";
import "./AddUser.scss";
import PropTypes from "prop-types";
import { FETCH_AD_USERS } from "../../../services/Api";
import MultiPeoplePicker from "../../peoplePicker/MultiPeoplePicker";
import { useMsal } from "@azure/msal-react";

const AddUser = ({
  isOpen,
  onClose,
  page,
  TitleUserManagementEN,
  UserNameEN,
  selectedUserId,
  RoleEN,
  SelectRoleEN,
  UserEmailEN,
  CancelButtonEN,
  DoneButtonEN,
  UserManagementPageEN,
  AddModulePopupEN,
  EditModulePopupEN,
  NewModuleTitleEN,
  SessionNameEN,
  AddSessionPopupEN,
  EditSessionPopupEN,
  SelectedsessionName,
  SelectedsessionFramework,
  ModuleEN,
  SelectModuleEN,
  NewSessionTitleEN,
  SessionNameContentEN,
  SessionFrameworkEN,
  SelectSessionFrameworkEN,
  ESRSEN,
  selectedRole,
  sessionId,
  projectId,
  callApi,
  TitleUpdateUserManagementEN,
  UpdateUserManagementPageEN,
  selectedUsername,
  selectedEmail,
  UpdateButtonEN,
  ConfirmationPopup,
  RetireSessionPopup,
  ConfirmationTitle,
  ConfirmationEN,
  ConfirmationDeleteMsgEN,
  userRoles,
  clientReadyLabel,
  nameLabel,
  enterNamePlaceholder,
  clientReadyPlaceholder,
  yesLabel,
  noLabel,
  moduleTypes = [],
  moduleNameUpdated,
  isClientReadyUpdated,
  moduleTypeId,
  moduleIdToUpdateApi,
  handleDeleteSession,
  DeleteButton,
  PreparerLabel,
  ApproverLabel,
  approverValue,
  sessionFrameWorkArray,
}) => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState(selectedEmail || "");
  const [isApprover, setIsApprover] = useState(approverValue || false);
  const [userFramework, setUserFramework] = useState(selectedRole || "");
  const [module, setModule] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [sessionFramework, setSessionFramework] = useState("");
  const { instance } = useMsal();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [clientReady, setClientReady] = useState("");
  const [moduleName, setModuleName] = useState("");
  const [sessionNameValidation, setSessionNameValidation] = useState(null);
  const [sessionFrameWorkValidation, setSessionFrameWorkValidation] =
    useState(null);
  const [moduleValidation, setModuleValidation] = useState(null);
  const [moduleNameValidation, setModuleNameValidation] = useState(null);
  const [isClientReadyValidation, setIsClientReadyValidation] = useState(null);

  useEffect(() => {
    if (isOpen && page === AddSessionPopupEN) {
      setSessionNameValidation(null);
      setSessionFrameWorkValidation(null);
    } else if (isOpen && page === AddModulePopupEN) {
      setModuleValidation(null);
      setModuleNameValidation(null);
      setIsClientReadyValidation(null);
    }
  }, [
    isOpen,
    page,
    AddSessionPopupEN,
    AddModulePopupEN,
    setSessionNameValidation,
    setSessionFrameWorkValidation,
    setModuleValidation,
    setModuleNameValidation,
    setIsClientReadyValidation,
  ]);

  useEffect(() => {
    if (isOpen && page === UpdateUserManagementPageEN && selectedEmail) {
      setSelectedUsers([
        { id: selectedUserId, label: selectedEmail, value: selectedUsername },
      ]);
    }
    setUserName(selectedUsername);
    setEmail(selectedEmail);
    setIsApprover(approverValue);
    setUserFramework(selectedRole);
  }, [
    selectedUsername,
    selectedEmail,
    UpdateUserManagementPageEN,
    selectedUserId,
    page,
    approverValue,
    isOpen,
    selectedRole,
  ]);

  useEffect(() => {
    if (page === EditSessionPopupEN && SelectedsessionName) {
      setSessionName(SelectedsessionName);
      setSessionFramework(SelectedsessionFramework);
    }

    if (page === EditModulePopupEN) {
      setModuleName(moduleNameUpdated);
      setClientReady(isClientReadyUpdated);
      setModule(moduleTypeId);
    }
  }, [
    page,
    SelectedsessionName,
    SelectedsessionFramework,
    EditSessionPopupEN,
    EditModulePopupEN,
    moduleNameUpdated,
    isClientReadyUpdated,
    moduleTypeId,
  ]);
  useEffect(() => {
    if (!isOpen) {
      setUserName("");
      setEmail("");
      setUserFramework("");
      setModule("");
      setSessionName("");
      setSessionFramework("");
      setClientReady("");
      setModuleName("");
      setIsApprover(false);
      setSelectedUsers([]);
    }
  }, [isOpen]);

  const specialCharPattern = /[^a-zA-Z0-9 ]/;
  const handleKeyPress = (event) => {
    if (specialCharPattern.test(event.key)) {
      event.preventDefault();
    }
  };
  const validateFieldsAddSession = () => {
    let isValid = true;
    const specialCharPattern = /[^a-zA-Z0-9 ]/;
    if (!sessionName) {
      setSessionNameValidation("Session name is required");
      isValid = false;
    } else if (specialCharPattern.test(module)) {
      setSessionNameValidation(
        "Session name should not contain special characters"
      );
      isValid = false;
    } else {
      setSessionNameValidation("");
    }
    if (!sessionFramework) {
      setSessionFrameWorkValidation("Session framework is required");
      isValid = false;
    } else {
      setSessionFrameWorkValidation("");
    }
    return isValid;
  };
  const validateFieldsAddModule = () => {
    let isValid = true;
    const specialCharPattern = /[^a-zA-Z0-9 ]/;
  
    if (!moduleName) {
      setModuleNameValidation("Module name is required.");
      isValid = false;
    } else if (specialCharPattern.test(moduleName)) {
      setModuleNameValidation("Module name contains invalid characters.");
      isValid = false;
    } else {
      setModuleNameValidation(null);
    }
  
    if (!module) {
      setModuleValidation("Module type is required.");
      isValid = false;
    } else {
      setModuleValidation(null);
    }
  
    if (!clientReady) {
      setIsClientReadyValidation("Client ready status is required.");
      isValid = false;
    } else {
      setIsClientReadyValidation(null);
    }
  
    return isValid;
  };
  const handleDone = () => {
    switch (page) {
      case AddSessionPopupEN:
        if (validateFieldsAddSession()) {
          callApi({
            name: sessionName,
            frameworkId: sessionFramework,
          });
        }
        break;
      case EditSessionPopupEN:
        callApi({
          name: sessionName,
          id: sessionId,
        });
        break;
      case AddModulePopupEN:
        if (validateFieldsAddModule()) {
          callApi({
            projectId: projectId,
            moduleTypeId: module,
            sessionId: sessionId,
            name: moduleName,
            isClientReady: clientReady === "Yes",
          });
        }

        break;
      case EditModulePopupEN:
        callApi({
          moduleId: moduleIdToUpdateApi,
          isClientReady: clientReady === "Yes",
          name: moduleName,
        });
        break;
      case UserManagementPageEN:
        callApi({
          aadId: selectedUsers.id,
          name: selectedUsers.value,
          email: selectedUsers.label,
          projectRoleId: parseInt(userFramework, 10),
          isApprover,
        });
        break;
      case UpdateUserManagementPageEN:
        callApi({
          aadId: selectedUsers.id,
          projectRoleId: parseInt(userFramework, 10),
          isApprover,
        });
        break;
      case RetireSessionPopup:
        callApi({ id: sessionId });
        break;

      default:
        callApi();
    }
  };
  const searchUsers = useCallback(async (searchQuery, accessToken) => {
    try {
      const filterQuery = encodeURIComponent(
        `startswith(displayName,'${searchQuery}') or startswith(mail,'${searchQuery}') or startswith(givenName,'${searchQuery}') or startswith(surname,'${searchQuery}')`
      );
      const response = await fetch(`${FETCH_AD_USERS}?$filter=${filterQuery}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching users: ${response.statusText}`);
      }

      const data = await response.json();
      return data.value;
    } catch (error) {
      console.error("Error in searchUsers:", error);
      return [];
    }
  }, []);

  const getADUsers = useCallback(
    async (inputValue) => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        try {
          const tokenResponse = await instance.acquireTokenSilent({
            scopes: ["User.ReadBasic.All"],
            account: accounts[0],
          });
          const accessToken = tokenResponse.accessToken;
          const users = await searchUsers(inputValue, accessToken);
          return users.map((user) => ({
            id: user.id,
            displayName: user.displayName,
            emailId: user.mail,
          }));
        } catch (error) {
          console.error("Error fetching AD users:", error);
          return [];
        }
      }
      return [];
    },
    [instance, searchUsers]
  );

  const handleChange = useCallback((selectedList) => {
    setSelectedUsers(selectedList);
  }, []);

  const handleFrameworkChange = (selectedFrameworkId) => {
    setUserFramework(selectedFrameworkId);
    const selectedRole = userRoles.find(
      (role) => role.id === selectedFrameworkId
    );
    if (selectedRole && selectedRole.name === "Project guest") {
      setIsApprover(false);
    }
  };

  const changeApproverToggle = () => {
    // Only allow toggling if the selected role is not "Project guest"
    const selectedRole = userRoles.find(
      (role) => role.id === parseInt(userFramework, 10)
    );
    if (selectedRole && selectedRole.name !== "Project guest") {
      setIsApprover(!isApprover);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>
            {page === UserManagementPageEN && TitleUserManagementEN}
            {page === AddSessionPopupEN && NewSessionTitleEN}
            {page === AddModulePopupEN && NewModuleTitleEN}
            {page === EditModulePopupEN && NewModuleTitleEN}
            {page === UpdateUserManagementPageEN && TitleUpdateUserManagementEN}
            {page === EditSessionPopupEN && NewSessionTitleEN}
            {(page === ConfirmationPopup || page === RetireSessionPopup) &&
              ConfirmationTitle}
          </h2>
        </div>
        <div className="modal-body">
          {page === UserManagementPageEN && (
            <div className="form-row">
              <div className="form-group-email">
                <MotifMessage id="form-title">{UserEmailEN}*</MotifMessage>
                <MotifFormField>
                  <MultiPeoplePicker
                    getADUsers={getADUsers}
                    onChange={(selectedList) => {
                      handleChange(selectedList);
                    }}
                    activeDirectoryData={selectedUsers}
                    options={[]}
                    disabled={false}
                    isMandatory={true}
                    label="Users"
                    value={selectedUsers}
                    isErrorVisible={false}
                    errorMessage=""
                    isErrorVisible2={false}
                    errorMessage2=""
                    pageName={"User Management"}
                  />
                </MotifFormField>
              </div>
            </div>
          )}
          {page === UpdateUserManagementPageEN && (
            <div className="form-row">
              <div className="form-group-email">
                <MotifMessage id="form-title">{UserEmailEN}</MotifMessage>
                <MotifFormField>
                  <MultiPeoplePicker
                    getADUsers={getADUsers}
                    onChange={(selectedList) => {
                      handleChange(selectedList);
                    }}
                    activeDirectoryData={selectedUsers}
                    options={[]}
                    disabled={true}
                    label="Users"
                    value={selectedUsers}
                    isErrorVisible={false}
                    errorMessage=""
                    isErrorVisible2={false}
                    pageName={"User Management"}
                    disabledPicker={true}
                  />
                </MotifFormField>
              </div>
            </div>
          )}
          {page !== ConfirmationPopup && page !== RetireSessionPopup && (
            <div className="form-row">
              <div className="form-group">
                <MotifMessage id="form-title">
                  {(page === UserManagementPageEN ||
                    page === UpdateUserManagementPageEN) &&
                    UserNameEN}
                  {(page === AddModulePopupEN ||
                    page === AddSessionPopupEN ||
                    page === EditModulePopupEN ||
                    page === EditSessionPopupEN) &&
                    SessionNameEN}
                </MotifMessage>
                {page === UserManagementPageEN && (
                  <MotifFormField>
                    <MotifInput
                      className="user-modal-input"
                      value={selectedUsers ? selectedUsers.value : ""}
                      onChange={(e) => setUserName(e.target.value)}
                      aria-describedby="user-name"
                      hideClearButton={true}
                      disabled={true}
                      data-testid="username"
                    />
                  </MotifFormField>
                )}
                {page === UpdateUserManagementPageEN && (
                  <MotifFormField>
                    <MotifInput
                      className="user-modal-input"
                      value={selectedUsername}
                      onChange={(e) => setUserName(e.target.value)}
                      aria-describedby="user-name"
                      hideClearButton={true}
                      data-testid="username"
                      disabled={true}
                    />
                  </MotifFormField>
                )}
                {(page === AddModulePopupEN || page === EditModulePopupEN) && (
                  <MotifFormField>
                    <MotifInput
                      className="session-modal-input session-name-input"
                      value={SelectedsessionName}
                      aria-describedby="session-name"
                      hideClearButton={true}
                      disabled={true}
                    />
                  </MotifFormField>
                )}
                {(page === AddModulePopupEN || page === EditModulePopupEN) && (
                  <div>
                    <MotifMessage id="form-title">{nameLabel}*</MotifMessage>
                    <MotifFormField>
                      <MotifLabel>{enterNamePlaceholder}</MotifLabel>
                      <MotifInput
                        className="module-modal-input"
                        value={moduleName}
                        onChange={(e) => setModuleName(e.target.value)}
                        onKeyPress={handleKeyPress}
                        aria-describedby="module-name"
                        hideClearButton={true}
                      />
                      {moduleNameValidation && (
                        <MotifErrorMessage>
                          {moduleNameValidation}
                        </MotifErrorMessage>
                      )}
                    </MotifFormField>
                  </div>
                )}
                {(page === AddSessionPopupEN ||
                  page === EditSessionPopupEN) && (
                  <MotifFormField>
                    <MotifLabel>{SessionNameContentEN}</MotifLabel>
                    <MotifInput
                      className="session-modal-input session-name-input"
                      value={sessionName}
                      onChange={(e) => setSessionName(e.target.value)}
                      onKeyPress={handleKeyPress}
                      aria-describedby="session-name"
                      hideClearButton={true}
                    />
                    {sessionNameValidation && (
                      <MotifErrorMessage>
                        {sessionNameValidation}
                      </MotifErrorMessage>
                    )}
                  </MotifFormField>
                )}
              </div>
              {(page === AddSessionPopupEN || page === EditSessionPopupEN) && (
                <div className="form-group">
                  <MotifMessage id="session-framework">
                    {SessionFrameworkEN}
                  </MotifMessage>
                  <MotifFormField>
                    <MotifLabel id="select-label">
                      {SelectSessionFrameworkEN}
                    </MotifLabel>
                    <MotifSelect
                      className="session-modal-input"
                      ariaLabelledBy="select-label"
                      onChange={(e) => setSessionFramework(e)}
                      value={sessionFramework}
                      disabled={page === EditSessionPopupEN}
                    >
                      {sessionFrameWorkArray.map((item) => (
                        <MotifOption value={item.id}>{item.group}</MotifOption>
                      ))}
                    </MotifSelect>
                    {sessionFrameWorkValidation && (
                      <MotifErrorMessage>
                        {sessionFrameWorkValidation}
                      </MotifErrorMessage>
                    )}
                  </MotifFormField>
                </div>
              )}
              {page === UserManagementPageEN && (
                <div className="form-group">
                  <MotifMessage id="form-title">{RoleEN}</MotifMessage>
                  <MotifFormField>
                    <MotifLabel id="select-label">{SelectRoleEN}</MotifLabel>
                    <MotifSelect
                      className="user-modal-input"
                      value={userFramework}
                      ariaLabelledBy="select-label"
                      onChange={(e) => handleFrameworkChange(e)}
                      placeholder="placeholder"
                      visibleOptions={2}
                      data-testid="userframework"
                    >
                      {userRoles.map((role) => (
                        <MotifOption key={role.id} value={role.id}>
                          {role.name}
                        </MotifOption>
                      ))}
                    </MotifSelect>
                  </MotifFormField>
                </div>
              )}
              {page === UserManagementPageEN && (
                <div className="form-group half-width">
                  <MotifFormField className="human-rights-toggle">
                    <MotifToggleSwitch
                      onChange={changeApproverToggle}
                      offLabel={PreparerLabel}
                      onLabel={ApproverLabel}
                      id="exampleToggle"
                      hideLabels={false}
                      checked={isApprover}
                    />
                  </MotifFormField>
                </div>
              )}
              {page === UpdateUserManagementPageEN && (
                <div className="form-group">
                  <MotifMessage id="form-title">{RoleEN}</MotifMessage>
                  <MotifFormField>
                    <MotifLabel id="select-label">{SelectRoleEN}</MotifLabel>
                    <MotifSelect
                      className="user-modal-input"
                      value={userFramework}
                      ariaLabelledBy="select-label"
                      onChange={(e) => handleFrameworkChange(e)}
                      placeholder="placeholder"
                      visibleOptions={2}
                      data-testid="userframework"
                    >
                      {userRoles.map((role) => (
                        <MotifOption key={role.id} value={role.id}>
                          {role.name}
                        </MotifOption>
                      ))}
                    </MotifSelect>
                  </MotifFormField>
                </div>
              )}
              {page === UpdateUserManagementPageEN && (
                <div className="form-group half-width">
                  <MotifFormField className="human-rights-toggle">
                    <MotifToggleSwitch
                      onChange={changeApproverToggle}
                      offLabel={PreparerLabel}
                      onLabel={ApproverLabel}
                      id="exampleToggle"
                      hideLabels={false}
                      checked={isApprover}
                    />
                  </MotifFormField>
                </div>
              )}

              {(page === AddModulePopupEN || page === EditModulePopupEN) && (
                <div className="form-group">
                  <MotifMessage id="module-framework">{ModuleEN}</MotifMessage>
                  <MotifFormField>
                    <MotifLabel id="select-label">{SelectModuleEN}</MotifLabel>
                    <MotifSelect
                      className="module-modal-input"
                      ariaLabelledBy="select-label"
                      onChange={(e) => setModule(e)}
                      value={module}
                      visibleOptions={3}
                      disabled={page === EditModulePopupEN}
                    >
                      {moduleTypes.map((module) => (
                        <MotifOption key={module.id} value={module.id}>
                          {module.name}
                        </MotifOption>
                      ))}
                    </MotifSelect>
                    {moduleValidation && (
                      <MotifErrorMessage>{moduleValidation}</MotifErrorMessage>
                    )}
                  </MotifFormField>
                  <div>
                    <MotifMessage id="module-framework">
                      {clientReadyLabel}
                    </MotifMessage>
                    <MotifFormField>
                      <MotifLabel>{clientReadyPlaceholder}</MotifLabel>
                      <MotifSelect
                        className="module-modal-select"
                        ariaLabelledBy="select-label"
                        onChange={(e) => setClientReady(e)}
                        value={clientReady}
                      >
                        <MotifOption value="Yes">{yesLabel}</MotifOption>
                        <MotifOption value="No">{noLabel}</MotifOption>
                      </MotifSelect>
                      {isClientReadyValidation && (
                        <MotifErrorMessage>
                          {isClientReadyValidation}
                        </MotifErrorMessage>
                      )}
                    </MotifFormField>
                  </div>
                </div>
              )}
            </div>
          )}

          {(page === ConfirmationPopup || page === RetireSessionPopup) && (
            <div className="form-row">
              <div className="form-group-email">
                <MotifMessage id="form-title">
                  {ConfirmationDeleteMsgEN}
                </MotifMessage>
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <MotifButton onClick={onClose} className="button cancel-button">
            {CancelButtonEN}
          </MotifButton>
          <MotifButton className="button done-button" onClick={handleDone}>
            {page === UpdateUserManagementPageEN
              ? UpdateButtonEN
              : DoneButtonEN}
            {(page === ConfirmationPopup || page === RetireSessionPopup) &&
              ConfirmationEN}
          </MotifButton>
          {(page === EditSessionPopupEN || page === EditModulePopupEN) && (
            <MotifButton
              className="button delete-button"
              onClick={handleDeleteSession}
            >
              {DeleteButton}
            </MotifButton>
          )}
        </div>
      </div>
    </div>
  );
};

AddUser.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  page: PropTypes.string,
  TitleUserManagementEN: PropTypes.string,
  UserNameEN: PropTypes.string,
  RoleEN: PropTypes.string,
  selectedRole: PropTypes.string,
  SelectRoleEN: PropTypes.string,
  UserEmailEN: PropTypes.string,
  CancelButtonEN: PropTypes.string,
  DoneButtonEN: PropTypes.string,
  UserManagementPageEN: PropTypes.string,
  AddModulePopupEN: PropTypes.string,
  EditModulePopupEN: PropTypes.string,
  NewModuleTitleEN: PropTypes.string,
  SessionNameEN: PropTypes.string,
  AddSessionPopupEN: PropTypes.string,
  EditSessionPopupEN: PropTypes.string,
  SelectedsessionName: PropTypes.string,
  SelectedsessionFramework: PropTypes.number,
  ModuleEN: PropTypes.string,
  SelectModuleEN: PropTypes.string,
  NewSessionTitleEN: PropTypes.string,
  SessionNameContentEN: PropTypes.string,
  SessionFrameworkEN: PropTypes.string,
  SelectSessionFrameworkEN: PropTypes.string,
  ESRSEN: PropTypes.string,
  sessionId: PropTypes.string,
  callApi: PropTypes.func,
  TitleUpdateUserManagementEN: PropTypes.string,
  UpdateUserManagementPageEN: PropTypes.string,
  selectedUsername: PropTypes.string,
  selectedEmail: PropTypes.string,
  UpdateButtonEN: PropTypes.string,
  ConfirmationPopup: PropTypes.string,
  RetireSessionPopup: PropTypes.string,
  ConfirmationTitle: PropTypes.string,
  ConfirmationEN: PropTypes.string,
  ConfirmationDeleteMsgEN: PropTypes.string,
  PreparerLabel: PropTypes.string,
  ApproverLabel: PropTypes.string,
  userRoles: PropTypes.array,
  clientReadyLabel: PropTypes.string,
  nameLabel: PropTypes.string,
  enterNamePlaceholder: PropTypes.string,
  clientReadyPlaceholder: PropTypes.string,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  moduleTypes: PropTypes.array,
  moduleNameUpdated: PropTypes.string,
  isClientReadyUpdated: PropTypes.string,
  moduleTypeId: PropTypes.string,
  moduleIdToUpdateApi: PropTypes.string,
  handleDeleteSession: PropTypes.func,
  DeleteButton: PropTypes.string,
  selectedUserId: PropTypes.string,
  approverValue: PropTypes.bool,
  projectId: PropTypes.number,
  sessionFrameWorkArray: PropTypes.array,
};

export default AddUser;

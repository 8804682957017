import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_Status_URL,
  GET_MAINTENANCE_STATUS_URL,
} from "../../services/Api";


export const getAllStatus = createAsyncThunk(
  "getAllStatus",
  async ({ token }, { rejectWithValue }) => {
    try {
      
      const response = await axios.get(GET_ALL_Status_URL, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching all status:", error);
      return rejectWithValue(
        error.response.data?.title || error?.message || "Error fetching all status"
      );
    }
  }
);

export const getMaintainenceStatus = createAsyncThunk(
  "getMaintainenceStatus",
  async (
    { isActive, messageWhenInactive, lastModifiedBy, token },
    { rejectWithValue }
  ) => {
    try {
      
      const response = await axios.post(
        GET_MAINTENANCE_STATUS_URL,
        {
          status: isActive,
          messageWhenInactive,
          lastModifiedBy,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating status:", error);
      return rejectWithValue(error.response.data?.title || error?.message || "Error updating status");
    }
  }
);

const getAllStatusSlice = createSlice({
  name: "allStatus",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    statusUpdate: false,
    errorMessage: ""
  },
  extraReducers: (builder) => {
    builder.addCase(getAllStatus.pending, (state) => {
      state.isLoading = true;
      state.errorMessage = "";
    });
    builder.addCase(getAllStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getAllStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    });
    builder.addCase(getMaintainenceStatus.pending, (state) => {
      state.isLoading = true;
      state.errorMessage = "";
    });
    builder.addCase(getMaintainenceStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.statusUpdate = true;
    });
    builder.addCase(getMaintainenceStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    });
  },
});

export default getAllStatusSlice.reducer;